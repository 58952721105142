import styled from 'styled-components';

export const GridContainer = styled.div`
  // todo: fix this
  height: 85vh;

  .ag-root ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .ag-root ::-webkit-scrollbar-button {
    background: ${({ theme }) => theme.color.grey[5]};
  }

  .ag-root ::-webkit-scrollbar-track-piece {
    background: ${({ theme }) => theme.color.grey[5]};
  }

  .ag-root ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.grey[2]};
  }
`;
