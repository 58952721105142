import { z } from "zod";
import { BooleanColumnDataSchema } from "./boolean.atom";
import { DateColumnDataSchema } from "./date.atom";
import { LabelsColumnDataSchema } from "./label.atom";
import { MultiSelectColumnDataSchema } from "./multi-select.atom";
import { NumberColumnDataSchema } from "./number.atom";
import { SelectColumnDataSchema } from "./select.atom";
import { TextColumnDataSchema } from "./text.atom";

export const TableEditorSchemas = [
    z.object({
        type: z.literal("Table_Editor_Select"),
        data: SelectColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Editor_Text"),
        data: TextColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Editor_Number"),
        data: NumberColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Editor_Date"),
        data: DateColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Editor_Labels"),
        data: LabelsColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Editor_Boolean"),
        data: BooleanColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Editor_MultiSelect"),
        data: MultiSelectColumnDataSchema(),
    })
] as const;

export const TABLE_EDITOR_TYPES = TableEditorSchemas.map(schema => schema.shape.type.value);

export type TableEditorBlocks = typeof TableEditorSchemas[number]["shape"]["type"]["value"];

