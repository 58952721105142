import { z } from 'zod';

import { CustomElement } from '@pages/Settings/notifications/editor/slate';

import { NotificationTemplateModel } from '@models/notificationTemplate.model';

import {
  NotificationTemplate,
  NotificationTemplateSchema
} from 'shared'

import AsyncStore from '@stores/base/async.store';
import RootStore from '@stores/root.store';

import { parseWithZod } from '@/utils/parseZodSchema';

export class NotificationTemplateStore extends AsyncStore<NotificationTemplateModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, NotificationTemplateModel, 'notificationTemplates');
  }

  loadNotificationTemplates(
    notificationTemplates: NotificationTemplate[]
  ): Maybe<NotificationTemplateModel[]> {
    const loadedNotificationTemplates = parseWithZod(
      z.array(NotificationTemplateSchema),
      notificationTemplates,
      'NOTIF-IzPts'
    );

    if (!loadedNotificationTemplates) return;

    return loadedNotificationTemplates.map((template) => {
      const notificationTemplate = new NotificationTemplateModel(
        this,
        template.id,
        template.title,
        template.text as CustomElement[],
        new Date(template.createdAt),
        new Date(template.updatedAt),
        template.process_id
      );

      this.set(notificationTemplate.id, notificationTemplate);
      return notificationTemplate;
    });
  }

  async createNewNotificationTemplate(
    title: string,
    text: CustomElement[],
    processId: string
  ) {
    const newNotificationTemplate = this.create();
    newNotificationTemplate.title = title;
    newNotificationTemplate.text = text;
    newNotificationTemplate.templateProcessId = processId;
    newNotificationTemplate.createdAt = new Date();
    newNotificationTemplate.updatedAt = new Date();

    const dto = {
      id: newNotificationTemplate.id,
      title: newNotificationTemplate.title,
      text: newNotificationTemplate.text,
      processId: newNotificationTemplate.templateProcessId,
      createdAt: newNotificationTemplate.createdAt,
      updatedAt: newNotificationTemplate.updatedAt
    };

    return await this.createNew(dto);
  }
}
