import { z } from "zod";
import { NumberWidgetSchema } from "./widget/number.atom";
import { LabelsWidgetSchema } from "./widget/label.atom";
import { DateWidgetSchema } from "./widget/date.atom";
import { TextWidgetSchema } from "./widget/text.atom";
import { BooleanWidgetSchema } from "./widget/boolean.atom";
import { ProgressWidgetSchema } from "./widget/progress.atom";

export namespace WidgetSchema {
  export const MainSchema = z.union([
    NumberWidgetSchema.MainSchema,
    LabelsWidgetSchema.MainSchema,
    DateWidgetSchema.MainSchema,
    TextWidgetSchema.MainSchema,
    BooleanWidgetSchema.MainSchema,
    ProgressWidgetSchema.MainSchema,
  ]);
}
