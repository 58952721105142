import { z } from "zod";
import { BaseEditorSchema, MultiEditorsSchema } from "./shared.schema";
import { NumberWidgetSchema, ColumnSchema } from "../../../../trace";

const NumberEditorSchema = BaseEditorSchema.merge(
    NumberWidgetSchema.MainSchema.omit({
        tabRight: true,
    })
).extend({
    saveFormatted: z.boolean(),
});

export const NumberColumnDataSchema = (valueValidation?: boolean) =>
    z
        .object({
            cell: z.object({
                view: NumberWidgetSchema.MainSchema,
            }),
            required: z.boolean(),
        })
        .merge(ColumnSchema.MainSchema(valueValidation))
        .merge(
            MultiEditorsSchema(
                z.object({
                    number: NumberEditorSchema,
                })
            )
        )
        .strict();

export type TableEditorNumberColumnData = z.infer<
    ReturnType<typeof NumberColumnDataSchema>
>;
