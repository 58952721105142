import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Info } from 'lucide-react';
import { observer } from 'mobx-react';

import {
  BlockBody,
  Column
} from '@components/dnd/base/blockBase/body.block.style';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import ActionButton from '@atoms/button';
import { InputField } from '@atoms/input';
import { SelectField } from '@atoms/select';

import { ProcessImageTag, UserRoles } from '@/types/process.types';
import { ENV, EnvironmentName } from '@/utils/constants';
import {
  Button,
  Checkbox,
  DialogActions,
  FormHelperText,
  List,
  ListItem,
  Modal,
  ModalDialog,
  Option
} from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';

import SettingHeader from '../SettingHeader';
import { SettingsContent } from '../settings.style';
import { SettingBody } from '../style';
import {
  ButtonContainer,
  GeneralSettingsInput,
  GeneralSettingsTitle,
  RowDiv
} from './general-setting.style';

interface BasicModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  nuke: () => Promise<void>;
  isDraft: boolean;
  stats?: {
    actionCount: number;
    transitionCount: number;
    workflowCount: number;
  };
}

export const DeleteModal = ({
  open,
  setOpen,
  nuke,
  isDraft,
  stats
}: BasicModalProps) => {
  const [deleteDisable, setDeleteDisable] = useState(true);
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
    >
      <ModalDialog
        color="danger"
        layout="center"
        variant="outlined"
        sx={{ width: 500 }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} color="danger" />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          Are you sure about deleting the process ?
        </Typography>
        <Typography>
          Deleting a process is completely irreversible.{' '}
          <strong>All data about the process will be lost.</strong>
        </Typography>
        {stats && (
          <>
            <Typography>Your process has:</Typography>
            <List marker="disc">
              {stats?.workflowCount === 0 && (
                <ListItem>
                  <strong>{stats.workflowCount}</strong> workflow
                </ListItem>
              )}
              {stats?.actionCount === 0 && (
                <ListItem>
                  <strong>{stats.workflowCount}</strong> workflow
                </ListItem>
              )}
              {stats?.transitionCount === 0 && (
                <ListItem>
                  <strong>{stats.workflowCount}</strong> workflow
                </ListItem>
              )}
            </List>
          </>
        )}
        {!isDraft && (
          <Checkbox
            color="neutral"
            label="I want to delete the process in Trace (all trace will be deleted has well)"
          />
        )}
        <Checkbox
          color="danger"
          label="I understand the consequences of this action"
          onChange={(e) => setDeleteDisable(!e.target.checked)}
        />
        <DialogActions>
          <Button
            variant="solid"
            color="danger"
            disabled={deleteDisable}
            onClick={() => {
              nuke().catch(() => {});
              setOpen(false);
            }}
          >
            Delete 💥
          </Button>
          <Button
            variant="plain"
            color="neutral"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

const GeneralSetting = () => {
  const { process } = useProcess();
  const [processName, setProcessName] = useState(process?.name);
  const [emptyProcessNameError, setEmptyProcessNameError] = useState(false);
  const [emptyWfNameError, setEmptyWfNameError] = useState(false);
  const [emptyWfIdEnvError, setEmptyWfIdEnvError] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const { processStore, actionStore, transitionStore, workflowStore } =
    useStores();

  const stats = {
    actionCount: actionStore.data.size,
    transitionCount: transitionStore.data.size,
    workflowCount: workflowStore.data.size
  };

  useEffect(() => {
    setProcessName(process?.name);
  }, [process]);

  const handleTagChange = (
    _: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    if (
      !newValue ||
      !Object.values(ProcessImageTag).includes(newValue as ProcessImageTag)
    ) {
      return;
    }
    process.imageTag = newValue as ProcessImageTag;
    processStore.updateProcess(process?.id, {
      image: process.processImage
    });
  };

  const canDelete = process?.user_role !== UserRoles.Viewer;

  const onProcessNameBlur = async () => {
    if (!processName) {
      return setEmptyProcessNameError(true);
    } else if (emptyProcessNameError) {
      setEmptyProcessNameError(false);
    }
    await processStore.updateProcess(process.id, {
      name: processName
    });
  };

  async function deleteProcess() {
    const response = await processStore.delete(process?.id);
    if (response) {
      window.location.assign('/');
    }
  }
  return (
    <SettingsContent>
      <SettingHeader
        title="General Settings"
        description="Customize your processus"
      />
      <DeleteModal
        open={modalOpen}
        setOpen={setModalOpen}
        stats={stats}
        isDraft={process?.draft ?? false}
        nuke={deleteProcess}
      />

      {process?.loading && <SettingBody></SettingBody>}
      {!process?.loading && (
        <SettingBody>
          <GeneralSettingsInput>
            <InputField
              label="Processus name"
              placeholder="My beatiful processus"
              onChange={(e) => setProcessName(e.target.value)}
              value={processName}
              onBlur={() => void onProcessNameBlur()}
              error={emptyProcessNameError}
            />
            {emptyProcessNameError && (
              <FormHelperText>
                <Info size={16} />
                Process name can't be empty
              </FormHelperText>
            )}

            {process?.processImage && (
              <RowDiv>
                <InputField
                  label="Image"
                  placeholder="git.sia-partners.com:5656/..."
                  disabled
                  value={process.imageUrl}
                  width="80%"
                />

                <SelectField
                  label="Tag"
                  variant="outlined"
                  placeholder="my-super-tag"
                  defaultValue={process.imageTag ?? undefined}
                  onChange={handleTagChange}
                  disabled={ENV == EnvironmentName.Release}
                  width="20%"
                >
                  {Object.values(ProcessImageTag).map((tag) => (
                    <Option value={tag} key={tag}>
                      {tag}
                    </Option>
                  ))}
                </SelectField>
              </RowDiv>
            )}
          </GeneralSettingsInput>

          {process.workflows.length > 0 && (
            <>
              <GeneralSettingsTitle>Workflows</GeneralSettingsTitle>
              <BlockBody>
                {process.workflows.map((workflow) => (
                  <>
                    <Column $width="200px">
                      <InputField
                        label="Name"
                        value={workflow.name}
                        onChange={(event) => {
                          workflow.name = event.target.value;
                        }}
                        // onBlur={() => void onWorkflowNameBlur(workflow.id)}
                        placeholder="Renewal"
                        error={emptyWfNameError}
                      />
                      {emptyWfNameError && (
                        <FormHelperText>
                          <Info size={16} />
                          Worfklow name can't be empty
                        </FormHelperText>
                      )}
                    </Column>

                    {process?.draft && (
                      <>
                        <Column $width="200px">
                          <InputField
                            label="ID environment variable name"
                            value={
                              workflow?.id_environment_variable_name ??
                              'undefined'
                            }
                            disabled
                          />
                        </Column>
                      </>
                    )}
                    <Column $width="200px">
                      <InputField
                        label="Published id"
                        disabled
                        value={workflow?.published_id ?? 'Not published yet'}
                        placeholder="Not published yet"
                      />
                    </Column>
                  </>
                ))}
              </BlockBody>
            </>
          )}

          <ButtonContainer>
            <ActionButton
              disabled={!canDelete}
              color="danger"
              value="Delete the process"
              onClick={() => {
                if (!process?.id) return;
                setModalOpen(true);
              }}
            />
          </ButtonContainer>
        </SettingBody>
      )}
    </SettingsContent>
  );
};

export default observer(GeneralSetting);
