import { observer } from 'mobx-react';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@components/ui/card';
import { Label } from '@components/ui/label';

import Model from '@models/base/base.model';

import { ColumnElement } from '@atoms/columnElement';
import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';
import { InfoTooltip } from '@atoms/infoTooltip';
import { SwitchV2 } from '@atoms/switch';
import { AtomReference, TableSchema } from 'shared';

type Props = TableSchema.SettingsData & {
  sourceId: Model['id'];
  onShowEmptyTableChange: (value: boolean) => void;
  onAllowColumnsSelection: (allowColumnsSelection: boolean) => void;
  onDataSelectorPathReferenceSelected: (ref: Maybe<AtomReference>) => void;
};

export const TableSettings = observer(
  (props: React.PropsWithChildren<Props>) => {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Table settings</CardTitle>
          <CardDescription>Additional table settings</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-wrap gap-6">
            <Column $width="240px">
              <ColumnElement>
                <div className="flex items-center gap-2">
                  <Label>Primary key</Label>
                  <InfoTooltip
                    title="Primary key"
                    description="The primary key of the table, ideally a unique identifier for each row."
                  />
                </div>
                <VariableSelector
                  specificSourceIds={[props.sourceId]}
                  onSelected={props.onDataSelectorPathReferenceSelected}
                  selectedRef={props.dataSelectorPathReference}
                  placeholder="Select a primary key"
                />
              </ColumnElement>

              <ColumnElement>
                <SwitchV2
                  id="allow-columns-celection"
                  labelText="Allow columns selection"
                  tooltip={
                    <InfoTooltip
                      title="Allow columns selection"
                      description="If enabled, the user will be able to select which columns to display or to hide using the Display button."
                    />
                  }
                  checked={props.allowColumnsSelection}
                  onCheckedChange={props.onAllowColumnsSelection}
                />
              </ColumnElement>
              <ColumnElement>
                <SwitchV2
                  id="show-empty-table"
                  labelText="Show empty table"
                  checked={props.showEmptyTable}
                  onCheckedChange={props.onShowEmptyTableChange}
                />
              </ColumnElement>
            </Column>
            {props.children}
          </div>
        </CardContent>
      </Card>
    );
  }
);
