import Model, { ModelError } from '@models/base/base.model';

import GatewayStore from '@stores/gateway.store';

interface CreateGateway {
  id: Model['id'];
}

export default class GatewayModel extends Model {
  store: GatewayStore;
  constructor(store: GatewayStore, gateway: CreateGateway) {
    super(store, gateway.id, false);
    this.store = store;
  }

  get toJSON() {
    return {};
  }

  public async delete() {
    return await this.store.delete(this.id);
  }

  public get errors(): ModelError[] {
    return [];
  }
}
