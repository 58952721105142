import { useEffect, useState } from 'react';

import { newError } from '@/services/errors/errors';

import useStores from './useStore';

export const useProcesses = () => {
  const { processStore } = useStores();
  const [loading, setLoading] = useState(true);
  const processes = processStore.toArray();

  useEffect(() => {
    processStore
      .getAllProcesses()
      .then(() => setLoading(false))
      .catch((e) => newError('PROCS-ulTQx', e));
  }, [processStore]);

  return {
    processes,
    loading
  };
};
