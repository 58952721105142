import { z } from "zod";
import { WidgetSchema, ColumnSchema } from "../../../../trace";

export const LabelsColumnDataSchema = (valueValidation?: boolean) =>
    z
        .object({
            cell: z.object({
                view: WidgetSchema.MainSchema,
            }),
            required: z.boolean(),
        })
        .merge(ColumnSchema.MainSchema(valueValidation))
        .strict();

export type TableEditorLabelsColumnData = z.infer<
    ReturnType<typeof LabelsColumnDataSchema>
>;
