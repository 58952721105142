import { observer } from 'mobx-react';

import DNDBase from '@components/dnd/base';

import useAction from '@hooks/useAction';
import useStores from '@hooks/useStore';

export const FormBuilder = observer(() => {
  const { dndStore } = useStores();
  const action = useAction();
  const formBuilderDnd = dndStore.get(action?.uiDndId);
  if (!formBuilderDnd) {
    return <></>;
  }

  return <DNDBase dnd={formBuilderDnd} hasToolbar />;
});
