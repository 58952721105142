import styled from 'styled-components';

interface SettingBodyProps {
  $noHelper?: boolean;
}
export const SettingTitle = styled.h1`
  font-size: 24px;
`;

export const SettingDescription = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.color.grey[4]};
`;

export const ContainerBase = styled.div`
  display: grid;
  grid-template-columns: 50px auto 30px;
  padding: 10px 0;
  margin-bottom: 20px;
  min-width: 500px;
  box-shadow: ${(p) => p.theme.verRail.boxShadow};
  border-radius: 8px;
  background-color: #fff;
  transition: box-shadow 0.15s ease;
  &:hover {
    box-shadow: ${(p) => p.theme.verRail.boxShadowHover};
  }
`;

export const IconBase = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;

  svg {
    height: 20px;
    width: 20px;
    stroke: ${({ theme }) => theme.color.grey[1]};
  }
`;

export const ContentBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DescriptionBase = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.color.grey[4]};
  margin: 10px 0;
`;

export const HelperBtn = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  width: fit-content;
  cursor: pointer;

  svg {
    height: 100%;
    margin-left: 16px;
  }
`;

export const HelperClose = styled(IconBase)`
  padding-right: 15px;
  svg {
    height: 14px;
    width: 14px;
    stroke: ${({ theme }) => theme.color.grey[4]};
    cursor: pointer;
  }
`;

export const SettingBody = styled.div<SettingBodyProps>`
  ${({ $noHelper }) => !$noHelper && `margin-top: 30px;`}
  .title-body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      gap: 8px;
    }

    h1 {
      font-size: 24px;
    }
  }
`;

export const SettingClearButton = styled.button`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  background: transparent;
  font-size: 12px;
  color: ${({ theme }) => theme.color.grey[2]};
  border-radius: 5px;

  svg {
    stroke: ${({ theme }) => theme.color.grey[2]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.grey[8]};
  }
`;

export const ListBase = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
