import { ChangeEvent } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';

import {  ResolvedVariableType, DNDBlock } from 'shared'

import { InputField } from '@atoms/input';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';

import { initialFileUploadData } from './fileUpload.data';
import { FileUploadModal } from './fileUpload.modal';
import { FileUploadIcon } from './fileUpload.utils';

const FileUpload = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    type: 'File Upload',
    sourceId: block.id,
    parentId: actionId,
    parentKind: 'form_action',
    initialData: initialFileUploadData,
    variableInfo: {
      resolvedType: ResolvedVariableType.File
    }
  });

  if (!atom) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: atom.data.title || `File Upload title`,
      content: <FileUploadModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );
  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={FileUploadIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={atom.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            atom.data.title = e.target.value;
          }}
        />
      </Column>
    </BlockBase>
  );
};

export default observer(FileUpload);
