import styled, { css } from 'styled-components';

import { CONTENT_PADDING } from '@pages/NewProcess/newProcess.style';

const ITEM_PADDING = 0.6;

export const ItemsContainer = styled.section`
  padding: ${CONTENT_PADDING}rem;
  box-sizing: border-box;
`;

export const SearchInput = styled.input`
  outline: none;
  border: none;
  border-bottom: ${(p) => p.theme.border.default};
  font-size: 15px;
  padding: 1rem 1rem;
`;

export const ItemList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-top: 2px;
`;

interface ItemProps {
  $isSelected: boolean;
  $disabled?: boolean;
}

export const Item = styled.div<ItemProps>`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-inline: ${ITEM_PADDING}rem;
  padding-block: 0.4rem;
  color: #484448;
  opacity: ${(p) => (p.$disabled ? 0.5 : 1)};
  min-width: 330px;
  user-select: none;
  cursor: ${(p) => (p.$disabled ? 'not-allowed' : 'pointer')};
  gap: 0.6rem;
  background-color: ${(p) => p.theme.verRail.background.content};
  border-radius: 6px;
  &:hover {
    background-color: ${(p) =>
      p.$disabled ? '' : p.theme.verRail.background.contentHover};
  }
  ${(p) =>
    p.$isSelected &&
    css`
      background-color: ${(p) => p.theme.verRail.background.contentHover};
    `}
`;

export const ItemIconContainer = styled.div`
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemName = styled.div`
  height: 100%;
`;

export const ModalContent = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
