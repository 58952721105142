import { useEffect, useRef, useState } from 'react';

import { ChevronsUpDown, Rocket, Share2 } from 'lucide-react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import { User } from 'shared';
import { toast } from 'sonner';
import { useTheme } from 'styled-components';

import ShareDialog from '@components/share';
import { Avatar, AvatarFallback, AvatarImage } from '@components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@components/ui/dropdown-menu';
import { Skeleton } from '@components/ui/skeleton';

import useStores from '@hooks/useStore';

import { ProcessModel } from '@models/process.model';

import ActionButton from '@atoms/button';
import Logo from '@atoms/logo/logo.comp';

import { ParamsList } from '@/routes/routes.types';
import {
  ACCOUNT_UI_URL,
  EnvironmentName,
  TRACE_UI_URL
} from '@/utils/constants';
import { ENV } from '@/utils/constants';
import { logout } from '@/utils/logout';
import { Button, Dropdown, MenuButton } from '@mui/joy';

import {
  CustomMenuItem,
  EnvironmentSpan,
  NavbarEditorMenu,
  ProcessList
} from './header.style';
import { MiddleNavBar } from './middle.comp';
import { ProcessSelector } from './processSelector';
import { WorkflowSelector } from './workflowSelector';

interface HeaderProps {
  user: User;
}

const Header = ({ user }: HeaderProps) => {
  const theme = useTheme();
  const { processStore } = useStores();
  const currentProcessId = useParams()[ParamsList.ProcessId];
  const currentWorkflowId = useParams()[ParamsList.WorkflowId];
  const isEnvironmentDisplayed: boolean =
    ENV !== EnvironmentName.Release &&
    !!ENV &&
    Object.values(EnvironmentName).includes(ENV);

  const inputRef = useRef<HTMLInputElement>(null);
  const [openModalShare, setOpenModalShare] = useState(false);
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const currentProcess: Maybe<ProcessModel> =
    currentProcessId != undefined
      ? processStore.get(currentProcessId)
      : undefined;

  const currentProcessFromStore =
    currentProcess && processStore.get(currentProcessId)?.workflowIds;

  const hasBeenGenerated =
    currentProcessFromStore &&
    currentProcessFromStore.some((wf) => wf.published_id);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSelectorOpen]);

  const generateProcess = () => {
    const prom = processStore.generateProcess(currentProcessId ?? '');
    toast.promise(prom, {
      loading: 'Your process generation has been queue!',
      success: () => {
        return 'Your workflow has been generated';
      },
      error: () => {
        return 'An error occured while generating your process';
      }
    });
  };

  return (
    <>
      <header className="w-full flex-row justify-between h-[60px] flex items-center bg-white pr-4 pl-1 z-10">
        {currentProcessId && currentProcess?.permission.can_edit && (
          <div className="fixed w-full top-[15px] z-0">
            <div className="relative w-full flex flex-col items-center ">
              <MiddleNavBar />
            </div>
          </div>
        )}

        <div className="flex z-[100] flex-row items-center w-[40%]">
          <Logo />
          <p className="text-gray-200 pl-0 pr-1">/</p>

          <ProcessSelector />

          {currentWorkflowId && (
            <>
              <p className="text-gray-200 p-2 pr-1">/</p>
              {currentProcess?.status == 'RUNNING' ? (
                <WorkflowSelector />
              ) : (
                <Skeleton className="w-[100px] h-[20px]" />
              )}
            </>
          )}

          {isEnvironmentDisplayed && <EnvironmentSpan>{ENV}</EnvironmentSpan>}
        </div>

        <div className="flex felx-col space-x-3">
          {currentProcessId &&
            hasBeenGenerated !== undefined &&
            currentProcessFromStore !== undefined && (
              <>
                <>
                  {currentProcess?.permission.can_edit && (
                    <ActionButton
                      value="Generate"
                      startDecorator={<Rocket size={18} />}
                      size="sm"
                      onClick={generateProcess}
                    />
                  )}

                  {hasBeenGenerated &&
                    (currentProcessFromStore.length === 1 ? (
                      <Link
                        to={`${TRACE_UI_URL}/workflowoverview/${currentProcessFromStore[0].published_id}`}
                        target="_blank"
                      >
                        <Button variant="outlined">Trace</Button>{' '}
                      </Link>
                    ) : (
                      <Dropdown
                        onOpenChange={() => setIsSelectorOpen(!isSelectorOpen)}
                      >
                        <MenuButton
                          size="sm"
                          endDecorator={
                            <ChevronsUpDown
                              color={theme.color.grey[6]}
                              size={14}
                            />
                          }
                          variant="outlined"
                          sx={{ gap: '10px' }}
                        >
                          Trace
                        </MenuButton>

                        <NavbarEditorMenu>
                          <ProcessList>
                            {currentProcessFromStore.map((wf) => (
                              <CustomMenuItem key={wf.id}>
                                <Link
                                  to={`${TRACE_UI_URL}/workflowoverview/${wf.published_id}`}
                                  target="_blank"
                                >
                                  Workflow {wf.published_id}
                                </Link>
                              </CustomMenuItem>
                            ))}
                          </ProcessList>
                        </NavbarEditorMenu>
                      </Dropdown>
                    ))}
                </>
                <Button
                  color="primary"
                  variant="plain"
                  size="sm"
                  onClick={() => {
                    setOpenModalShare(!openModalShare);
                  }}
                  startDecorator={<Share2 size={18} />}
                />
              </>
            )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Avatar className="cursor-pointer h-9 w-9">
                <AvatarImage
                  src={
                    user?.avatar != null
                      ? user?.avatar
                      : 'https://www.w3schools.com/howto/img_avatar.png'
                  }
                />
                {user && (
                  <AvatarFallback>{`${user.firstName && user.firstName[0]}${user.lastName && user?.lastName[0]}`}</AvatarFallback>
                )}
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-2 border border-solid border-gray-200">
              <DropdownMenuLabel>
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {user.firstName} {user.lastName}
                  </p>
                  <p className="text-xs leading-none text-muted-foreground">
                    {user.email}
                  </p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={() => (location.href = ACCOUNT_UI_URL)}
                >
                  Account
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={() => logout()}
                >
                  Logout
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>

      {currentProcessId && (
        <ShareDialog
          key="modal"
          openModalShare={openModalShare}
          setOpenModalShare={setOpenModalShare}
          user={user}
          processId={currentProcessId}
        />
      )}
    </>
  );
};

export default observer(Header);
