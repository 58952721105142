import { AtomModel } from '@models/atom.model';
import { TableEditorBooleanColumnData } from 'shared';

type Props = {
  atom: AtomModel<TableEditorBooleanColumnData>;
};

export const BooleanSettingsModal = ({ atom }: Props) => {
  atom;
  return <></>;
};
