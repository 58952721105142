import { makeObservable, observable } from 'mobx';
import { Class } from 'utility-types';

import Model from '@models/base/base.model';

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

export const ModelStatus = {
  NEED_LOADING: 'need_loading',
  LOADING: 'loading',
  RUNNING: 'running',
  ERROR: 'error',
  LOCKED: 'password'
};
export type IModelStatus = keyof typeof ModelStatus;

export default class AsyncStore<
  GenericModel extends Model
> extends BaseStore<GenericModel> {
  loading: boolean;
  modelStatus: Map<Model['id'], IModelStatus>;

  constructor(
    rootStore: RootStore,
    model: Class<GenericModel>,
    override_store_url?: string
  ) {
    super(rootStore, model, override_store_url);
    this.loading = false;
    this.modelStatus = new Map();

    makeObservable(this, {
      loading: observable,
      modelStatus: observable
    });
  }

  public async createNew<T = GenericModel>(el: T): Promise<GenericModel> {
    const data = await this.httpWrapper.post<GenericModel>('/', el);

    if (!data) {
      throw Error('Error while creating the Generic store');
    }

    return data;
  }
}
