import styled from 'styled-components';

import { ModalClose } from '@mui/joy';

export const GlassModalContainer = styled.section`
  border-radius: 8px;
  border: 0.5px solid rgb(216, 216, 216);

  box-shadow: rgba(0, 0, 0, 0.5) 0px 16px 70px;
  /* backdrop-filter: blur(5px) saturate(190%) contrast(50%) brightness(130%); */
  background-color: rgba(255, 255, 255, 1);
  &:focus {
    outline: none;
  }
  overflow-y: auto;
  max-height: 70vh;
  max-width: min(70vw, 700px);
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const CloseModalButton = styled(ModalClose)`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  color: #000;
  z-index: 1;
  background: none;
  &:hover {
    color: #000;
  }
`;
