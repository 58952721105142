
import { ActionModel } from '@models/action/action.model';
import { AtomModel } from '@models/atom.model';
import Model from '@models/base/base.model';

import { DNDBlock, ResolvedVariableType } from 'shared'

import { newError } from '@/services/errors/errors';
import { VariableValueOrigin } from '@/types/variables';

import useStores from './useStore';

export type VariableValues = {
  id: DNDBlock['id'];
  type: DNDBlock['type'];
  name: string;
  computedType: ResolvedVariableType;
  origin: VariableValueOrigin;
  atomId: AtomModel['id'];
  actionId: ActionModel['id'];
  path: string;
  possibleValues?: string[];
  possiblePath?: string;
};

export const useVariableData = (
  specificSourceIds?: Model['id'][],
  specificResolvedTypes?: ResolvedVariableType[]
): Map<string, AtomModel<{ title: string }>[]> => {
  const { atomStore, actionStore } = useStores();
  const allVariables = atomStore.getAllVariables();

  const variableByActionMap = new Map<string, AtomModel<{ title: string }>[]>();

  for (const variable of allVariables) {
    const actionId = variable.metaInfo.source.elementId;
    if (
      specificResolvedTypes &&
      // @ts-expect-error it's all good
      !specificResolvedTypes.includes(variable.variableInfo?.resolvedType)
    )
      continue;

    if (specificSourceIds && !specificSourceIds.includes(actionId)) continue;
    const action = actionStore.get(actionId)?.name;
    if (!action) {
      newError('VARS-qDmYk', `Action with id "${actionId}" was not found`);
      continue;
    }

    const actionVariables = variableByActionMap.get(actionId) ?? [];
    actionVariables.push(variable);
    variableByActionMap.set(actionId, actionVariables);
  }

  if (
    !specificSourceIds ||
    (specificSourceIds && specificSourceIds.includes('globalVariables'))
  ) {
    let allglobalVariables = atomStore.getAllGlobalVariables();
    if (specificResolvedTypes?.length) {
      allglobalVariables = allglobalVariables.filter((variable) =>
        // @ts-expect-error it's all good
        specificResolvedTypes.includes(variable.variableInfo?.resolvedType)
      );
    }
    variableByActionMap.set('globalVariables', allglobalVariables);
  }

  return variableByActionMap;
};
