import { z } from "zod";
import { EmailDataSchema } from "./email.atom";

export const NotificationSchemas = [
    z.object({
        type: z.literal("Send Email"),
        data: EmailDataSchema,
    }),
] as const;

export const NOTIFICATION_TYPES = NotificationSchemas.map(schema => schema.shape.type.value);

export type NotificationBlocks = typeof NotificationSchemas[number]["shape"]["type"]["value"];
