import { WidgetSchema } from 'shared';
import { BooleanWidget } from './boolean';
import { DateWidget } from './date';
import { LabelsWidget } from './labels';
import { NumberWidget } from './number';
import { ProgressWidget } from './progress';
import { TextWidget } from './text';

export namespace Widgets {
  export const Schema = WidgetSchema.MainSchema;

  export import Number = NumberWidget;
  export import Date = DateWidget;
  export import Text = TextWidget;
  export import Labels = LabelsWidget;
  export import Boolean = BooleanWidget;
  export import Progress = ProgressWidget;
}
