import { z } from "zod";

export const SingleOrArrayOfString = z.union([z.string(), z.array(z.string())]);

function toArray<T>(el: T | T[]) {
  return Array.isArray(el) ? el : [el];
}

export const BPMNCollaborationSchema = z.object({
  participant: z.object({
    id: z.string(),
    name: z.string(),
    processRef: z.string(),
  }),
  id: z.string(),
});

export type BPMNCollaboration = z.infer<typeof BPMNCollaborationSchema>;

export const BPMNLaneSchema = z
  .object({
    flowNodeRef: SingleOrArrayOfString,
    id: z.string(),
    name: z.string(),
  })
  .transform((val) => {
    return {
      ...val,
      flowNodeRef: toArray(val.flowNodeRef),
    };
  });

export type BPMNLane = z.infer<typeof BPMNLaneSchema>;

export const BPMNLaneSetSchema = z.object({
  id: z.string(),
  lane: z.array(BPMNLaneSchema),
});

export type BPMNLaneSet = z.infer<typeof BPMNLaneSchema>;

export const BPMNSequenceFlowSchema = z
  .object({
    id: z.string(),
    sourceRef: z.string(),
    targetRef: z.string(),
  })
  .transform((el) => ({
    ...el,
    type: "sequenceFlow" as const,
  }));

export type BPMNSequenceFlow = z.infer<typeof BPMNSequenceFlowSchema>;

export const BPMNStartEventSchema = z
  .object({
    outgoing: SingleOrArrayOfString,
    id: z.string(),
  })
  .transform((val) => {
    return {
      outgoing: toArray(val.outgoing),
      id: val.id,
      type: "start_gateway" as const,
    };
  });

export type BPMNStartEvent = z.infer<typeof BPMNStartEventSchema>;

export const BPMNEndEventSchema = z
  .object({
    incoming: SingleOrArrayOfString,
    id: z.string(),
  })
  .transform((val) => ({
    ...val,
    incoming: toArray(val.incoming),
    type: "end_gateway" as const,
  }));

export type BPMNEndEvent = z.infer<typeof BPMNEndEventSchema>;

export const BPMNTaskSchema = z
  .object({
    incoming: SingleOrArrayOfString,
    outgoing: SingleOrArrayOfString.optional(),
    id: z.string(),
    name: z.string(),
  })
  .transform((val) => ({
    ...val,
    incoming: toArray(val.incoming),
    outgoing: toArray(val.outgoing),
    type: "task" as const,
  }));

export type BPMNTask = z.infer<typeof BPMNTaskSchema>;

export const BPMNParallelGatewaySchema = z
  .object({
    incoming: SingleOrArrayOfString,
    outgoing: SingleOrArrayOfString,
    id: z.string(),
  })
  .transform((val) => ({
    ...val,
    incoming: toArray(val.incoming),
    outgoing: toArray(val.outgoing),
    type: "parallel_gateway" as const,
  }));

export type BPMNParallelGateway = z.infer<typeof BPMNParallelGatewaySchema>;

export const BPMNExclusiveGatewaySchema = z
  .object({
    incoming: SingleOrArrayOfString,
    outgoing: SingleOrArrayOfString,
    id: z.string(),
  })
  .transform((val) => ({
    ...val,
    incoming: toArray(val.incoming),
    outgoing: toArray(val.outgoing),
    type: "exclusive_gateway" as const,
  }));

export const BPMNInclusiveGatewaySchema = z
  .object({
    incoming: SingleOrArrayOfString,
    outgoing: SingleOrArrayOfString,
    id: z.string(),
  })
  .transform((val) => ({
    ...val,
    incoming: toArray(val.incoming),
    outgoing: toArray(val.outgoing),
    type: "inclusive_gateway" as const,
  }));
export type BPMNExclusiveGateway = z.infer<typeof BPMNExclusiveGatewaySchema>;

const AllElementSchema = z.union([
  BPMNStartEventSchema,
  BPMNEndEventSchema,
  BPMNExclusiveGatewaySchema,
  BPMNParallelGatewaySchema,
  BPMNInclusiveGatewaySchema,
  BPMNTaskSchema,
  BPMNSequenceFlowSchema,
]);

export type BPMNElement = z.infer<typeof AllElementSchema>;

export const BPMNProcessSchema = z
  .object({
    laneSet: BPMNLaneSetSchema,
    sequenceFlow: z.array(BPMNSequenceFlowSchema).or(BPMNSequenceFlowSchema),
    startEvent: z.array(BPMNStartEventSchema).or(BPMNStartEventSchema),

    // form action
    task: z.array(BPMNTaskSchema).or(BPMNTaskSchema),
    // form action
    sendTask: z.array(BPMNTaskSchema).or(BPMNTaskSchema),
    // form action
    serviceTask: z.array(BPMNTaskSchema).or(BPMNTaskSchema),

    parallelGateway: z
      .array(BPMNParallelGatewaySchema)
      .or(BPMNParallelGatewaySchema)
      .optional(),
    endEvent: z.array(BPMNEndEventSchema).or(BPMNEndEventSchema).optional(),
    exclusiveGateway: z
      .array(BPMNExclusiveGatewaySchema)
      .or(BPMNExclusiveGatewaySchema)
      .optional(),
    inclusiveGateway: z
      .array(BPMNInclusiveGatewaySchema)
      .or(BPMNInclusiveGatewaySchema)
      .optional(),
  })
  .transform((val) => ({
    ...val,
    sequenceFlow: toArray(val.sequenceFlow),
    task: [
      ...toArray(val.task),
      ...toArray(val.sendTask),
      ...toArray(val.serviceTask),
    ],
    parallelGateway: toArray(val.parallelGateway || []),
    endEvent: toArray(val.endEvent || []),
    startEvent: toArray(val.startEvent || []),
    exclusiveGateway: toArray(val.exclusiveGateway || []),
    inclusiveGateway: toArray(val.inclusiveGateway || []),
  }));

export const BPMNGeneralSchema = z.object({
  definitions: z.object({
    collaboration: BPMNCollaborationSchema,
    process: BPMNProcessSchema,
  }),
});

export type BPMNProcess = z.infer<typeof BPMNGeneralSchema>;
