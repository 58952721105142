import { useState } from 'react';

import { CloudUpload } from 'lucide-react';
import { FileCheck } from 'lucide-react';

import {
  FileInput,
  UploadHelperText,
  UploadTitle,
  UploadedTitle,
  VisuallyHiddenInput
} from './fileUpload.style';

interface FileUploadProps {
  onFileChange: (file: File) => void;
  uploadTitle?: string;
  uploadHelper?: string;
  accept?: string;
}

const FileUploadForm = ({
  uploadTitle,
  uploadHelper,
  onFileChange,
  accept
}: FileUploadProps) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    onFileChange(uploadedFile);
  };

  return (
    <FileInput>
      <VisuallyHiddenInput
        type="file"
        onChange={handleFileChange}
        accept={accept ?? '*/*'}
      />
      {file && (
        <>
          <FileCheck size={40} />
          <UploadedTitle>{file.name}</UploadedTitle>
        </>
      )}
      {!file && (
        <>
          <CloudUpload size={40} />
          <UploadTitle>{uploadTitle ?? 'Upload a file'}</UploadTitle>
          <UploadHelperText>{uploadHelper}</UploadHelperText>
        </>
      )}
    </FileInput>
  );
};

export default FileUploadForm;
