import { ActionModel } from '@models/action/action.model';
import { TableEditorActionModel } from '@models/action/tableEditor.model';
import { TableImporterActionModel } from '@models/action/tableImporter.model';
import { CodeModel } from '@models/code/code.model';
import { EmptyActionType } from '@models/code/constants';
import { DNDModel } from '@models/dnd.model';

import {
  Action,
  ActionSchema,
  ActionType,
  CreateActionDTO
} from 'shared'
import { DynamicType } from 'shared'
import { LoadedDndModel } from 'shared'

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { parseWithZod } from '@/utils/parseZodSchema';
import { arrayToUnionType } from '@/utils/typescript/arrayToUnionType';
import { DndLibrary } from '@library';

export default class ActionStore extends BaseStore<ActionModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, ActionModel, 'action');
    this.store_ready = true;
  }

  public async createNewAction(
    bpmnActionId: string,
    actionType: ActionType,
    workflowId: string
  ): Promise<Maybe<ActionModel>> {
    const dto: CreateActionDTO = ActionModel.getCreateDTOByActionType(
      actionType,
      workflowId,
      bpmnActionId
    );

    try {
      const rawResponse = await this.httpWrapper.post<Action>('/', dto);

      const newActionData = parseWithZod(
        ActionSchema,
        rawResponse,
        'ACTIO-aec59'
      );
      if (!newActionData) return;
      return this.addLoadedActionToStore(newActionData);
    } catch (e) {
      newError(
        'ACTIO-f63f6',
        `Error while creating new action with dto: 
      ${JSON.stringify(dto)}`,
        true,
        {
          customMessage: 'Error while creating new action'
        }
      );
      return;
    }
  }

  public addLoadedActionToStore(actionLoaded: Action): ActionModel {
    /* ------------------------ Handle drag & drop models ----------------------- */

    const uiLibrary = this.getUILibraryByActionType(actionLoaded.type);

    this.addActionDndToDndStore(uiLibrary, actionLoaded.ui);
    this.addActionDndToDndStore(
      DndLibrary.EffectsBuilder,
      actionLoaded.effects
    );

    let newAction: ActionModel;

    switch (actionLoaded.type) {
      case 'TABLE_IMPORTER':
        newAction = new TableImporterActionModel(this, actionLoaded);
        break;
      case 'TABLE_EDITOR':
        newAction = new TableEditorActionModel(this, actionLoaded);
        break;
      default:
        newAction = new ActionModel(this, actionLoaded);
    }

    this.set(actionLoaded.bpmn_id, newAction);

    const codeParent: CodeModel['parent'] = {
      type: 'action',
      id: actionLoaded.id
    };

    this.rootStore.codeStore.addLoadedCodeToStore(
      actionLoaded.customCode,
      codeParent
    );

    return newAction;
  }

  private addActionDndToDndStore = (
    library: DndLibrary,
    loadedDnd: LoadedDndModel
  ): DNDModel => {
    return this.rootStore.dndStore.createDndFromLoaded(library, loadedDnd);
  };

  public async deleteById(actionId: string): Promise<boolean> {
    const actionToDelete: Maybe<ActionModel> = this.get(actionId);

    if (!actionToDelete) {
      newError(
        'ACTIO-27edb',
        `No action found with the id: ${actionId}`,
        true,
        {
          description: 'An error occured while deleting the action'
        }
      );

      return false;
    }

    return await actionToDelete.delete();
  }

  public getActionsTypeScriptType(): string {
    const actionKeys = this.getAllActions().map(
      (action) => action.traceKey.value
    );
    if (actionKeys.length === 0) return EmptyActionType;
    return `type ${DynamicType.Action} = ${arrayToUnionType(actionKeys)};`;
  }

  public getAllActions(): ActionModel[] {
    return Array.from(this.data.values());
  }

  private getUILibraryByActionType(actionType: ActionType): DndLibrary {
    switch (actionType) {
      case 'FORM':
        return DndLibrary.FormBuilder;
      case 'TABLE_IMPORTER':
        return DndLibrary.TableImporterUI;
      case 'TABLE_EDITOR':
        return DndLibrary.TableEditor;
      default:
        return DndLibrary.FormBuilder;
    }
  }
}
