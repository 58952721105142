import { z } from 'zod';

import { DateFormatSchema } from '../../../../atom/dataSelector/data-date.atom';
import { ColumnSchema } from '../../../../trace/table/column.schema';
import { DateWidgetSchema } from '../../../../trace/widget/date.atom';
import { BaseEditorSchema, MultiEditorsSchema } from './shared.schema';

const DateEditorSchema = BaseEditorSchema.extend({
  type: z.literal('date'),
  inputFormat: DateFormatSchema,
  displayFormat: DateFormatSchema,
  saveFormatted: z.boolean()
});

export const DateColumnDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      cell: z.object({
        view: DateWidgetSchema.MainSchema
      }),
      required: z.boolean()
    })
    .merge(ColumnSchema.MainSchema(valueValidation))
    .merge(
      MultiEditorsSchema(
        z.object({
          date: DateEditorSchema
        })
      )
    )
    .strict();

export type TableEditorDateColumnData = z.infer<
  ReturnType<typeof DateColumnDataSchema>
>;
