export namespace ProgressWidgetConfig {
  export const DenominatorTypeList = ['none', 'number', 'variable'] as const;

  export const DenominatorTypeLabelMap = {
    none: 'No denominator',
    number: 'Static number',
    variable: 'State variable'
  };
}

