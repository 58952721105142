import styled, { css, keyframes } from 'styled-components';

import { Menu } from '@mui/joy';

const MAIN_HEADER_HEIGHT = '60px';

const RotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const HeaderSection = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  height: ${MAIN_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  font-family: EuclidCircularB, Helvetica, Arial, sans-serif;
  // box-shadow: 0px 3px 15px 0px #4b35d21a;
  // border-bottom: solid 1px #4b35d21a;
  background-color: white;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 10px;
  padding-right: 24px;
  align-items: center;
`;

export const NavbarSection = styled.nav`
  display: flex;
  opacity: 1;
  transition: opacity 250ms linear 250ms;
  justify-content: space-between;
`;

export const NavbarEditorSection = styled(NavbarSection)`
  align-items: center;
  gap: 20px;
`;

export const Text = styled('h3')<{ selected?: boolean }>`
  flex-shrink: 0;
  color: #413f48;
  padding-inline: 16px;
  cursor: pointer;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.05em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-family: Inter, Helvetica, Arial, sans-serif;

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 600;
      color: #4b35d2;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
    `}
  &:hover {
    color: #4b35d2;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const AccountPicture = styled('img')<{ $image: string }>`
  border-radius: 50px;
  ${({ $image }) => {
    return `background: url("${$image}");`;
  }}
  background-size: cover;
  width: 40px;
  cursor: pointer;
  height: 40px;
`;

export const ProfilMenu = styled.ul``;

export const ProfilElement = styled.li`
  appearance: none;
  background-color: #f2f1f5;
  flex-grow: 1;
  box-sizing: border-box;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  outline: none;
  color: inherit;
  &:focus:not(:hover) {
    color: #5246f7;
  }
  &:hover {
    background-color: #5246f7;
    color: #ffffff;
    border-top-color: #5246f7;
    & + * {
      border-top-color: #5246f7;
    }
  }
`;

export const ButtonIcon = styled.span`
  display: block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  & > svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const ButtonLabel = styled.span`
  display: block;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
`;

export const RainbowButton = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
  padding: 4px 12px;
  font-size: 14px;
  font-family: 'Inter', Roboto, Helvetica, Arial, sans-serif;
  color: rgb(55 65 81);
  font-weight: 500;
  overflow: hidden;
  line-height: 25px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadow.light};
    &:before {
      content: '';
      position: absolute;
      left: -10rem;
      right: -10rem;
      top: -10rem;
      bottom: -10rem;
      margin: auto;
      background: conic-gradient(
        #fd004c,
        #fe9000,
        #fff020,
        #3edf4b,
        #3363ff,
        #b102b7,
        #fd004c
      );
      z-index: -2;
      pointer-events: none;
      animation: ${RotateAnimation} 2s infinite linear;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background: white;
    border-radius: 3px;
    z-index: -1;
    pointer-events: none;
  }
`;

export const EnvironmentSpan = styled.span`
  background: linear-gradient(to right, #4b35d2 0%, #5246f7 100%);
  border-radius: 1rem;
  width: max-content;
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 20px;
  padding-inline: 12px;
  margin-left: 12px;
  user-select: none;
`;

export const NavbarEditorMenu = styled(Menu)`
  z-index: 100001 !important;
  max-width: 400px;
  .MuiInput-root {
    background-color: transparent;
  }

  .Mui-focusVisible {
    outline: none !important;
  }

  .Mui-focused::before {
    box-shadow: none;
  }
`;

export const CustomMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f4f8;
  }
`;

export const ProcessList = styled.div`
  max-height: 400px;
  overflow: auto;
`;

export const ProjectTypeTitle = styled.h4`
  font-size: 1rem;
  padding: 5px 10px;
  font-weight: 600;
`;

export const ProcessNameEllipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 0;
`;

export const ProcessInfos = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  width: 60%;
  max-width: 250px;
`;
