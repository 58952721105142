import { PropsWithChildren } from 'react';

import {
  CalendarIcon,
  Hash,
  ListCheck,
  ListChecks,
  LucideIcon,
  MessageCircle,
  TextCursorInput
} from 'lucide-react';
import { observer } from 'mobx-react';
import { EditorType } from 'shared';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { Label } from '@components/ui/label';
import { RadioGroup, RadioGroupItem } from '@components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/ui/select';

import { AtomModel } from '@models/atom.model';

import { ColumnElement, ColumnTitle } from '@atoms/columnElement';
import { InfoTooltip } from '@atoms/infoTooltip';

type Props = PropsWithChildren<
  {
    atomId: AtomModel['id'];
    isEditMode: boolean;
    onIsEditModeChange: (isEditMode: boolean) => void;
  } & (
    | { canSelectEditorType: false }
    | {
        canSelectEditorType: true;
        selectedEditorType: EditorType;
        possibleEditorTypes: EditorType[];
        onSelectedEditorTypeChange: (editorType: EditorType) => void;
      }
  )
>;

const ColumnModeValue = ['Display', 'Edit'] as const;

export const EditorMode = observer((props: Props) => {
  const onColumnModeChange = (columnMode: string) => {
    props.onIsEditModeChange(columnMode === 'Edit');
  };

  const selectedColumnMode = props.isEditMode ? 'Edit' : 'Display';
  return (
    <>
      {props.children}

      <Column $width="170px">
        <ColumnTitle>Column behavior</ColumnTitle>
        <ColumnElement>
          <div className="flex items-center space-x-2">
            <Label>Column mode</Label>
            <InfoTooltip
              title="Column mode"
              description="Whether the cells of the column are editable or in display mode."
            />
          </div>
          <RadioGroup
            value={selectedColumnMode}
            onValueChange={onColumnModeChange}
          >
            {ColumnModeValue.map((mode) => (
              <div className="flex items-center space-x-2" key={mode}>
                <RadioGroupItem value={mode} id={`${mode}-${props.atomId}`} />
                <Label
                  htmlFor={`${mode}-${props.atomId}`}
                  className="font-normal cursor-pointer"
                >
                  {mode}
                </Label>
              </div>
            ))}
          </RadioGroup>
        </ColumnElement>
        {props.isEditMode && props.canSelectEditorType && (
          <ColumnElement>
            <Label htmlFor={`editor-type-${props.atomId}`}>Editor type</Label>
            <Select
              onValueChange={props.onSelectedEditorTypeChange}
              value={props.selectedEditorType}
            >
              <SelectTrigger id={`editor-type-${props.atomId}`}>
                <SelectValue
                  placeholder={renderEditorTypeIcon(props.selectedEditorType)}
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {props.possibleEditorTypes.map((editorType) => (
                    <SelectItem value={editorType} key={editorType}>
                      {renderEditorTypeIcon(editorType)}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </ColumnElement>
        )}
      </Column>
    </>
  );
});

const editorTypeToInfo = {
  number: { name: 'Number', icon: Hash },
  comment: { name: 'Comment', icon: MessageCircle },
  date: { name: 'Date', icon: CalendarIcon },
  input: { name: 'Input', icon: TextCursorInput },
  multiselect: {
    name: 'Multiselect',
    icon: ListChecks
  },
  select: { name: 'Select', icon: ListCheck }
} as const satisfies Record<EditorType, { name: string; icon: LucideIcon }>;

const renderEditorTypeIcon = (editorType: EditorType) => {
  const { name, icon: Icon } = editorTypeToInfo[editorType];
  return (
    <div className="flex items-center gap-2">
      <Icon size={16} />
      {name}
    </div>
  );
};
