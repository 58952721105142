import { z } from 'zod';

import { AtomReferenceSchema } from '../../../atomReference.schema';

export const TO_USER_VIEW_TYPES = ['Avatar', 'Activity', 'Text'] as const;
export const TO_UserViewTypeSchema = z.enum(TO_USER_VIEW_TYPES);

export const TO_UserDataSchema = (_?: boolean) =>
  z
    .object({
      title: z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_UserViewTypeSchema
    })
    .strict();

export type TO_UserData = z.infer<ReturnType<typeof TO_UserDataSchema>>;
