import { PropsWithChildren } from 'react';

import { observer } from 'mobx-react';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { Input } from '@components/ui/input';
import { Label } from '@components/ui/label';

import { ColumnElement, ColumnTitle } from '@atoms/columnElement';
import { InfoTooltip } from '@atoms/infoTooltip';
import { BaseEditorOptions } from 'shared';

type Props = BaseEditorOptions & {
  onPlaceholderChange: (placeholder: string) => void;

  atomId: string;
  additionalChildren?: React.ReactNode;
};

export const EditorOptions = observer((props: PropsWithChildren<Props>) => {
  return (
    <>
      <Column $width="220px">
        <ColumnTitle>Editor options</ColumnTitle>
        {props.children}
        <ColumnElement>
          <div className="flex items-center w-full gap-2">
            <Label htmlFor={`placeholder-${props.atomId}`}>Placeholder</Label>
            <InfoTooltip
              title="Placeholder"
              description="The text to display when the cell's editor is empty."
            />
          </div>

          <Input
            id={`placeholder-${props.atomId}`}
            type="text"
            placeholder="Aa"
            value={props.placeholder}
            onChange={(e) => props.onPlaceholderChange(e.target.value)}
          />
        </ColumnElement>
      </Column>
      {props.additionalChildren}
    </>
  );
});
