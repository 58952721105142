import { z } from 'zod';

import { SettingsSchema } from './generic-table.schema';
import { BaseActionSchema, BaseCreateActionDTO } from './shared.action.schema';
import { ActionType } from './types';

export const encodingOptions = [
  'latin1',
  'utf8',
  'ucs2',
  'utf16le',
  'ascii',
  'base64',
  'hex'
] as const;

const EncodingSchema = z.enum(encodingOptions);
const ParsingSchema = z.object({
  encoding: EncodingSchema,
  csvParser: z.object({
    delimiter: z.string()
  })
});

const TableImporterMetadataSchema = z.object({
  name: z.string(),
  settings: SettingsSchema.extend({
    parsing: ParsingSchema,
    addFile: z.boolean(),
    fileKey: z.string(),
    addComments: z.boolean(),
    commentKey: z.string(),
    aggregation: z.object({})
  })
});

export const TableImporterDTOSchema = z.object({
  type: z.literal('TABLE_IMPORTER') satisfies z.ZodSchema<ActionType>,
  metadata: TableImporterMetadataSchema
});

export const TableImporterSchema = BaseActionSchema.merge(
  TableImporterDTOSchema
);

export const CreateTableImporterDTO = BaseCreateActionDTO.merge(
  TableImporterDTOSchema
);

export type TableImporterActionDTO = z.infer<typeof TableImporterDTOSchema>;
export type TableImporterAction = z.infer<typeof TableImporterSchema>;
export type TableImporterMetadata = z.infer<typeof TableImporterMetadataSchema>;
