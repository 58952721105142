import { z } from 'zod';

import { AtomReferenceSchema } from '../../../../other/state-menu.schema';
import { ResolvedVariableType } from '../../../variables';

export const OperatorSchema = z.enum([
  '==',
  '!=',
  '>',
  '<',
  '>=',
  '<=',
  'isTrue',
  'isFalse',
  'contains',
  'defined',
  'startsWith',
  'endsWith',
  'oneOf',
  'allOf',
  'noneOf'
]);
export type OperatorMap = Record<ResolvedVariableType, OperatorOption[]>;

export interface OperatorOption {
  label: string;
  value: Operator;
}
export type Operator = z.infer<typeof OperatorSchema>;

export const ConditionInfoSchema = z
  .object({
    operator: OperatorSchema,
    value: z.union([z.string(), z.array(z.string()), z.null()])
  })
  .strict();

export const ConditionDataSchema = z
  .object({
    selectedVariable: AtomReferenceSchema.nullable(),
    condition: ConditionInfoSchema.nullable()
  })
  .strict();

export type ConditionData = z.infer<typeof ConditionDataSchema>;
