import styled from 'styled-components';

interface ShortcutTile {
  $isMultiple?: boolean;
}

export const ShortcutContainer = styled.span`
  display: flex;
`;

export const ShortcutTile = styled.kbd<ShortcutTile>`
  display: flex;
  text-align: center;
  text-transform: capitalize;
  font-size: 12px;
  border: 1px solid rgb(216, 216, 216);
  background-color: rgb(254, 255, 254);
  box-shadow: rgba(0, 0, 0, 0.086) 0px 2px 0px 0px;
  padding: 3px;
  border-radius: 3px;
  /* height: 15px;
  width: 15px; */
  margin-right: ${({ $isMultiple }) => ($isMultiple ? '3px' : '0')};

  svg {
    height: 16px;
    width: 16px;
  }
`;
