import { TableEditorLabelsColumnData } from 'shared';

const DEFAULT_HEADER = 'Default Labels Column Header';
export const initialLabelsData: TableEditorLabelsColumnData = {
  title: DEFAULT_HEADER,
  width: 'large',
  isfixedColumn: false,
  isDisplayedByDefault: true,
  required: true,
  cell: {
    view: {
      type: 'labels'
    }
  }
};
