import { z } from "zod";
import { BooleanWidgetSchema, ColumnSchema } from "../../../../trace";


export const BooleanColumnDataSchema = (valueValidation?: boolean) =>
    z
        .object({
            cell: z.object({
                view: BooleanWidgetSchema.MainSchema,
            }),
            required: z.boolean(),
        })
        .merge(ColumnSchema.MainSchema(valueValidation))
        .strict();

export type TableEditorBooleanColumnData = z.infer<
    ReturnType<typeof BooleanColumnDataSchema>
>;
