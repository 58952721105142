import { z } from 'zod';

import { AtomReferenceSchema } from '../../../atomReference.schema';

export const TO_FILE_VIEW_TYPES = ['File Compact', 'File'] as const;
export const TO_FileViewTypeSchema = z.enum(TO_FILE_VIEW_TYPES);

export const TO_FileDataSchema = (_?: boolean) =>
  z
    .object({
      title: z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_FileViewTypeSchema
    })
    .strict();

export type TO_FileData = z.infer<ReturnType<typeof TO_FileDataSchema>>;
