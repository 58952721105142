import { INITIAL_DATA_SELECTOR_DATA } from '@atoms/dataSelectors/dataSelector/dataSelector.data';
import { MultiSelectTableEditorColumnData } from 'shared';


const DEFAULT_HEADER = 'Default Multi-Select Column Header';
export const initialSelectData: MultiSelectTableEditorColumnData = {
  title: DEFAULT_HEADER,
  width: 'medium',
  isfixedColumn: false,
  isDisplayedByDefault: true,
  required: true,
  isEditMode: true,
  editorOptions: {
    selectedEditor: 'multiselect',
    editors: {
      multiselect: {
        type: 'multiselect',
        placeholder: 'Select an option',
        options: INITIAL_DATA_SELECTOR_DATA,
        delay: undefined,
        disabledPath: undefined
      }
    }
  }
};
