import { z } from 'zod';

import { TitleInputSchema } from '../../../../other/otherUtils';
import { DataSelectorSchema } from '../../../dataSelector';
import { PrefillDataSchema } from './prefill.schema';

export const CheckBoxTypeSchema = z.enum(['checkbox', 'radio']);
export type CheckBoxType = z.infer<typeof CheckBoxTypeSchema>;

export const CheckBoxDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean(),
      checkboxType: CheckBoxTypeSchema
    })
    .merge(DataSelectorSchema)
    .merge(PrefillDataSchema)
    .strict();

export type CheckBoxData = z.infer<ReturnType<typeof CheckBoxDataSchema>>;
