import { Dispatch, MouseEventHandler, SetStateAction } from 'react';

import { ChevronRight } from 'lucide-react';
import Highlighter from 'react-highlight-words';

import { AtomModel } from '@models/atom.model';

import { ResolvedVariableType } from 'shared'

import { ChipLabel } from '@atoms/chipLabel';

import { StateMenuItem, StateMenuVar } from './stateMenu.style';

type VariableItemProps = {
  id: string;
  onHover: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  onMouseEnter: Dispatch<SetStateAction<unknown>>;
  onMouseLeave?: Dispatch<SetStateAction<unknown>>;
  searchVar: string;
  variable: AtomModel<{
    title: string;
    variableInfo?: { resolvedType?: ResolvedVariableType };
  }>;
  actionName?: string;
};

const VariableItem = ({
  id,
  onHover,
  onClick,
  onMouseEnter,
  onMouseLeave,
  searchVar,
  variable,
  actionName
}: VariableItemProps) => {
  const VariableComp = actionName
    ? {
        component: StateMenuItem,
        props: {
          id,
          $onHover: onHover,
          onClick: onClick,
          onMouseEnter: onMouseEnter,
          onMouseLeave: onMouseLeave
        }
      }
    : {
        component: StateMenuVar,
        props: {
          id,
          $onHover: onHover,
          onClick: onClick,
          onMouseEnter: onMouseEnter
        }
      };

  return (
    <VariableComp.component {...VariableComp.props}>
      {actionName ? (
        <div>
          {actionName}
          <ChevronRight size={'0.8em'} />
          <Highlighter
            searchWords={[searchVar]}
            textToHighlight={variable.data.title}
          />
        </div>
      ) : (
        <Highlighter
          searchWords={[searchVar]}
          textToHighlight={variable.data.title}
        />
      )}
      <ChipLabel>{variable.variableInfo?.resolvedType}</ChipLabel>
    </VariableComp.component>
  );
};

export default VariableItem;
