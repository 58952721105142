import { z } from 'zod';

import { AtomReferenceSchema, TraceKeySchema } from '../other';

export enum CellDataType {
  Text = 'text',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  DateString = 'dateString',
  Object = 'object'
}

export enum ValueSetters {
  NON_EMPTY_STRING = 'nonEmptyString'
}

export const DatabaseColumnSchema = z
  .object({
    field: z.string(),
    cellDataType: z.nativeEnum(CellDataType),
    context: z
      .object({
        reference: z
          .object({
            databaseId: z.string()
          })
          .optional(),
        isPrimaryKey: z.boolean().optional()
      })
      .optional()
  })
  .passthrough();
export type Column = z.infer<typeof DatabaseColumnSchema>;

export const ColumnsSchema = z.array(DatabaseColumnSchema);
export type Columns = z.infer<typeof ColumnsSchema>;

export const DatabaseSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    traceKey: TraceKeySchema,
    columnDefinitions: ColumnsSchema,
    rowReferences: z.array(AtomReferenceSchema),
    processId: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    deletedAt: z.string().datetime().nullable()
  })
  .strict();
export type Database = z.infer<typeof DatabaseSchema>;

export const UpdateDatabaseSchema = DatabaseSchema.omit({
  id: true
});
export type UpdateDatabase = z.infer<typeof UpdateDatabaseSchema>;
