
import { DNDModel } from '@models/dnd.model';

import { DNDBlock, LoadedDndModel } from 'shared'

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

import { DndLibrary } from '@library';

export class DNDStore extends BaseStore<DNDModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, DNDModel, 'dnd');
  }

  createNewDndModel(
    library: DndLibrary,
    newDndModelId?: string,
    state?: DNDBlock<'root'>,
    loading?: boolean
  ): DNDModel {
    const newDndModel = new DNDModel(
      this,
      library,
      newDndModelId,
      state,
      loading
    );

    this.set(newDndModel.id, newDndModel);
    return newDndModel;
  }

  createDndFromLoaded(library: DndLibrary, loaded: LoadedDndModel): DNDModel {
    return this.createNewDndModel(library, loaded.id, loaded.state as DNDBlock<"root">);
  }
}
