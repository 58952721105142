import { CheckSquare, LucideIcon } from 'lucide-react';

import { parseWithZod } from '@/utils/parseZodSchema';
import { CheckBoxType, CheckBoxTypeSchema } from 'shared';

export const CheckBoxIcon: LucideIcon = CheckSquare;

export interface CheckBoxTypeOption {
  name: string;
  value: CheckBoxType;
  isDisabled?: boolean;
}

export const checkBoxTypeOptions: CheckBoxTypeOption[] = [
  {
    name: 'Checkbox',
    value: 'checkbox'
  },
  {
    name: 'Radio',
    value: 'radio'
  }
];

export const isCorrectCheckBoxType = (
  checkBoxType: string
): checkBoxType is CheckBoxType => {
  return !!parseWithZod(CheckBoxTypeSchema, checkBoxType, 'CHECKBOX-1');
};
