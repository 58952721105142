import { z } from 'zod';

export const GatewayTypeSchema = z.enum(['PARALLEL', 'INCLUSIVE', 'EXCLUSIVE']);

export const GatewaySchema = z.object({
  type: GatewayTypeSchema,
  deletedAt: z.string().datetime().nullable().optional(),
  id: z.string()
});

export type GatewayType = z.infer<typeof GatewayTypeSchema>;

export type GatewayLoaded = z.infer<typeof GatewaySchema>;
