import styled, { css } from 'styled-components';

import {
  BLOCK_HEADER_HEIGHT,
  BLOCK_HEADER_MARGIN_BOTTOM
} from './blockBase.style';

interface ColumnProps {
  $width?: string;
  $gap?: string;
  $min?: string;
  $max?: string;
  $justifyContent?: string;
  $grow?: number;
}

interface EndControlsContainerProps {
  $isCondition?: boolean;
}

interface BlockBodyProps {
  $isStatement?: boolean;
}

/** A simple column with a default width of 145px which can be overwritten with the `$width` property. */
export const Column = styled.div<ColumnProps>`
  width: ${(p) => p.$width ?? '145px'};
  flex-shrink: 0;
  display: flex;
  ${({ $grow }) =>
    $grow &&
    css`
      flex-grow: ${$grow};
    `}
  flex-direction: column;
  justify-content: ${(p) => p.$justifyContent ?? 'initial'};
  gap: ${(p) => p.$gap ?? '0.6rem'};
  min-width: ${(p) => p.$min};
  max-width: ${(p) => p.$max};
`;

export const BlockBody = styled.form<BlockBodyProps>`
  width: 100%;
  flex-grow: 1;
  height: calc(100% - 40px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  ${({ $isStatement }) => !$isStatement && 'gap: 1rem;'}
`;

export const EndControlsContainer = styled.div<EndControlsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: ${({ $isCondition }) =>
    $isCondition
      ? 'auto'
      : `calc(100% - ${BLOCK_HEADER_HEIGHT} - ${BLOCK_HEADER_MARGIN_BOTTOM})`};
  gap: 0.5rem;
  flex-shrink: 0;
`;
