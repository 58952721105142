import Modeler from 'bpmn-js/lib/Modeler';
import Modeling, { EventBus } from 'bpmn-js/lib/features/modeling/Modeling';
import { Element } from 'bpmn-js/lib/model/Types';
import { camelCase } from 'camel-case';
import { Connection } from 'diagram-js/lib/model/Types';

import { ElementRegistry } from '@components/editor/plugin/dragging/Dragging';

import { newError } from '@/services/errors/errors';
import { ElementType, ModdleElement } from '@/types/bpmn.types';

export type BpmnState = {
  modeler: Modeler;
  selectedElement: Element;
};

type BusinessObjectData = {
  id: string;
  name: string;
  sourceRef: ModdleElement;
  targetRef: ModdleElement;
};

export type BusinessObject = Partial<BusinessObjectData>;

export class BpmnModel {
  state: Partial<BpmnState>;

  constructor() {
    this.state = {};
  }

  get toJSON() {
    return {};
  }

  setModeler(modeler: Modeler) {
    this.state.modeler = modeler;
  }

  setSelectedElement(selectedElement: Element) {
    this.state.selectedElement = selectedElement;
  }

  get modeler(): Maybe<Modeler> {
    return this.state.modeler;
  }

  get selectedElement(): Maybe<Element> {
    return this.state.selectedElement;
  }

  get allElements(): Maybe<Element[]> {
    return this.elementRegistry?.getAll() as Element[];
  }

  get modeling(): Maybe<Modeling> {
    return this.state.modeler?.get<Modeling>('modeling');
  }

  get elementRegistry(): Maybe<ElementRegistry> {
    return this.modeler?.get<ElementRegistry>('elementRegistry');
  }

  get eventBus(): Maybe<EventBus> {
    return this.modeler?.get<EventBus>('eventBus');
  }

  /* -------------------------------------------------------------------------- */
  /*                                Element Utils                               */
  /* -------------------------------------------------------------------------- */

  public static getModdleElementName(el: ModdleElement) {
    if (el.name) return el.name;

    switch (el.$type) {
      case ElementType.ExclusiveGateway:
        return 'Exclusive Gateway';
      case ElementType.ParallelGateway:
        return 'Parallel Gateway';
      case ElementType.InclusiveGateway:
        return 'Inclusive Gateway';
      case ElementType.StartEvent:
        return 'Start Event';
      case ElementType.EndEvent:
        return 'End Event';
      case ElementType.Form:
        return 'Unnamed Form Action';
      case ElementType.TableImporter:
        return 'Unnamed Table Importer Action';
      case ElementType.TableEditor:
        return 'Unnamed Table Editor Action';
      default:
        return 'No name';
    }
  }

  changeElementName(
    name: string,
    element: Maybe<Element> = this.selectedElement
  ): void {
    if (!element || !this.modeling) {
      newError(
        'BPMN-3ba05',
        {
          name,
          element,
          message:
            'Cannot change the name of the element (this.selectedElement is undefined?)'
        },
        true,
        {
          description: 'Cannot change the name of the element'
        }
      );
      return;
    }

    this.modeling.updateProperties(element, {
      name
    });
  }

  /** Finds a BPMN element using its `id` and its `type`
   * @returns a BPMN element or `undefined`
   * @note Actually returns an object of type `ElementLike` from `bpmn-js` library,
   * but it doesn't looks like it's being exported, so we use `Element` instead.
   * @exemple
   * ```ts
   * const element: Maybe<Element> = findElementById('0lg5tdk', ElementType.Action)
   * ```
   */
  getElementById(id: string): Maybe<Element> {
    if (!this.elementRegistry) return;
    return this.elementRegistry.get(id) as Element;
  }

  setSelectedElementByElementId(id: string): Maybe<Element> {
    const element = this.getElementById(id);
    if (!element) return;
    this.setSelectedElement(element);
    return element;
  }

  resetSelectedElement(): void {
    this.state.selectedElement = undefined;
  }

  getElementConnections(
    element: Maybe<Element> = this.selectedElement
  ): Maybe<Connection[]> {
    if (!element) return;
    return element.outgoing;
  }

  getSourceAndTargetElements(
    element: Maybe<Element> = this.selectedElement
  ): [Maybe<ModdleElement>, Maybe<ModdleElement>] {
    if (!element) return [undefined, undefined];
    if (!this.isTransition(element)) return [undefined, undefined];
    const businessObject = element.businessObject as Partial<BusinessObject>;
    return [businessObject.sourceRef, businessObject.targetRef];
  }

  isTransition = (element: Maybe<Element> = this.selectedElement): boolean => {
    if (!element) return false;
    const elementType = element.type as ElementType;
    return (
      elementType === ElementType.MessageFlow ||
      elementType === ElementType.SequenceFlow
    );
  };

  //! not used (yet)
  reactToEvents = (): void => {
    this.eventBus?.on('element.click', (event) => {
      console.info('element.click', event);
    });
  };

  getLaneNames(): Maybe<string[]> {
    if (!this.elementRegistry) return;

    const laneElements: ModdleElement[] = this.elementRegistry
      .getAll()
      .map((bpmnElement) => bpmnElement.businessObject as ModdleElement)
      .filter((moddleElement) => moddleElement.$type === ElementType.Lane);

    const laneNames = laneElements.map((lane) => camelCase(lane.name));
    return laneNames;
  }

  getWorkflowName(): Maybe<string> {
    if (!this.elementRegistry) return;

    const participant = this.elementRegistry
      .getAll()
      .filter(
        (el) =>
          (el.businessObject as ModdleElement).$type == ElementType.Participant
      );

    if (participant.length == 0) {
      throw Error('No participant in the current process');
    }

    const el = participant[0];

    return (el.businessObject as ModdleElement).name;
  }

  get errors() {
    return [];
  }
}
