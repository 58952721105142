import { ProgressWidgetSchema } from 'shared';
import { ProgressWidgetConfig } from './progress.config';
import { ProgressWidget as Comp } from './progress.widget';
import { initialProgressWidgetData } from './progress.widget.data';

export namespace ProgressWidget {
  export const Base = Comp;
  export type Data = ProgressWidgetSchema.Data;
  export import Config = ProgressWidgetConfig;
  export const initialData = initialProgressWidgetData;
  export import Schema = ProgressWidgetSchema;
}
