import {
  AlignJustify,
  AlignLeft,
  AppWindow,
  CalendarDays,
  Check,
  CheckSquare,
  FileUp,
  Hash,
  List,
  LucideIcon,
  Mail,
  MessageCircleMore,
  MessageSquarePlus,
  PanelTopOpen,
  Sheet,
  TableCellsSplit,
  Tags,
  UserCircle,
  Variable
} from 'lucide-react';
import { AtomType, BlockType } from 'shared';

export const iconMap: Record<AtomType | BlockType, LucideIcon> = {
  root: AppWindow,
  ['Check Box']: CheckSquare,
  ['Date']: CalendarDays,
  ['Drop Down']: PanelTopOpen,
  ['File Upload']: FileUp,
  ['Number']: Hash,
  ['Rich Text']: MessageSquarePlus,
  ['TextField']: AlignLeft,
  ['Comment']: MessageCircleMore,

  ['TO_Date']: CalendarDays,
  ['TO_File']: FileUp,
  ['TO_List']: List,
  ['TO_Number']: Hash,
  ['TO_Text']: AlignLeft,
  ['TO_User']: UserCircle,
  ['TO_KeyValue']: Tags, // TODO: find the icon

  ['TI_Date']: CalendarDays,
  ['TI_File']: FileUp,
  ['TI_List']: List,
  ['TI_Number']: Hash,
  ['TI_Text']: AlignLeft,
  ['TI_DataTab']: Sheet,
  ['TI_Comment']: MessageCircleMore,
  ['TI_Window']: AppWindow,
  ['TI_KeyValue']: Tags, // TODO: find the icon

  ['Condition']: AlignJustify,
  ['Code Condition']: Variable,
  ['Or']: AppWindow,
  ['And']: AppWindow,
  ['Not']: AppWindow,

  ['GlobalVariable']: Variable,

  ['Send Email']: Mail,

  ['Table_Importer_Date']: CalendarDays,
  ['Table_Importer_Number']: Hash,
  ['Table_Importer_Text']: AlignLeft,
  ['Table_Importer_Boolean']: Check,
  ['Table_Importer_Labels']: Tags,

  ['Table_Editor_Text']: AlignLeft,
  ['Table_Editor_Number']: Hash,
  ['Table_Editor_Date']: CalendarDays,
  ['Table_Editor_Boolean']: Check,
  ['Table_Editor_Labels']: Tags,
  ['Table_Editor_Select']: Tags, // TODO: find the icon
  ['Table_Editor_MultiSelect']: Tags, // TODO: find the icon
  ['Row']: TableCellsSplit
};
