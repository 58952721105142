import { observer } from 'mobx-react';

import { Label } from '@components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/ui/select';
import { Locale, LocaleList } from 'shared';
import { LocalToInfosMap } from './localFormatSelector.utils';


type Props = {
  selectedLocale: Locale;
  onLocaleChange: (locale: Locale) => void;

  id?: string;
};
export const LocalFormatSelector = observer((props: Props) => {
  return (
    <>
      <Label htmlFor={`locale-${props.id}`}>Locale format</Label>
      <Select onValueChange={props.onLocaleChange} value={props.selectedLocale}>
        <SelectTrigger id={`locale-${props.id}`}>
          <SelectValue placeholder={renderLocaleValue(props.selectedLocale)} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {LocaleList.map((locale) => (
              <SelectItem value={locale} key={locale}>
                {renderLocaleValue(locale)}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
});

const renderLocaleValue = (locale: Locale) => {
  const { emoji, name } = LocalToInfosMap[locale];
  return (
    <div className="flex items-center gap-2">
      <p>{emoji}</p>
      <p>{name}</p>
    </div>
  );
};
