import { useState } from 'react';

import { Pencil, X } from 'lucide-react';

import StateMenu, { StateMenuProps } from '@components/stateMenu';
import { Button } from '@components/ui/button';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogTrigger
} from '@components/ui/dialog';

import useStore from '@hooks/useStore';
import { AtomReference } from 'shared';

type Props = StateMenuProps & {
  selectedRef?: AtomReference | null;
  editable?: boolean;
  /** @deprecated Use `selectedRef` props instead! */
  value?: string;
  placeholder?: string;
  disabled?: boolean;
};

export const VariableSelector = ({
  value,
  selectedRef,
  placeholder = 'Select a variable',
  disabled = false,
  editable = true,
  onSelected,
  specificSourceIds,
  specificResolvedTypes
}: Props) => {
  const { atomStore } = useStore();
  const [isStateMenuOpen, setStateMenuOpen] = useState<boolean>(false);

  const name =
    atomStore.getAtomReferenceTitle(selectedRef) ?? value ?? placeholder;

  const onRemoveSelectedRef = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!selectedRef) return;
    e.stopPropagation();
    if (!onSelected) return;
    onSelected(undefined);
  };
  return (
    <Dialog
      open={isStateMenuOpen}
      onOpenChange={(open) => setStateMenuOpen(open)}
    >
      <DialogTrigger disabled={disabled}>
        <Button
          variant="outline"
          className="flex overflow-hidden h-8 gap-2 rounded-md border border-solid hover:bg border-input bg-background pl-3 pr-1 py-2 text-sm ring-offset-background justify-between items-center w-[220px] font-normal hover:bg-accent hover:text-accent-foreground"
          data-tag="variable-field"
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            if (!onSelected || disabled) return;
            setStateMenuOpen(true); // ! bad
          }}
        >
          <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
            {name}
          </p>
          {editable && (
            <>
              {selectedRef ? (
                <div
                  className="h-6 w-6 rounded shrink-0 flex items-center justify-center hover:bg-gray-300"
                  onClick={onRemoveSelectedRef}
                >
                  <X className="w-4 h-4 opacity-50 shrink-0" />
                </div>
              ) : (
                <div className="h-6 w-6 rounded shrink-0 flex items-center justify-center hover:bg-gray-300">
                  <Pencil className="w-4 h-4 opacity-50 shrink-0" />
                </div>
              )}
            </>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogBody className="p-0 m-0">
          <StateMenu
            onSelected={onSelected ?? (() => {})}
            onClose={() => setStateMenuOpen(false)}
            specificResolvedTypes={specificResolvedTypes}
            specificSourceIds={specificSourceIds}
          />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};
