import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { ParamsList } from '@/routes/routes.types';

import StratumnEditor from './stratumn.editor';

export const EditorWrapper = observer(() => {
  const workflowId = useParams()[ParamsList.WorkflowId];

  return (
    <div className="w-full h-full" key={workflowId}>
      <StratumnEditor />
    </div>
  );
});
