import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { TableColumn } from '@components/trace/column';
import { Label } from '@components/ui/label';

import { useAtom } from '@hooks/useAtom';

import { ResolvedVariableType, DNDBlock } from 'shared'

import { ColumnElement, ColumnTitle } from '@atoms/columnElement';
import { DateFormatPicker } from '@atoms/dateFormat/dateFormat';
import { ExempleTooltip, InfoTooltip } from '@atoms/infoTooltip';
import { SwitchV2 } from '@atoms/switch';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';
import { Widgets } from '@widgets';

import { initialDateData } from './date.data';
import { DateSettingsModal as DateSettingsModal } from './date.settings';
import { DateIcon } from './date.utils';

const DateTableImporterBlock = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    type: 'Table_Importer_Date',
    sourceId: block.id,
    parentId: actionId,
    parentKind: 'table_importer',
    initialData: initialDateData,
    variableInfo: {
      resolvedType: ResolvedVariableType.Date
    }
  });

  if (!atom) return null;

  const getModalSettings = (): SettingsConfig => {
    return {
      title: atom.data.title || `Date Column Title`,
      content: <DateSettingsModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getModalSettings()}
      hasRequired
    ></EndControlsFB>
  );

  const onTitleChange = (title: TableColumn.Data['title']) => {
    if (atom.data.title === atom.data.importerOptions.from) {
      atom.data.importerOptions.from = title; // From follows the column header
    }
    atom.data.title = title;
  };

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={DateIcon}
      endControls={getEndControls()}
    >
      <TableColumn.Base
        atomId={atom.id}
        title={atom.data.title}
        width={atom.data.width}
        isDisplayedByDefault={atom.data.isDisplayedByDefault}
        onTitleChange={onTitleChange}
        onWidthChange={(v) => (atom.data.width = v)}
        isfixedColumn={atom.data.isfixedColumn}
        onFixedColumnChange={(v) => (atom.data.isfixedColumn = v)}
        onDisplayByDefaultChange={(v) => (atom.data.isDisplayedByDefault = v)}
      />
      <Column $width="220px">
        <ColumnTitle>CSV parser options</ColumnTitle>
        <ColumnElement>
          <SwitchV2
            id={`parser-activated-${atom.id}`}
            checked={atom.data.importerOptions.parserOptions.activated}
            onCheckedChange={(v) =>
              (atom.data.importerOptions.parserOptions.activated = v)
            }
            labelText="Parse CSV"
          />
        </ColumnElement>
        {atom.data.importerOptions.parserOptions.activated === true && (
          <>
            <ColumnElement>
              <div className="flex items-center gap-2">
                <Label htmlFor={`parser-input-format-${atom.id}`}>
                  CSV date format
                </Label>
                <InfoTooltip
                  title="CSV date format"
                  description={`The format of the dates in the column ${atom.data.importerOptions.from} of the CSV file`}
                  additionalContent={
                    <ExempleTooltip
                      exampleName="Input format"
                      input="DD/MM/YYYY"
                      output="21/01/2023"
                    />
                  }
                />
              </div>
              <DateFormatPicker
                id={`parser-input-format-${atom.id}`}
                format={
                  atom.data.importerOptions.parserOptions.parser.inputFormat
                }
                onFormatChange={(format) =>
                  (atom.data.importerOptions.parserOptions.parser.inputFormat =
                    format)
                }
              />
            </ColumnElement>
            <ColumnElement>
              <div className="flex items-center gap-2">
                <Label htmlFor={`parser-output-format-${atom.id}`}>
                  Stored date format
                </Label>
                <InfoTooltip
                  title="Stored date format"
                  description="The format in which the imported date is stored in the Trace's state"
                  additionalContent={
                    <ExempleTooltip
                      exampleName="Output format"
                      input="DD/MM/YYYY"
                      output="21/01/2023"
                    />
                  }
                />
              </div>
              <DateFormatPicker
                id={`parser-output-format-${atom.id}`}
                format={
                  atom.data.importerOptions.parserOptions.parser.outputFormat
                }
                onFormatChange={(format) =>
                  (atom.data.importerOptions.parserOptions.parser.outputFormat =
                    format)
                }
              />
            </ColumnElement>
          </>
        )}
      </Column>
      <Widgets.Date.Base
        atomId={atom.id}
        format={atom.data.cell.view.format}
        onFormatChange={(format) => (atom.data.cell.view.format = format)}
      />
    </BlockBase>
  );
};

export default observer(DateTableImporterBlock);
