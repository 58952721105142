import { z } from 'zod';

import { AtomReferenceSchema } from '../../other/state-menu.schema';
import { DataSourceOptionSchema } from './data-options.atom';
import { NormalSourceSchema } from './data-static.atom';
import { SelectedDatabaseSchema } from './selectedDatabase.atom';

export const SourcesDataSchema = z.object({
  normalSource: NormalSourceSchema.optional(),
  database: SelectedDatabaseSchema.optional(),
  stateVariable: AtomReferenceSchema.optional()
});

export const DataSelectorSchema = z.object({
  dataSource: DataSourceOptionSchema,
  dataSourceData: SourcesDataSchema
});

export type SourcesData = z.infer<typeof SourcesDataSchema>;
export type DataSelectorData = z.infer<typeof DataSelectorSchema>;
