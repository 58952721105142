import { useEffect } from 'react';

import { LucideIcon, Mail } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';
import useVariableNotifications from '@hooks/useNotificationVariables';

import { SelectField } from '@atoms/select';

import { ParamsList } from '@/routes/routes.types';
import { deepCopy } from '@/utils/deepCopy';
import EndControlsFB from '@library/formBuilder/endControls';
import { FormControl, FormLabel, Option } from '@mui/joy';

import TemplateVariable from './TemplateVariable';
import { initialEmailData } from './email.data';
import EmailHeader from './email.header';
import { DNDBlock } from 'shared';

export const EmailIcon: LucideIcon = Mail;

export type SelectEvent =
  | React.MouseEvent<Element, MouseEvent>
  | React.KeyboardEvent
  | React.FocusEvent<Element, Element>
  | null;

const TVList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Email = (block: DNDBlock) => {
  const transitionId = useParams()[ParamsList.TransitionId] as string;
  const notificationsVariables = useVariableNotifications();

  const atom = useAtom({
    type: 'Send Email',
    sourceId: block.id,
    parentId: transitionId,
    parentKind: 'atom',
    initialData: initialEmailData
  });

  useEffect(() => {
    if (!atom) return;

    if (
      atom.data.dataSource?.template &&
      atom.data.dataSourceData?.templateVariables &&
      notificationsVariables
    ) {
      // Check if template  still exist
      const currentVariable = notificationsVariables.find(
        (o) => o.id === atom.data.dataSource?.template
      );

      if (currentVariable) {
        const notifId: string[] = deepCopy(
          atom.data.dataSourceData.templateVariables
        ).map((temp) => temp.variable);

        const updateTemplateVariables = currentVariable.variables.map((v) => {
          if (
            atom.data.dataSourceData?.templateVariables &&
            notifId.includes(v.text)
          ) {
            const notifValue = deepCopy(
              atom.data.dataSourceData.templateVariables
            ).find((temp) => temp.variable === v.text);

            return {
              variable: v.text,
              value: notifValue?.value ?? null
            };
          }

          return { variable: v.text, value: null };
        });

        atom.data.dataSourceData.templateVariables =
          updateTemplateVariables.length === 0 ? null : updateTemplateVariables;
      } else {
        //Remove data if template no longer exist
        atom.data.dataSourceData.templateVariables = null;
        atom.data.dataSource.template = '';
      }
    }
  }, [atom, notificationsVariables]);

  if (!atom) return null;

  const handleChange = (value: string | null): void => {
    if (!value || !atom.data.dataSource || !atom.data.dataSourceData) {
      return; // Check if value exist to avoid to filling dataSource.template with a null value on load
    }

    atom.data.dataSource.template = value;

    if (notificationsVariables) {
      const currentVariable = notificationsVariables.find(
        (o) => o.id === value
      );
      if (currentVariable?.variables && currentVariable.variables.length > 0) {
        const variableState = currentVariable.variables.map((v) => ({
          variable: v.text,
          value: null
        }));
        atom.data.dataSourceData.templateVariables = variableState;
      } else {
        atom.data.dataSourceData.templateVariables = null;
      }
    }
  };

  if (!atom) return null;

  return (
    <>
      <BlockBase
        dndBlock={block}
        title={'notification'}
        icon={EmailIcon}
        header={<EmailHeader atom={atom} blockAtomId={block.atomId} />}
        endControls={
          <EndControlsFB
            hasRequired={false}
            dataItem={atom}
            dndBlock={block}
            isHovering={false}
          />
        }
      >
        <Column $width="auto">
          <SelectField
            label="Template"
            value={atom.data.dataSource?.template ?? ''}
            onChange={(_, value) => handleChange(value)}
          >
            {notificationsVariables &&
              notificationsVariables.map((notif, index) => (
                <Option key={index} value={notif.id}>
                  {notif.title}
                </Option>
              ))}
          </SelectField>
          {atom.data.dataSourceData?.templateVariables && (
            <FormControl sx={{ marginTop: '10px' }} size="sm">
              <FormLabel>Template variables</FormLabel>
              <TVList>
                {atom.data.dataSourceData.templateVariables.map(
                  (template, index) => (
                    <TemplateVariable
                      key={index}
                      template={template}
                      blockAtomId={block.atomId}
                    />
                  )
                )}
              </TVList>
            </FormControl>
          )}
        </Column>
      </BlockBase>
    </>
  );
};

export default observer(Email);
