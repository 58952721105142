import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Side } from './panel.type';

export const PANEL_CONTENT_PADDING = '32px';
const PANEL_MARGIN_TOP = 20;
const BORDER_RADIUS = '10px';

interface WrapperProps {
  $side: Side;
}

export const PanelWrapper = styled(motion.aside)<WrapperProps>`
  position: absolute;
  bottom: 0;
  height: calc(100% - ${PANEL_MARGIN_TOP}px);
  box-sizing: border-box;
  z-index: 20;
  background-color: ${({ theme }) => theme.color.white[2]};
  /* animation: 0.15s openRightPanel ease-out; */
  overflow: hidden;
  ${({ $side }) => {
    if ($side === Side.Right) {
      return `
        right: 0;
        border-top-left-radius: ${BORDER_RADIUS};
      `;
    } else {
      return `
        left: 0;
        border-top-right-radius: ${BORDER_RADIUS};
      `;
    }
  }};
`;

export const PanelBody = styled.div`
  height: calc(
    100% - 112px
  ); //! magic number, resizable component takes more that 100% of panel height, no idea why
  width: 100%;
  position: relative;
  box-sizing: border-box;
  /* overflow-y: auto; */
`;
