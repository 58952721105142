import { ReactNode } from 'react';

import { Label } from '@components/ui/label';
import { Switch } from '@components/ui/switch';

import {
  FormControl as MuiFormControl,
  FormLabel as MuiFormLabel,
  Switch as MuiSwitch,
  switchClasses as MuiSwitchClasses,
  SwitchProps as MuiSwitchProps
} from '@mui/joy';
import * as SwitchPrimitives from '@radix-ui/react-switch';

interface SwitchFieldProps extends MuiSwitchProps {
  label?: ReactNode;
  title?: string;
}
/** @deprecated */
export const SwitchField = (props: SwitchFieldProps) => (
  <div>
    {props.title && (
      <MuiFormControl size={props.size || 'sm'}>
        <MuiFormLabel required={props.required}>{props.title}</MuiFormLabel>
      </MuiFormControl>
    )}
    <MuiSwitch
      size={'sm'}
      sx={{
        [`& .${MuiSwitchClasses.thumb}`]: {
          transition: 'left 0.2s',
          width: 14,
          height: 14
        }
      }}
      endDecorator={props.label}
      {...props}
    />
  </div>
);

type SwitchV2Props = React.ComponentPropsWithoutRef<
  typeof SwitchPrimitives.Root
> & {
  id: string;
  labelText: string;
  tooltip?: ReactNode;
};
export const SwitchV2 = ({ labelText, tooltip, ...props }: SwitchV2Props) => {
  return (
    <div className="flex items-center space-x-2 select-none">
      <Switch {...props} />
      <Label htmlFor={props.id} className="text-sm font-normal cursor-pointer">
        {labelText}
      </Label>
      {tooltip}
    </div>
  );
};
