import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ProcessModel } from '@models/process.model';

import { ParamsList } from '@/routes/routes.types';
import { newError } from '@/services/errors/errors';

import useStores from './useStore';

/**
 * Returns the correct instance of `ProcessModel` using process ID found in the url parameters.
 */
interface ProcessData {
  process: ProcessModel;
  processId: string;
}

const useProcess = (): ProcessData => {
  const processId = useParams()[ParamsList.ProcessId] as string;
  const { processStore } = useStores();

  const process = processStore.getProcessById(processId);

  // TODO: refactor this
  useEffect(() => {
    if (!processId) return;

    if (process.status == 'NEED_LOADING') {
      console.log('Fetching the process', processId);
      process.fetchProcess().catch((e) => {
        newError('USPR-gjH3f', e, true);
      });
    }
  }, [process.status, process, processId]);

  return {
    process,
    processId
  };
};

export default useProcess;
