import { ChevronUp } from 'lucide-react';
import styled, { css } from 'styled-components';

interface BlockProps {
  $isDragging: boolean;
  $isCollapsed: boolean;
  $isNested: boolean;
}

interface LeftSideProps {
  $isCondition?: boolean;
}

interface BlockHeaderProps {
  $isStatement?: boolean;
}

export const BlockContainer = styled.div<BlockProps>`
  /* transform: translate3d(0, 0, 0); */
  width: 100%;
  box-sizing: border-box;
  ${({ theme, $isNested }) =>
    $isNested
      ? css`
          border: 1px solid ${theme.color.grey[6]};
        `
      : css`
          box-shadow: ${theme.boxShadow.light};
        `}

  padding: 16px;
  padding-left: 0;
  border-radius: 5px;
  display: flex;
  transition: all 0.2s;
  background-color: white;
  flex-shrink: 0;
  ${(p) =>
    p.$isDragging &&
    css`
      opacity: 0.2;
    `}

  ${(p) =>
    p.$isCollapsed &&
    css`
      height: 55px;
      overflow: hidden;
    `}
`;

export const BlockConditionContainer = styled(BlockContainer)<BlockProps>`
  ${({ theme, $isNested }) =>
    $isNested &&
    css`
      padding: 4px 16px;
      padding-left: 0;
      border: none;
      gap: 0;

      &:hover {
        background-color: ${theme.color.grey[8]};
      }
    `}

  ${({ theme, $isNested }) =>
    !$isNested &&
    css`
      border: 1px solid ${theme.color.grey[6]};
    `}
`;

export const LeftSide = styled.section<LeftSideProps>`
  width: 50px;
  gap: 12px;
  display: flex;
  flex-direction: ${({ $isCondition }) => ($isCondition ? 'row' : 'column')};
  flex-shrink: 0;
  align-items: center;
  cursor: grab;
`;

export const RightSide = styled.section`
  width: 100%;
  height: 100%;
`;

export const RightSideCondition = styled(RightSide)`
  display: flex;
  align-items: center;
`;

export const BLOCK_HEADER_HEIGHT = '25px';
export const BLOCK_HEADER_MARGIN_BOTTOM = '2rem';
export const BlockHeader = styled.header<BlockHeaderProps>`
  width: 100%;
  height: ${BLOCK_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ $isStatement }) =>
    $isStatement ? '8px' : BLOCK_HEADER_MARGIN_BOTTOM};
  user-select: none;
`;

export const BlockHeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
`;

export const BlockTitle = styled.input`
  font-size: 12px;
  border: none;
  outline: none;
  color: ${(p) => p.theme.color.grey.dark};
  font-family: Inter;
`;

export const CollapseButton = styled(ChevronUp)<{ $isCollapsed: boolean }>`
  cursor: pointer;
  transition: all 0.2s;
  ${(p) =>
    p.$isCollapsed &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ECSContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
