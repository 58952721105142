import { newError } from '@/services/errors/errors';

import { ACCOUNT_API_URL, ACCOUNT_UI_URL } from './constants';

export const logout = () => {
  fetch(ACCOUNT_API_URL + '/logout', {
    credentials: 'include'
  })
    .then(() => {
      location.href = ACCOUNT_UI_URL;
    })
    .catch((error: unknown) => {
      newError('LOGOUT-0ff64', error);
    });
};
