import { makeObservable, observable } from 'mobx';

import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';

import { AtomModel } from './atom.model';

type ElementType = 'atom' | 'action' | 'transition';
export type ReferenceDirection = 'references' | 'referencedBy' | 'both';

const DEFAULT_DIRECTION: ReferenceDirection = 'referencedBy';
const DEFAULT_ITEM_NAME = 'element';

export type ReferenceMap = Record<AtomModel['id'], AtomModel[]>;

export class ReferenceModalModel {
  static instance: ReferenceModalModel;

  isOpen: boolean;
  elementType: ElementType | null;
  atoms: AtomModel[];
  direction: ReferenceDirection | null;
  itemName: string;

  constructor(public rootStore: RootStore) {
    this.isOpen = false;
    this.elementType = null;
    this.atoms = [];
    this.direction = DEFAULT_DIRECTION;
    this.itemName = DEFAULT_ITEM_NAME;

    makeObservable(this, {
      isOpen: observable,
      elementType: observable,
      atoms: observable,
      direction: observable,
      itemName: observable
    });

    if (ReferenceModalModel.instance) {
      newError('REFRS-hvnGp', 'ReferenceModal model is a singleton.');
      return ReferenceModalModel.instance;
    }

    //! for dev purposes
    // setTimeout(
    //   () =>
    //     // this.open('atom', 'y0RC5fdLaXlhyUJwmM573', 'referencedBy', 'Check Box'),
    //     this.open('action', 'ipHgMpC', 'referencedBy', 'action'),
    //   1000
    // );
  }

  public open(
    elementType: ElementType,
    elementId: string,
    direction: ReferenceDirection,
    itemName: string = DEFAULT_ITEM_NAME
  ): void {
    this.isOpen = true;
    this.elementType = elementType;
    this.direction = direction ?? DEFAULT_DIRECTION;
    this.itemName = itemName;
    this.setAtoms(elementType, elementId);
  }

  public close(): void {
    this.resetModal();
  }

  private setAtoms(elementType: ElementType, elementId: string): void {
    if (elementType === 'atom') {
      const atom = this.atomStore.get(elementId);
      if (!atom) {
        newError('REFRS-GCv94', `Atom with id "${elementId}" not found`, true);
        return this.resetModal();
      }
      this.atoms = [atom];
    } else {
      this.atoms = this.atomStore.getAllAtomsBySourceId(elementId);
    }
  }

  get referencedByMap(): ReferenceMap {
    return this.atoms.reduce((map: ReferenceMap, atom) => {
      map[atom.id] = atom.referencedBy
        .map((referencedAtomId) => this.atomStore.get(referencedAtomId))
        .filter((ref): ref is AtomModel => !!ref?.id);
      return map;
    }, {});
  }

  get referencesMap(): ReferenceMap {
    return this.atoms.reduce((map: ReferenceMap, atom) => {
      map[atom.id] = atom.references
        .map((referenceId) => this.atomStore.get(referenceId))
        .filter((ref): ref is AtomModel => !!ref?.id);
      return map;
    }, {});
  }

  private resetModal(): void {
    this.isOpen = false;
    this.elementType = null;
    this.atoms = [];
    this.direction = DEFAULT_DIRECTION;
    this.itemName = DEFAULT_ITEM_NAME;
  }

  private get atomStore() {
    return this.rootStore.atomStore;
  }

  get errors() {
    return [];
  }
}
