import { TableColumn } from '@components/trace/column';
import { Separator } from '@components/ui/separator';

import { AtomModel } from '@models/atom.model';

import { TableImporterLabelsColumnData } from 'shared';

type Props = {
  atom: AtomModel<TableImporterLabelsColumnData>;
};

export const LabelsSettingsModal = ({ atom }: Props) => {
  return (
    <>
      <TableColumn.ImporterSettings
        columnName={atom.data.title}
        onFromChange={(v) => (atom.data.importerOptions.from = v)}
        from={atom.data.importerOptions.from}
      />
      <Separator />
    </>
  );
};
