import { Element } from 'bpmn-js/lib/model/Types';
import { makeObservable } from 'mobx';

import Model, { ModelError } from '@models/base/base.model';

import BaseStore from '@stores/base/base.store';

import { newError } from '@/services/errors/errors';
import { ModdleElement } from '@/types/bpmn.types';

import { BpmnModel, BusinessObject } from './bpmn.model';
import { DNDModel } from './dnd.model';

export class TransitionModel extends Model {
  constructor(
    store: BaseStore<TransitionModel>,
    id: string,
    public conditionDndId: string,
    public notificationDndId: string,
    public prioritiesDndId: string,
    loading?: boolean
  ) {
    super(store, id, loading);

    makeObservable(this, {});
  }

  public async delete(): Promise<boolean> {
    const dndStore = this.store.rootStore.dndStore;

    const conditionDnd = dndStore.get(this.conditionDndId);
    const notificationDnd = dndStore.get(this.notificationDndId);
    const prioritiesDnd = dndStore.get(this.prioritiesDndId);

    if (!conditionDnd || !notificationDnd || !prioritiesDnd) {
      newError(
        'TRANS-lPIKm',
        `Error while deleting the transition "${this.id}" one of the DND is missing: 
        conditionDnd: "${conditionDnd?.id}", 
        notificationDnd: "${notificationDnd?.id}", 
        prioritiesDnd: "${prioritiesDnd?.id}"`,
        true,
        {
          customMessage: 'Error while deleting the transition'
        }
      );
      return false;
    }

    /* ---------------------- Delete the transition itself ---------------------- */

    const isTransitionDeleted = await this.store.delete(this.id);
    if (!isTransitionDeleted) return false;

    /* ----------------- Delete transaction's DNDs in store only ---------------- */

    await dndStore.delete(this.conditionDndId, false);
    await dndStore.delete(this.notificationDndId, false);
    await dndStore.delete(this.prioritiesDndId, false);

    return true;
  }

  private get bpmnTransition(): Maybe<Element> {
    const currentWorkflow = this.store.rootStore.workflowStore.currentWorkflow;
    return currentWorkflow?.bpmn?.getElementById(
      this.id
    );
  }

  private get bpmnElement() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.bpmnTransition?.di?.bpmnElement as Maybe<BusinessObject>;
  }

  get sourceRef(): Maybe<ModdleElement> {
    return this.bpmnElement?.sourceRef;
  }

  get targetRef(): Maybe<ModdleElement> {
    return this.bpmnElement?.targetRef;
  }

  get sourceName() {
    if (!this.sourceRef) return 'No source';
    return BpmnModel.getModdleElementName(this.sourceRef);
  }

  get targetName() {
    if (!this.targetRef) return 'No target';
    return BpmnModel.getModdleElementName(this.targetRef);
  }

  get toJSON() {
    return {};
  }

  get conditionDnd(): Maybe<DNDModel> {
    return this.store.rootStore.dndStore.get(this.conditionDndId);
  }

  get notificationDnd(): Maybe<DNDModel> {
    return this.store.rootStore.dndStore.get(this.notificationDndId);
  }

  get prioritiesDnd(): Maybe<DNDModel> {
    return this.store.rootStore.dndStore.get(this.prioritiesDndId);
  }

  get errors(): ModelError[] {
    return [];
  }
}
