import { z } from "zod";
import { CodeConditionDataSchema } from "./codeCondition.atom";
import { ConditionDataSchema } from "./condition.atom";

export const ConditionSchemas = [
    z.object({
        type: z.literal("Condition"),
        data: ConditionDataSchema,
    }),
    z.object({
        type: z.literal("Code Condition"),
        data: CodeConditionDataSchema,
    }),
    z.object({
        type: z.literal("Or"),
        data: z.object({}),
    }),
    z.object({
        type: z.literal("And"),
        data: z.object({}),
    }),
    z.object({
        type: z.literal("Not"),
        data: z.object({}),
    }),
] as const;

export const CONDITION_TYPES = ConditionSchemas.map(schema => schema.shape.type.value);
export type ConditionBlocks = typeof ConditionSchemas[number]["shape"]["type"]["value"];
