import { ChangeEvent } from 'react';

import { DndKitValue } from 'shared';

import {
  Active,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  Over,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import { SortableItem } from './sortableItem';

interface DndKitContainerProps {
  dndValues: DndKitValue[];
  updateValues: (newValues: DndKitValue[]) => void;
}

const DndKitContainer = ({ dndValues, updateValues }: DndKitContainerProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const onDrop = (active: Active, over: Over) => {
    const oldIndex = dndValues.findIndex((value) => value.id === active.id);
    const newIndex = dndValues.findIndex((value) => value.id === over.id);

    if (oldIndex === -1 || newIndex === -1) return;

    updateValues(arrayMove(dndValues, oldIndex, newIndex));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      onDrop(active, over);
    }
  };

  const updateValueById = (
    e: ChangeEvent<HTMLInputElement>,
    valueId: UniqueIdentifier
  ) => {
    const newValues: DndKitValue[] = dndValues.map((value) => {
      if (value.id === valueId) return { id: value.id, name: e.target.value };
      return value;
    });

    updateValues(newValues);
  };

  const deleteValueById = (valueId: UniqueIdentifier) => {
    const newValues: DndKitValue[] = dndValues.filter(
      (value) => value.id !== valueId
    );

    updateValues(newValues);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext
        items={dndValues.map((value) => value.id)}
        strategy={verticalListSortingStrategy}
      >
        <div>
          {dndValues.map((value: DndKitValue) => (
            <SortableItem
              key={value.id}
              value={value}
              onValueChange={updateValueById}
              onValueDelete={deleteValueById}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default DndKitContainer;
