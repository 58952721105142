import { AtomModel } from '@models/atom.model';

import { Prefill } from '@library/formBuilder/prefill';
import { DateFieldData } from 'shared';

type Props = {
  atom: AtomModel<DateFieldData>;
};

export const DateModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
