import { z } from 'zod';

import { RowDataSchema } from './row.atom';

export const DatabaseSchemas = [
  z.object({
    type: z.literal('Row'),
    data: RowDataSchema
  })
] as const;

export const DATABASE_TYPES = DatabaseSchemas.map(
  (schema) => schema.shape.type.value
);
export type DatabaseType =
  (typeof DatabaseSchemas)[number]['shape']['type']['value'];
