import { AtomModel } from '@models/atom.model';
import { TableEditorSelectColumnData } from 'shared';


type Props = {
  atom: AtomModel<TableEditorSelectColumnData>;
};

export const SelectSettingsModal = ({ atom }: Props) => {
  atom;
  return <></>;
};
