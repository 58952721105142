import { TableImporterDateColumnData } from "shared";

const DEFAULT_HEADER = 'Default Date Column Header';
const DEFAULT_FORMAT = 'DD/MM/YYYY';

export const initialDateData: TableImporterDateColumnData = {
  title: DEFAULT_HEADER,
  cell: {
    view: {
      type: 'date',
      format: DEFAULT_FORMAT,
      isDeadline: false,
      deadlineWarningBuffer: 10,
      doneVariableReference: undefined,
      deadlineWarningIcon: undefined,
      inputFormat: undefined
    }
  },
  importerOptions: {
    from: DEFAULT_HEADER,
    parserOptions: {
      activated: true,
      parser: {
        type: 'date',
        inputFormat: DEFAULT_FORMAT,
        outputFormat: DEFAULT_FORMAT
      }
    }
  },
  width: 'medium',
  isfixedColumn: false,
  isDisplayedByDefault: true,
  required: true
};
