import { forwardRef } from 'react';

import { Label } from '@components/ui/label';
import { Separator } from '@components/ui/separator';

import { cn } from '@/lib/utils';
import * as LabelPrimitive from '@radix-ui/react-label';

export const ColumnElement = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((p, ref) => (
  <div ref={ref} className={cn('flex flex-col gap-1.5', p.className)} {...p}>
    {p.children}
  </div>
));

export const ColumnTitle = forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>((p, ref) => (
  <>
    <Label ref={ref} className={cn('font-medium text-sm', p.className)} {...p}>
      {p.children}
    </Label>
    <Separator />
  </>
));
