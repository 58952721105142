import { z } from "zod";

export namespace SharedSchema {
  export const PositiveIntegerSchema = z.number().int().gte(0);
}

export const LocaleList = [
  "en-US",
  "fr-FR",
  "it-IT",
] as const satisfies string[];

export type Locale = (typeof LocaleList)[number];
