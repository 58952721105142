import { DynamicType } from 'shared'

export const NeverFormDataType =
  `type ${DynamicType.FormData} = never;` as const;
export const EmptyFormDataType = `type ${DynamicType.FormData} = {};` as const;
export const EmptyLaneType = `type ${DynamicType.Lanes} = never;` as const;
export const EmptyActionType = `type ${DynamicType.Action} = never;`;
export const EmptyGlobalVariablesType = `type ${DynamicType.GlobalState} = never;`;

export const DEFAULT_CODE = `const effect = (dsl: DSLContext) => {
  const { state, formData, meta } = dsl.$variables;
  const { data, nextActions, notifications, tasks } = state;

  // <-- Write your code here -->
  
};`;

export const DEFAULT_TRANSPILED_CODE = `const effect = (dsl) => {
    const { state, formData, meta } = dsl.$variables;
    const { data, nextActions, notifications, tasks } = state;
};`;
