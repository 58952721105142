import { CodeConditionData } from "shared"

const DEFAULT_CODE = `function condition(dsl: DSLContext, fn: Functions, def: Definitions): boolean {
  const { state, formData, meta } = dsl.$variables;
  const { data, nextActions, notifications, tasks } = state;

  /** 
   * Write your condition code here.
   * Return true if the condition is met.
   */



  return true;
};`;

export const initialCodeConditionData: CodeConditionData = {
  code: DEFAULT_CODE,
  isActive: true
};
