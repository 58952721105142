import { NumberWidgetSchema } from 'shared';
import { NumberWidget as Comp } from './number.widget';
import { NumberWidgetConfig } from './number.widget.config';
import { NumberWidgetSettings } from './number.widget.settings';

export namespace NumberWidget {
  export const Base = Comp;
  export const Settings = NumberWidgetSettings;
  export import Config = NumberWidgetConfig;
  export type Data = NumberWidgetSchema.Data;
  export import Schema = NumberWidgetSchema;
}
