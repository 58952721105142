export enum SuggestionSource {
  Database = 'database',
  Variable = 'variable'
}

export type Suggestion = {
  label: string;
  groupeBy: string;
  source: SuggestionSource;
};
