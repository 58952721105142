import { TableImporterNumberColumnData } from "shared";

const DEFAULT_HEADER = 'Default Number Column Header';
export const initialNumberData: TableImporterNumberColumnData = {
  title: DEFAULT_HEADER,
  width: 'medium',
  isfixedColumn: false,
  isDisplayedByDefault: true,
  required: true,
  cell: {
    view: {
      type: 'number',
      tabRight: false,
      format: {
        locale: 'en-US',
        options: {}
      }
    }
  },
  importerOptions: {
    from: DEFAULT_HEADER,
    parserOptions: {
      activated: true,
      parser: {
        type: 'number',
        delimiters: {
          thousands: ',',
          decimal: '.'
        }
      }
    }
  }
};
