import { ReactNode } from 'react';

import { ChevronDown } from 'lucide-react';

import {
  Box,
  Chip,
  FormControl,
  FormLabel,
  Select,
  SelectProps,
  selectClasses
} from '@mui/joy';

interface MultiSelectFieldProps extends SelectProps<string, true> {
  label?: string;
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  required?: boolean;
}

export const MultiSelectField = (props: MultiSelectFieldProps) => (
  <FormControl size={props.size || 'sm'}>
    {props.label && (
      <FormLabel required={props.required}>{props.label}</FormLabel>
    )}
    <Select
      size="sm"
      multiple
      placeholder="Select values"
      indicator={<ChevronDown size={15} />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
          {selected.map((selectedOption) => (
            <Chip variant="soft" color="neutral" size="sm">
              {selectedOption.label}
            </Chip>
          ))}
        </Box>
      )}
      sx={{
        [`& .${selectClasses.indicator}`]: {
          transition: '0.2s',
          [`&.${selectClasses.expanded}`]: {
            transform: 'rotate(-180deg)'
          }
        },
        backgroundColor: 'white' //! temp
      }}
      slotProps={{
        listbox: {
          sx: {
            width: '100%'
          }
        }
      }}
      {...props}
    ></Select>
  </FormControl>
);
