import { User, UserSchema } from 'shared'

import { GraphQLService } from '@/services/graphql.service';
import { ACCOUNT_API_URL, ACCOUNT_UI_URL, CLIENT_ID } from '@/utils/constants';
import { parseWithZod } from '@/utils/parseZodSchema';
import { gql } from '@apollo/client';

let instance: AccountServiceClass;

class AccountServiceClass extends GraphQLService {
  constructor() {
    if (instance) {
      // singleton pattern, will not create the same instance twice'
      return instance;
    }

    super(ACCOUNT_API_URL + '/graphql');
  }

  async getMe(): Promise<Maybe<User>> {
    const data = await this.query<{ me: User }>({
      query: gql`
        query getUser {
          me {
            rowId
            avatar
            email
            firstName
            lastName
          }
        }
      `,
      variable: {}
    });

    if (!data || !data.me) {
      const pathname = window.location.pathname;
      const isDraftEditor = pathname.endsWith('draft-editor');
      if (isDraftEditor) {
        return;
      }
      redirectToAccountUILogin();
      return;
    }

    const parsedUser = parseWithZod(UserSchema, data?.me, 'ACCNT-QbEIQ');
    if (!parsedUser) {
      redirectToAccountUILogin();
      return;
    }

    const user = parsedUser;
    return user;
  }
}

export const redirectToAccountUILogin = (): void => {
  location.href = `${ACCOUNT_UI_URL}/login?clientId=${CLIENT_ID}&redirectUri=${window.location.href}`;
};

const AccountService = Object.freeze(new AccountServiceClass());

export default AccountService;
