import { TabLink } from '@panel/PanelHeader/panelHeader.types';

import { CustomCodePage } from '../shared/tabs';
import { TableEditorSettingsTab } from './tabs/settings.tab.tableEditor';
import { UiTableImporter } from './tabs/ui.tab.tableEditor';

export namespace TableEditorConfig {
  export enum SubPath {
    Ui = 'ui',
    Code = 'code',
    Settings = 'settings'
  }

  export const TAB_LINKS: TabLink[] = [
    {
      content: 'Table',
      path: SubPath.Ui
    },
    {
      content: 'Code',
      path: SubPath.Code
    },
    {
      content: 'Settings',
      path: SubPath.Settings
    }
  ] as const;

  export const SUBPATHS_TO_FC_MAP = {
    [SubPath.Ui]: <UiTableImporter />,
    [SubPath.Code]: <CustomCodePage />,
    [SubPath.Settings]: <TableEditorSettingsTab />
  } as const satisfies Record<SubPath, React.ReactNode>;
}
