import { DateWidgetSchema } from 'shared';
import { DateWidget as Comp } from './date.widget';
// import { DateWidgetConfig } from './date.widget.config';
import { DateWidgetSettings } from './date.widget.settings';

export namespace DateWidget {
  export const Base = Comp;
  export const Settings = DateWidgetSettings;
  // export import Config = DateWidgetConfig;
  export type Data = DateWidgetSchema.Data;
  export import Schema = DateWidgetSchema;
}
