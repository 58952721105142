import { useEffect, useState } from 'react';

import { Outlet, useOutletContext } from 'react-router-dom';

import Header from '@components/header/header.comp';
import { Modals } from '@components/modals/modal.comp';

import { User } from 'shared'

import AccountService from '@/services/account/account.service';

type ContextType = { user: User; loadingUser: boolean };

export const Layout = () => {
  const [user, setUser] = useState<Maybe<User>>();
  const [loadingUser, setLoadingUser] = useState(true);
  // get routeusing react router , if route is settings, then show subheader


  useEffect(() => {
    const loadUser = async (): Promise<void> => {
      const user: Maybe<User> = await AccountService.getMe();
      setUser(user);
      setLoadingUser(false);
    };

    void loadUser();
  }, []);

  if (!user) return <></>;

  return (
    <section className="w-screen h-screen flex-col">
      {user && <Header user={user} />}
      <section className="bg-[#fafafa] grow h-full relative">
        <Modals />
        <Outlet context={{ user, loadingUser }} />
      </section>
    </section >
  );
};

export function useUser() {
  return useOutletContext<ContextType>();
}

export default Layout;
