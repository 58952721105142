import { CommentFieldData } from "shared";

export const DEFAULT_COMMENT_TITLE = 'Comment';

export const initialCommentData: CommentFieldData = {
  title: DEFAULT_COMMENT_TITLE,
  required: false,
  placeholder: 'Leave a comment here',
  withMention: true
};
