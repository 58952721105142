import { FC } from 'react';


import { DNDInfo, Plexer } from '@library/library.types';

// import * as Blocks from '@library/traceInfo/blocks';


import { DNDBlock, TIBlocks, TRACE_INFO_TYPES } from 'shared'; 

export const isTIBlocks = (blockType: unknown): blockType is TIBlocks => {
  return TRACE_INFO_TYPES.includes(blockType as TIBlocks);
};

export const TI_INFO_DND: DNDInfo = {
  title: 'Trace Info',
  description:
    'Add a window to configure the panels present in the Trace inspector. You can nest windows together.'
};

const TraceInfoPlexer: Plexer<TIBlocks> = {
  // ['TI_File']: Blocks.FileUpload,
  // ['TI_Date']: Blocks.Date,
  // ['TI_DataTab']: Blocks.DataTab,
  // ['TI_List']: Blocks.List,
  // ['TI_Window']: Blocks.Window,
  // ['TI_Text']: Blocks.Text,
  // ['TI_Number']: Blocks.Number,
  // ['TI_Comment']: Blocks.Comment,
  // ['TI_KeyValue']: Blocks.KeyValue
};
interface LibraryTraceInfoProps {
  block: DNDBlock;
}

export const LibraryTI: FC<LibraryTraceInfoProps> = ({ block }) => {
  const Block = TraceInfoPlexer[block.type as TIBlocks];
  return <Block {...block} />;
};
