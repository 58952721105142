import { z } from "zod";
import { BaseEditorSchema, MultiEditorsSchema } from "./shared.schema";
import { TextWidgetSchema, ColumnSchema } from "../../../../trace";

const InputEditorSchema = BaseEditorSchema.extend({
    type: z.literal("input"),
});

const CommentEditorSchema = BaseEditorSchema.extend({
    type: z.literal("comment"),
});

export const TextColumnDataSchema = (valueValidation?: boolean) =>
    z
        .object({
            cell: z.object({
                view: TextWidgetSchema.MainSchema,
            }),
            required: z.boolean(),
        })
        .merge(ColumnSchema.MainSchema(valueValidation))
        .merge(
            MultiEditorsSchema(
                z.object({
                    input: InputEditorSchema,
                    comment: CommentEditorSchema,
                })
            )
        )
        .strict();

export type TableEditorTextColumnData = z.infer<
    ReturnType<typeof TextColumnDataSchema>
>;
