import PopupMenuModule from 'bpmn-js/lib/features/popup-menu';
import SpaceToolModule from 'bpmn-js/lib/features/space-tool';
import CreateModule from 'diagram-js/lib/features/create';
import GlobalConnectModule from 'diagram-js/lib/features/global-connect';
import HandToolModule from 'diagram-js/lib/features/hand-tool';
import LassoToolModule from 'diagram-js/lib/features/lasso-tool';
import PaletteModule from 'diagram-js/lib/features/palette';
import translate from 'diagram-js/lib/i18n/translate';

import CustomPaletteProvider from './CustomPaletteProvider';

export default {
  __depends__: [
    PaletteModule,
    CreateModule,
    SpaceToolModule,
    LassoToolModule,
    HandToolModule,
    GlobalConnectModule,
    translate,
    PopupMenuModule
  ],
  __init__: ['paletteProvider'],
  paletteProvider: ['type', CustomPaletteProvider]
};
