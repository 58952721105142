import { z } from 'zod';

import {
  TextInputSchema,
  TitleInputSchema
} from '../../../../other/otherUtils';
import { PrefillDataSchema } from './prefill.schema';

export const CommentDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean(),
      placeholder: valueValidation ? TextInputSchema : z.string(),
      withMention: z.boolean()
    })
    .merge(PrefillDataSchema)
    .strict();

export type CommentFieldData = z.infer<ReturnType<typeof CommentDataSchema>>;
