import { cn } from '@/lib/utils';

export const ChipLabel = ({
  className,
  children,
  ...divProps
}: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'rounded-sm px-[5px] py-[2px] bg-light text-gray-600 text-xs inline-flex items-center text-center select-none font-mono ml-[5px] whitespace-nowrap',
        className
      )}
      {...divProps}
    >
      {children}
    </div>
  );
};
