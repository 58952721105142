import { z } from "zod";

export const AtomReferenceSchema = z
    .object({
        dataItemId: z.string(),
        blockType: z.string(),
        sourceId: z.string(),
    })
    .strict();

export type AtomReference = z.infer<typeof AtomReferenceSchema>;
