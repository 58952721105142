import moment from 'moment';

import { Label } from '@components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@components/ui/select';
import { Separator } from '@components/ui/separator';
import { DateFormatType, DateFormatUtils } from 'shared';

type Props = React.PropsWithChildren<{
  id: string;
  format: DateFormatType;
  onFormatChange: (format: DateFormatType) => void;
  label?: string;
}>;

const exampleDate = moment('06-13-2024');
export const DateFormatPicker = ({
  format,
  onFormatChange,
  id,
  label
}: Props) => {
  return (
    <>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Select value={format} onValueChange={onFormatChange} autoComplete="off">
        <SelectTrigger id={id}>
          <SelectValue placeholder="DD/MM/YYYY" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Classic formats</SelectLabel>
            {DateFormatUtils.ClassicFormats.map((format) => (
              <SelectItem value={format} key={format}>
                {exampleDate.format(format)}
              </SelectItem>
            ))}
          </SelectGroup>

          <Separator className="my-2" />

          <SelectGroup>
            <SelectLabel>Verbose formats</SelectLabel>

            {DateFormatUtils.VerboseFormats.map((format) => (
              <SelectItem value={format} key={format}>
                {exampleDate.format(format)}
              </SelectItem>
            ))}
          </SelectGroup>

          <Separator className="my-2" />

          <SelectGroup>
            <SelectLabel>Other formats</SelectLabel>
            {DateFormatUtils.OtherFormats.map((format) => (
              <SelectItem value={format} key={format}>
                {exampleDate.format(format)}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
};
