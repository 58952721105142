import { FC } from 'react';

import {
  DNDInfo,
  LibraryProps,
  Plexer,
  ToolbarItem
} from '@library/library.types';
import * as Blocks from '@library/notification/blocks';
import { NotificationBlocks } from 'shared';

export const NOTIF_TOOLBAR_ITEMS: ToolbarItem<NotificationBlocks>[] = [
  {
    type: 'Send Email',
    icon: <Blocks.EmailIcon />
  }
];

export const NF_INFO_DND: DNDInfo = {
  title: 'Notification',
  description:
    'Drag & drop a block to add a sending method. Choose the person(s) who will receive the notification.'
};

const NotificationPlexer: Plexer<NotificationBlocks> = {
  ['Send Email']: Blocks.Email
};

export const LibraryNF: FC<LibraryProps> = ({ block }) => {
  const Block = NotificationPlexer[block.type as NotificationBlocks];
  return <Block {...block} />;
};
