import { useParams } from 'react-router-dom';

import { ParamsList } from '@/routes/routes.types';

import useProcess from './useProcess';

export const useWorkflow = () => {
  const workflowId = useParams()[ParamsList.WorkflowId] as string;
  const { process } = useProcess();

  if (!process || !workflowId) {
    return { workflow: undefined };
  }

  const workflow = process?.workflows.filter((w) => w?.id == workflowId)[0];
  return { workflow };
};
