import { makeObservable, observable } from 'mobx';

import RootStore from '@stores/root.store';

import { ProcessModel } from './process.model';

export class PasswordProtectedModel {
  isOpen: boolean;
  hasError: boolean;
  errorMessage = 'Incorrect password';

  passwordProcess: Map<ProcessModel['id'], string>;

  constructor(public rootStore: RootStore) {
    this.isOpen = false;
    this.hasError = false;
    this.passwordProcess = new Map<ProcessModel['id'], string>();

    makeObservable(this, {
      isOpen: observable,
      passwordProcess: observable
    });
  }

  setPassword(processId: ProcessModel['id'], password: string) {
    this.passwordProcess.set(processId, password);
  }

  getPassword(processId: ProcessModel['id']): string {
    return this.passwordProcess.get(processId) ?? '';
  }

  removePassword(id: ProcessModel['id']) {
    return this.passwordProcess.delete(id);
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  resetModal() {
    this.isOpen = false;
    this.hasError = false;
  }
}
