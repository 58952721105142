import { z } from "zod";
import { BooleanWidgetSchema, ColumnSchema } from "../../../../trace";
import { ImportOptionsSchema } from "./table-importer.atom";

export const TIBooleanColumnDataSchema = (valueValidation?: boolean) =>
    z
        .object({
            cell: z.object({
                view: BooleanWidgetSchema.MainSchema,
            }),
            required: z.boolean(),
        })
        .merge(ColumnSchema.MainSchema(valueValidation))
        .merge(ImportOptionsSchema)
        .strict();

export type TableImporterBooleanColumnData = z.infer<
    ReturnType<typeof TIBooleanColumnDataSchema>
>;
