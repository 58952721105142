import { z } from 'zod';

import { AtomReferenceSchema } from '../../../atomReference.schema';

export const TO_LIST_VIEW_TYPES = ['List Compact', 'List'] as const;
export const TO_ListViewTypeSchema = z.enum(TO_LIST_VIEW_TYPES);

export const TO_ListDataSchema = (_?: boolean) =>
  z
    .object({
      title: z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_ListViewTypeSchema
    })
    .strict();

export type TO_ListData = z.infer<ReturnType<typeof TO_ListDataSchema>>;
