import { ChangeEvent } from 'react';

import { Hash, LucideIcon } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';

import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';
import { InputField } from '@atoms/input';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB from '@library/formBuilder/endControls';
import { FormControl, FormLabel } from '@mui/joy';

import { initialTINumberData } from './number.data';
import { DNDBlock } from 'shared';

export const NumberIcon: LucideIcon = Hash;

const Number = (block: DNDBlock) => {
  const workflowId = useParams()[ParamsList.WorkflowId] as string;

  const atom = useAtom({
    type: 'TI_Number',
    sourceId: block.id,
    parentId: workflowId,
    parentKind: 'traceInfo',
    initialData: {
      ...initialTINumberData,
      selectedVariable: block.other!.selectedVariableRef!
    }
  });

  if (!atom) return null;

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    atom.data.title = event.target.value;
  };

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={NumberIcon}
      endControls={
        <EndControlsFB hasRequired={false} dndBlock={block} dataItem={atom} />
      }
    >
      <Column $width="200px">
        <InputField
          label="Widget title"
          value={atom.data.title}
          onChange={onTitleChange}
          placeholder="Aa"
        />
      </Column>
      <Column $width="200px">
        <FormControl size="sm">
          <FormLabel> State variable</FormLabel>
          <VariableSelector
            editable={false}
            selectedRef={atom.data.selectedVariable}
            disabled
          />
        </FormControl>
      </Column>
    </BlockBase>
  );
};

export default observer(Number);
