import { z } from "zod";
import { DNDSchema } from "../bpmn/dnd.schema";

export const CreateTransitionDTOSchema = z.object({
  processId: z.string(),
  transitionId: z.string(),
});

export type CreateTransitionDTO = z.infer<typeof CreateTransitionDTOSchema>;

export const TransitionSchema = z.object({
  id: z.string(),
  condition: DNDSchema,
  notification: DNDSchema,
  priorities: DNDSchema,
  conditionDnd: z.string().optional(),
  notificationDnd: z.string().optional(),
  prioritiesDnd: z.string().optional(),
});

export type Transition = z.infer<typeof TransitionSchema>;
