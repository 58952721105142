import { AtomModel } from '@models/atom.model';

import { Prefill } from '@library/formBuilder/prefill';
import { CommentFieldData } from 'shared';

type Props = {
  atom: AtomModel<CommentFieldData>;
};

export const CommentModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
