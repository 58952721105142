import { ReactNode, useCallback, useEffect, useState } from 'react';

import { WandSparkles } from 'lucide-react';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { MonacoEditor } from '@components/monaco/monacoEditor.code';

import { STATIC_TYPES } from '@models/code/starter.types';

import ActionButton from '@atoms/button';
import { GlassModal } from '@atoms/glassModal';
import { SelectField } from '@atoms/select';

import { newError } from '@/services/errors/errors';
import { OnChange } from '@monaco-editor/react';
import {
  FormControl,
  FormLabel,
  ListItemDecorator,
  Option,
  SelectOption
} from '@mui/joy';

import {
  getCustomTypeName,
  isCorrectPrimaryType,
  primaryTypeToInfoMap
} from './globalVariable.utils';
import { GlobalVariableData, PRIMARY_TYPES } from 'shared';

type Props = GlobalVariableData['variableType'] & {
  onTypeChange: (type: GlobalVariableData['variableType']['type']) => void;
  onCustomTypeChange: (
    customType: GlobalVariableData['variableType']['customType']
  ) => void;
};
export const TypeSelector = ({
  selectedKind,
  type,
  customType,
  onTypeChange,
  onCustomTypeChange
}: Props) => {
  const [customTypeHasError, setCustomTypeHasError] = useState<boolean>(true);
  const [isCustomTypeModelOpen, setIsCustomTypeModelOpen] = useState(false);
  const checkHasErrorCustomType = useCallback((): boolean => {
    if (selectedKind !== 'custom') return false;
    if (!customType) return true;
    const hasNamedType = !!getCustomTypeName(customType);
    const isEndCorrect = customType.endsWith(';');
    return !hasNamedType || !isEndCorrect;
  }, [customType, selectedKind]);

  useEffect(() => {
    setCustomTypeHasError(() => checkHasErrorCustomType());
  }, [checkHasErrorCustomType, customType]);

  const onPrimaryTypeChange = (value: unknown): void => {
    if (!isCorrectPrimaryType(value)) {
      newError('PRIM-1000', 'Invalid primary type', true);
      return;
    }
    if (selectedKind === 'custom') {
      newError(
        'PRIM-2000',
        'Cannot select a primary type for custom type',
        true
      );
      return;
    }
    onTypeChange(value);
  };

  const onCodeChange: OnChange = (code) => {
    if (!code) return;
    onCustomTypeChange(code);
  };

  if (selectedKind === 'custom') {
    return (
      <Column $width="185px">
        <FormControl size="sm" required error={customTypeHasError}>
          <FormLabel
            style={{
              color: customTypeHasError ? '#c41c1c' : undefined
            }}
          >
            Custom type
          </FormLabel>
          <ActionButton
            onClick={() => setIsCustomTypeModelOpen(true)}
            value="Edit custom type"
            color="neutral"
            variant="soft"
            startDecorator={<WandSparkles size={18} />}
          />
        </FormControl>
        <GlassModal
          open={isCustomTypeModelOpen}
          onClose={() => setIsCustomTypeModelOpen(false)}
          header={{
            title: `Custom type editor`,
            description: 'Customize the type of your variable'
          }}
          className="w-full h-full px-4"
        >
          <section className="w-full h-full -ml-4 overflow-hidden">
            <MonacoEditor
              code={customType || DEFAULT_CODE}
              preCode={STATIC_TYPES + PRE_TYPE_SECURITY}
              onCodeChange={onCodeChange}
              options={{
                hover: {
                  enabled: true
                },
                scrollbar: {
                  useShadows: false,
                  vertical: 'auto'
                },
                scrollBeyondLastLine: false,
                padding: {},
                lineDecorationsWidth: 3,
                lineNumbersMinChars: 3,
                contextmenu: false,
                overviewRulerBorder: false,
                overviewRulerLanes: 0
              }}
            />
          </section>
        </GlassModal>
      </Column>
    );
  }
  return (
    <Column $width="200px">
      <FormControl size="sm">
        <SelectField
          required
          label="Primary type"
          onChange={(_, type) => onPrimaryTypeChange(type)}
          value={type}
          renderValue={renderSelectedPrimaryTypeOption}
          sx={{
            '--ListItemDecorator-size': '25px',
            width: '100%'
          }}
        >
          {PRIMARY_TYPES.map((primaryType) => {
            const { label, icon: Icon } = primaryTypeToInfoMap[primaryType];
            return (
              <Option key={primaryType} value={primaryType} label={label}>
                <>
                  <ListItemDecorator>
                    <Icon size={14} />
                  </ListItemDecorator>
                  {label}
                </>
              </Option>
            );
          })}
        </SelectField>
      </FormControl>
    </Column>
  );
};

const renderSelectedPrimaryTypeOption = (
  option: SelectOption<string> | null
): ReactNode => {
  if (!option) return null;

  const { label, icon: Icon } =
    primaryTypeToInfoMap[option.value as (typeof PRIMARY_TYPES)[number]];
  return (
    <>
      <ListItemDecorator>
        <Icon size={14} />
      </ListItemDecorator>
      {label}
    </>
  );
};

const DEFAULT_TYPENAME = 'MyType';
const DEFAULT_CODE = `type ${DEFAULT_TYPENAME} = ;` as const;

const PRE_TYPE_SECURITY =
  `/** Please replace \`${DEFAULT_TYPENAME}\` with a custom type name. */
type ${DEFAULT_TYPENAME} = never;` as const;
