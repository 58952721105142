import { createContext } from 'react';

import { observer } from 'mobx-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled, { css } from 'styled-components';


import { DNDModel, DropZone, Item } from '@models/dnd.model';

import { DndLibrary } from '@library';

import DNDToolbar from './Toolbar';
import { TOOLBAR_HEIGHT } from './Toolbar/toolbar.style';
import { DNDRootBlock } from './root';

export interface DNDMetaContextI {
  onDropFunction: (item: DropZone, data: Item) => void;
  onDelete: (path: string) => void;
  library: DndLibrary;
  restriction?: (string, Item) => boolean;
}
export const DNDMetaContext = createContext<DNDMetaContextI>(
  {} as DNDMetaContextI
);


interface DndContainerProps {
  $hasToolbar?: boolean;
  $warning?: boolean;
}

const DndContainer = styled.div<DndContainerProps>`
  width: 100%;
  height: 100%;
  ${({ $hasToolbar, $warning }) =>
    $hasToolbar
      ? $warning
        ? css`
            padding: 20px;
            padding-top: ${TOOLBAR_HEIGHT + 90}px;
          `
        : css`
            padding: 20px;
            padding-top: ${TOOLBAR_HEIGHT}px;
          `
      : $warning
        ? css`
            padding-top: 90px;
          `
        : css`
            padding: 0;
          `};
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    margin-top: ${TOOLBAR_HEIGHT + 5}px;
    margin-bottom: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.grey[6]};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.grey[4]};
  }
`;

interface DNDBaseProps {
  dnd: DNDModel;
  hasToolbar?: boolean;
  warning?: JSX.Element | null;
  restriction?: (string, Item) => boolean;
}

const DNDBase = ({
  dnd,
  hasToolbar,
  restriction,
  warning: Warning = null
}: DNDBaseProps) => {
  if (!dnd) return <></>;

  const onDrop = (dropZone: DropZone, item: Item) => {
    dnd.onDropFunction(dropZone, item);
    // dndStore.set(dnd_id, dnd);
  };

  const onDelete = (path: string) => {
    dnd.deleteBlockByPath(path);
  };

  if (!dnd.state.props?.['root']) return <></>;

  return (
    <DndProvider backend={HTML5Backend}>
      <DNDMetaContext.Provider
        value={{
          onDropFunction: onDrop,
          library: dnd.library,
          restriction,
          onDelete
        }}
      >
        <section className="relative h-full w-full">
          {hasToolbar && <DNDToolbar library={dnd.library} />}
          {Warning}
          <DndContainer $hasToolbar={hasToolbar} $warning={!!Warning}>
            <DNDRootBlock root={dnd.state.props['root']} />
          </DndContainer>
        </section>
      </DNDMetaContext.Provider>
    </DndProvider>
  );
};

export default observer(DNDBase);
