import { z } from "zod";

import { BaseActionSchema, BaseCreateActionDTO } from "./shared.action.schema";
import { ActionType } from "./types";

const ESignatureMetadataSchema = z.object({
  name: z.string(),
});

export const ESignatureDTOSchema = z.object({
  type: z.literal("E_SIGNATURE") satisfies z.ZodSchema<ActionType>,
  metadata: ESignatureMetadataSchema,
});

export const ESignatureSchema = BaseActionSchema.merge(ESignatureDTOSchema);

export const CreateESignatureDTO =
  BaseCreateActionDTO.merge(ESignatureDTOSchema);

export type ESignatureActionDTO = z.infer<typeof ESignatureDTOSchema>;
export type ESignatureAction = z.infer<typeof ESignatureSchema>;
export type ESignatureMetadata = z.infer<typeof ESignatureMetadataSchema>;
