import { PropsWithChildren } from 'react';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { Input } from '@components/ui/input';
import { Label } from '@components/ui/label';

import { AtomModel } from '@models/atom.model';

import { ColumnElement, ColumnTitle } from '@atoms/columnElement';
import { InfoTooltip } from '@atoms/infoTooltip';
import { SwitchV2 } from '@atoms/switch';

import { Widgets } from '@widgets';

type Props = Omit<Widgets.Text.Schema.Data, 'type'> & {
  onDefaultChange: (value: Widgets.Text.Schema.Data['default']) => void;
  onIsRichTextChange: (value: Widgets.Text.Schema.Data['isRichText']) => void;

  atomId: AtomModel['id'];
};

export const TextWidget = (props: PropsWithChildren<Props>) => {
  const onDefaultValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value) {
      props.onDefaultChange(undefined);
      return;
    }
    props.onDefaultChange(value);
  };
  return (
    <>
      {props.children}

      <Column $width="200px">
        <ColumnTitle>Display options</ColumnTitle>

        <ColumnElement>
          <div className="flex items-center gap-2">
            <Label htmlFor={`default-${props.atomId}`}>Default value</Label>
            <InfoTooltip
              title="Default value"
              description="If no data is found, this value will be the default one. Empty by default."
            />
          </div>
          <Input
            id={`default-${props.atomId}`}
            value={props.default}
            onChange={onDefaultValueChange}
            placeholder="Aa"
          />
        </ColumnElement>
        <ColumnElement>
          <SwitchV2
            id={`is-rich-text-${props.atomId}`}
            labelText="Is rich text data"
            checked={props.isRichText}
            onCheckedChange={(isRichTest) =>
              props.onIsRichTextChange(isRichTest)
            }
            tooltip={
              <InfoTooltip
                title="Rich text data"
                description="If the data is a rich text (bold, italic etc.), this option needs to be enabled."
              />
            }
          />
        </ColumnElement>
      </Column>
    </>
  );
};
