import { useState } from 'react';

import { camelCase } from 'camel-case';
import { Plus } from 'lucide-react';
import { observer } from 'mobx-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { RowData } from 'shared/src/atom/sources/database/row.atom';
import { ResolvedVariableType } from 'shared/src/atom/variables';
import {
  CellDataType,
  Columns,
  Database as CreateDatabaseDTO
} from 'shared/src/database/database.schema';
import { TraceKeyMode } from 'shared/src/other/traceKey.schema';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { DatabaseModel } from '@models/database.model';

import ActionButton from '@atoms/button';
import { InputField } from '@atoms/input';
import { BasicModal } from '@atoms/modal';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ParamsList } from '@/routes/routes.types';
import nanoID from '@/utils/nanoID';
import { Button, DialogActions, IconButton } from '@mui/joy';

import { DatabasesContainer, GridBody } from './database.style';

const DEFAULT_PROGRAMS_COLUMNS: Columns = [
  {
    field: 'name',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  },
  {
    field: 'proportional',
    cellDataType: CellDataType.Boolean
  }
];

const DEFAULT_ENTITIES_COLUMNS: Columns = [
  {
    field: 'name',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  },
  {
    field: 'programs',
    cellDataType: CellDataType.Object,
    context: {
      reference: {
        databaseId: 'EHgjbaV'
      }
    }
  }
];

const Databases = () => {
  const navigate = useNavigate();
  const databaseId = useParams<string>()[ParamsList.DatabaseId];
  const { process } = useProcess();
  const { databaseStore, atomStore } = useStores();
  const [showNewRepoInput, setShowNewRepoInput] = useState(false);
  const [newDatabaseName, setNewDatabaseName] = useState('');
  const [firstDataModalOpen, setFirstDataModalOpen] = useState<boolean>(false);
  let currentTab: string = '';

  if (!process || process.status != 'RUNNING' || process.loading) {
    return <div>Loading...</div>;
  }

  const onTabChange = (newTabId: string) => {
    currentTab = newTabId;
    navigate(`${newTabId}`);
  };

  const isUserOwner = () => {
    return process.permission.can_delete;
  };

  const createNewRowAtom = (gridAtomId: string, data: RowData) => {
    const newRowAtom = atomStore.createAtom(
      data.rowAtomId,
      'Row',
      data,
      {
        source: {
          elementId: gridAtomId,
          parentId: gridAtomId,
          parentKind: 'database'
        }
      },
      process.id,
      {
        resolvedType: 'Row' as ResolvedVariableType.Row
      }
    );

    return newRowAtom;
  };

  const createNewDatabase = async (name: string) => {
    setShowNewRepoInput(false);
    setFirstDataModalOpen(false);

    if (!newDatabaseName) return;

    const newGridAtomId = nanoID();

    const newDatabaseDto: CreateDatabaseDTO = {
      id: newGridAtomId,
      name: newDatabaseName,
      columnDefinitions:
        name === 'Entities'
          ? DEFAULT_ENTITIES_COLUMNS
          : DEFAULT_PROGRAMS_COLUMNS,
      processId: process?.id ?? '',
      rowReferences: [],
      traceKey: {
        value: camelCase(newDatabaseName),
        mode: TraceKeyMode.Follow
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null
    };

    const newGridModel = await databaseStore.createNewDatabase(newDatabaseDto);

    if (!newGridModel) {
      return;
    }

    databases.push(newGridModel);

    setNewDatabaseName('');

    const newRowAtomId = nanoID();

    // name: 'AXA France',
    //   programs: [
    //     {
    //       dataItemId: 'vWNxq7t',
    //       blockType: DTRBlocks.Row,
    //       sourceId: 'CjpIlRM'
    //     }
    //   ]

    // name: 'AXA XL',
    //   proportional: true

    const rowData: RowData = {
      rowAtomId: newRowAtomId,
      name: 'AXA France',
      programs: [
        {
          dataItemId: 'SkrRRKQ',
          blockType: 'Row',
          sourceId: 'EHgjbaV'
        }
      ]
    };

    const newRowAtom = createNewRowAtom(newGridAtomId, rowData);

    if (!newRowAtom) return;

    newGridModel.addRowReference({
      dataItemId: newRowAtom.id,
      blockType: 'Row',
      sourceId: newGridAtomId
    });

    onTabChange(newGridModel.id);
  };

  const handleNewRepoKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      createNewDatabase('Entities');
    } else if (event.key == 'Escape') {
      setShowNewRepoInput(false);
    }
  };

  const databases: Pick<DatabaseModel, 'id' | 'getName'>[] = databaseStore
    .toArray()
    .map((database) => {
      return {
        id: database.id,
        getName: database.getName
      };
    });

  if (databases.length == 0 && databaseId) {
    navigate('');
  }

  if (databases.length > 0 && !databaseId) {
    onTabChange(databases[0].id);
  }

  if (databaseId) {
    currentTab = databaseId;
  }

  return (
    <>
      <DatabasesContainer>
        {databases.length == 0 ? (
          <ActionButton
            value="Create your first database"
            onClick={() => setFirstDataModalOpen(true)}
            sx={{
              width: '250px',
              alignSelf: 'center',
              justifySelf: 'center',
              marginTop: '20px'
            }}
          />
        ) : (
          <Tabs
            value={currentTab}
            onValueChange={onTabChange}
            className="h-full"
          >
            <TabsList className="flex flex-wrap items-center justify-start bg-transparent">
              {databases.map((databaseModel) => (
                <TabsTrigger value={databaseModel.id} key={databaseModel.id}>
                  {databaseModel.getName}
                </TabsTrigger>
              ))}
              {isUserOwner() &&
                (!showNewRepoInput ? (
                  <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    onClick={() => setShowNewRepoInput(true)}
                    sx={{
                      maxWidth: '100px',
                      marginLeft: '5px'
                    }}
                  >
                    <Plus />
                  </IconButton>
                ) : (
                  <InputField
                    placeholder="Entities"
                    onChange={(e) => setNewDatabaseName(e.target.value)}
                    value={newDatabaseName}
                    width="100px"
                    onBlur={() => setShowNewRepoInput(false)}
                    onKeyDown={handleNewRepoKeyDown}
                    sx={{
                      marginLeft: 1
                    }}
                  />
                ))}
            </TabsList>
            {databases.map((database) => (
              <TabsContent
                value={database.id}
                key={database.id}
                className="mt-2 h-full"
              >
                <Outlet />
              </TabsContent>
            ))}
          </Tabs>
        )}
      </DatabasesContainer>
      <BasicModal
        open={firstDataModalOpen}
        setOpen={setFirstDataModalOpen}
        title="Give it a name"
        width="400px"
      >
        <InputField
          placeholder="Entities"
          onChange={(e) => setNewDatabaseName(e.target.value)}
          value={newDatabaseName}
          width="200px"
        />
        <DialogActions>
          <Button
            variant="solid"
            color="primary"
            size="sm"
            disabled={newDatabaseName.length == 0}
            onClick={() => createNewDatabase('Programs')}
            sx={{
              maxWidth: '100px'
            }}
          >
            Create
          </Button>
        </DialogActions>
      </BasicModal>
    </>
  );
};

export default observer(Databases);
