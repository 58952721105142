import { z } from "zod";

const MAX_TEXT_LENGTH = 250;
const MIN_TITLE_LENGTH = 2;

export const TextInputSchema = z.string().max(MAX_TEXT_LENGTH, {
  message: `Value should be less than ${MAX_TEXT_LENGTH} characters long`,
});
export const TitleInputSchema = TextInputSchema.min(MIN_TITLE_LENGTH, {
  message: `Value must be at least ${MIN_TITLE_LENGTH} characters long`,
});

export interface DndKitValue {
  id: string | number;
  name: string;
}

export const DndKitValueSchema = z
  .object({
    id: z.string().or(z.number()),
    name: z.string(),
  })
  .strict();

export const DndKitValuesSchema = z.array(DndKitValueSchema);
