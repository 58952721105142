import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { ArrowBigDown, ArrowBigUp, CornerDownLeft } from 'lucide-react';
import { AtomReference, ResolvedVariableType } from 'shared';

import { SearchBar } from '@components/stateMenu/SearchBar';

import { useKeyPress } from '@hooks/useKeyPress';
import { useVariableData } from '@hooks/useVariables';

import { AtomModel } from '@models/atom.model';
import Model from '@models/base/base.model';

import ShortcutHelper from '@atoms/shortcut';

import { newError } from '@/services/errors/errors';

import SearchResult from './SearchResult';
import VariableMenu from './VariableMenu';
import SideBar from './sidebar.stateMenu';
import { StateMenuBody, StateMenuRoot } from './stateMenu.style';

export type StateMenuProps = {
  onSelected?: (ref: AtomReference | undefined) => void;
  specificSourceIds?: Model['id'][];
  specificResolvedTypes?: ResolvedVariableType[] | ResolvedVariableType;
};

const StateMenu = ({
  onSelected,
  onClose,
  specificSourceIds,
  specificResolvedTypes
}: StateMenuProps & { onClose?: () => void }) => {
  if (specificResolvedTypes && !Array.isArray(specificResolvedTypes)) {
    specificResolvedTypes = [specificResolvedTypes];
  }
  const inputRef = useRef<HTMLInputElement | null>(null);
  const allVariables = useVariableData(
    specificSourceIds,
    specificResolvedTypes
  );

  const allActionKeys: string[] = Array.from(allVariables.keys());
  const [searchVar, setSearchVar] = useState('');
  const [selectedParent, setSelectedParent] = useState<string | null>(null);

  const [cursor, setCursor] = useState<number>(0);
  const [hoveredVariable, setHoveredVariable] = useState<AtomModel<{
    title: string;
  }> | null>(null);
  const [hoverActionKey, setHoverActionKey] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const regex = new RegExp(/[$^*()+\-[\]\\,./{}|:<>?]/, 'gi');
  const searchReg = new RegExp(searchVar.replace(regex, '\\$&'), 'i'); // Escaping special characters to avoid an error

  useEffect(() => {
    if (selectedParent || isSearching) {
      setCursor(0);
    }
  }, [selectedParent, searchVar, isSearching]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedParent, cursor]);

  const filteredVariables = allActionKeys.flatMap((actionKey: string) => {
    const actionVariables = allVariables.get(actionKey);
    return (
      actionVariables?.filter((variable) =>
        searchReg.test(variable.data.title)
      ) ?? []
    );
  });

  useEffect(() => {
    if (allActionKeys.length && hoverActionKey && !isSearching) {
      setCursor(allActionKeys.indexOf(hoverActionKey));
    }

    if (!isSearching && hoveredVariable) {
      setCursor(filteredVariables.indexOf(hoveredVariable));
    }
  }, [
    allActionKeys,
    filteredVariables,
    hoverActionKey,
    hoveredVariable,
    isSearching
  ]);

  const onVariableAtomSelect = (index?: number) => {
    const list = isSearching ? filteredVariables : (variablesByAction ?? []);
    const selectedAtom = list[index ?? cursor];
    if (!selectedAtom) {
      newError('SM-qwe', 'onDataItemSelect(): selectedDataItem is undefined');
      return;
    }
    if (!onSelected) {
      newError('SM-asda', 'onDataItemSelect(): onSelected is undefined');
      return;
    }

    if (!onClose) {
      newError('SM-asd123', 'onDataItemSelect(): onClose is undefined');
      return;
    }
    const variableReference: AtomReference = {
      dataItemId: selectedAtom.id,
      blockType: selectedAtom.type,
      sourceId: selectedAtom.metaInfo.source.elementId
    };

    onSelected(variableReference);
    onClose();
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    if (!selectedParent) {
      setIsSearching(!!searchTerm);
    }
    setSearchVar(searchTerm);
  };

  const handleActionClick = (key: string | null) => {
    setSelectedParent(key);
  };

  const resetStateMenu = () => {
    setSelectedParent(null);
    setSearchVar('');
    setCursor(0);
  };

  const onArrowPress = (event: KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      if (cursor === null && !isSearching) {
        setCursor(0);
        return;
      }
      if (selectedParent && variablesByAction) {
        setCursor((prevState) =>
          prevState < variablesByAction.length - 1 ? prevState + 1 : prevState
        );
      } else {
        const list = isSearching ? filteredVariables : filteredActions;
        const nextIndex = (cursor + 1) % list.length;
        setCursor(nextIndex);
      }
    } else if (event.key === 'ArrowUp') {
      if (cursor === null && !isSearching) {
        const lastIndex = allActionKeys.length - 1;
        setCursor(lastIndex);
        return;
      }
      if (selectedParent) {
        setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
      } else {
        const list = isSearching ? filteredVariables : filteredActions;
        const previousIndex = cursor === 0 ? list.length - 1 : cursor - 1;
        setCursor(previousIndex);
      }
    }
  };

  const onEnterPress = () => {
    if (selectedParent || isSearching) {
      onVariableAtomSelect();
    } else {
      setSelectedParent(filteredActions[cursor]);
    }
  };

  const onEscapePress = () => {
    if (isSearching) {
      setSearchVar('');
      setIsSearching(false);
      setCursor(0);
      return;
    }

    if (selectedParent) {
      setSelectedParent(null);
      setSearchVar('');
      setCursor(0);
      return;
    }

    onClose && onClose();
  };

  const onRightPress = (event: KeyboardEvent): void => {
    event.preventDefault();
    if (event.shiftKey || selectedParent || isSearching) return;
    setSelectedParent(filteredActions[cursor]);
  };

  const onLeftPress = (event: KeyboardEvent) => {
    event.preventDefault();
    if (selectedParent) {
      const currentIndex = filteredActions.indexOf(selectedParent);
      setCursor(currentIndex);

      if (event.key === 'Tab') {
        if (event.shiftKey) setSelectedParent(null);
        return;
      }
      setSelectedParent(null);
      setSearchVar('');
    }
  };

  const onBackspacePress = () => {
    if (selectedParent && !searchVar) {
      setSelectedParent(null);
    }
  };

  useKeyPress(['ArrowUp', 'ArrowDown'], onArrowPress);
  useKeyPress(['Enter'], onEnterPress);
  useKeyPress(['Escape'], onEscapePress);
  useKeyPress(['Backspace'], onBackspacePress);
  useKeyPress(['ArrowRight', 'Tab'], onRightPress);
  useKeyPress(['ArrowLeft', 'Tab'], onLeftPress);

  const filteredActions = allActionKeys.filter((actionKey: string) => {
    const actionVariables = allVariables.get(actionKey);

    if (actionVariables == undefined) {
      return false;
    }

    const hasSomeMatch = actionVariables.filter((variable) =>
      variable.data.title.match(searchReg)
    );
    return hasSomeMatch.length > 0;
  });

  const variablesByAction =
    !isSearching && cursor !== null
      ? allVariables
          .get(selectedParent || allActionKeys[cursor])
          ?.filter((variable) => searchReg.test(variable.data.title))
      : [];

  return (
    <StateMenuRoot data-tag="statemenu">
      <SearchBar
        ref={inputRef}
        searchVar={searchVar}
        onSearch={onSearch}
        selectedAction={selectedParent}
        resetSearch={resetStateMenu}
      />
      <StateMenuBody>
        {isSearching ? (
          <SearchResult
            filteredVariables={filteredVariables || []}
            cursor={cursor}
            searchVar={searchVar}
            actionsState={allVariables}
            setHovered={setHoveredVariable}
            handleVarClick={onVariableAtomSelect}
          />
        ) : (
          <>
            <SideBar
              sideBarItemNames={allActionKeys}
              selectedSideBarItem={selectedParent}
              cursor={cursor}
              handleItemClick={handleActionClick}
              setHovered={setHoverActionKey}
            />
            <VariableMenu
              variablesByAction={variablesByAction || []}
              cursor={cursor}
              setCursor={setCursor}
              handleVarClick={onVariableAtomSelect}
              searchVar={searchVar}
              selectedAction={selectedParent}
            />
          </>
        )}
      </StateMenuBody>
      <section className="flex justify-end items-center font-mono text-[10px] p-[10px] gap-6">
        <StateMenuNavigation>
          <ShortcutHelper value={[<ArrowBigUp />, <ArrowBigDown />]} />
          Navigation
        </StateMenuNavigation>
        <StateMenuNavigation>
          <ShortcutHelper value={<CornerDownLeft />} />
          Select
        </StateMenuNavigation>
        <StateMenuNavigation>
          <ShortcutHelper value="esc" />
          Cancel
        </StateMenuNavigation>
      </section>
    </StateMenuRoot>
  );
};

const StateMenuNavigation = (props: React.PropsWithChildren) => {
  return (
    <div className="flex items-center gap-2 text-gray-700 text-xs">
      {props.children}
    </div>
  );
};

export default StateMenu;
