import { z } from 'zod';

export type DataType =
  | 'variable'
  | 'traceInfo'
  | 'traceOverview'
  | 'condition'
  | 'action'
  | 'notification'
  | 'globalVariable'
  | 'null';

export enum ResolvedVariableType {
  Text = 'Text',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
  User = 'User',
  File = 'File',
  List = 'List',
  Comment = 'Comment',
  Custom = 'Custom',
  Row = 'Row'
}

export const VariableInfoSchema = z.object({
  resolvedType: z.nativeEnum(ResolvedVariableType)
});

export type VariableInfo = z.infer<typeof VariableInfoSchema>;
