import { z } from 'zod';

import { AtomReferenceSchema } from '../../../other/state-menu.schema';

export const RowDataSchema = z
  .object({ rowAtomId: z.string() })
  .catchall(
    z.union([
      z.string(),
      z.number(),
      z.boolean(),
      z.date(),
      z.array(AtomReferenceSchema),
      z.null()
    ])
  );
export type RowData = z.infer<typeof RowDataSchema>;
