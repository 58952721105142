import { z } from "zod";
import { TIBooleanColumnDataSchema } from "./boolean.atom";
import { TIDateColumnDataSchema } from "./date.atom";
import { TILabelsColumnDataSchema } from "./label.atom";
import { TINumberColumnDataSchema } from "./number.atom";
import { TITextColumnDataSchema } from "./text.atom";

export const TableImporterSchemas = [
    z.object({
        type: z.literal("Table_Importer_Text"),
        data: TITextColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Importer_Date"),
        data: TIDateColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Importer_Number"),
        data: TINumberColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Importer_Labels"),
        data: TILabelsColumnDataSchema(),
    }),
    z.object({
        type: z.literal("Table_Importer_Boolean"),
        data: TIBooleanColumnDataSchema(),
    }),
] as const;

export const TABLE_IMPORTER_TYPES = TableImporterSchemas.map(schema => schema.shape.type.value);

export type TableImporterBlocks = typeof TableImporterSchemas[number]["shape"]["type"]["value"];
