import { ChangeEvent } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';

import { ResolvedVariableType, DNDBlock } from 'shared'

import { InputField } from '@atoms/input';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';

import { initialTextFieldData } from './textField.data';
import { TextFieldModal } from './textField.modal';
import {
  TextFieldIcon,
} from './textField.utils';

const TextField = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    type: 'TextField',
    sourceId: block.id,
    parentId: actionId,
    parentKind: 'form_action',
    initialData: initialTextFieldData,
    variableInfo: {
      resolvedType: ResolvedVariableType.Text
    }
  });

  if (!atom) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: atom.data.title || `TextField title`,
      content: <TextFieldModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={TextFieldIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={atom.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            atom.data.title = e.target.value;
          }}
        />
      </Column>

      <Column>
        <InputField
          label="Placeholder"
          placeholder="Aa"
          value={atom.data.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            atom.data.placeholder = e.target.value;
          }}
        />
      </Column>

      {/* // ! Removed validation since not implemented */}
      {/* <Column $width="170px">
        <SelectField
          label="Validation"
          value={atom.data.validation}
          onChange={(_, value) => {
            if (!value) return;
            atom.data.validation = value as ValidationOption;
          }}
        >
          {validationOptions.map(({ value, label, Icon }, index) => (
            <Option key={index} value={value} label={label}>
              {Icon}
              {label}
            </Option>
          ))}
        </SelectField>
      </Column> */}
    </BlockBase>
  );
};

export default observer(TextField);
