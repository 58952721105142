import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { TableColumn } from '@components/trace/column';

import { useAtom } from '@hooks/useAtom';

import { ResolvedVariableType, DNDBlock } from 'shared'

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';
import { DateEditor } from '@library/tableEditor/editors/date.editor';
import { Widgets } from '@widgets';

import { initialDateData } from './date.data';
import { DateSettingsModal as DateSettingsModal } from './date.settings';
import { DateIcon } from './date.utils';

const DateTableEditorBlock = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    type: 'Table_Editor_Date',
    sourceId: block.id,
    parentId: actionId,
    parentKind: 'table_editor',
    initialData: initialDateData,
    variableInfo: {
      resolvedType: ResolvedVariableType.Date
    }
  });

  if (!atom) return null;

  const getModalSettings = (): SettingsConfig => {
    return {
      title: atom.data.title || `Date Column Title`,
      content: <DateSettingsModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getModalSettings()}
      hasRequired
    ></EndControlsFB>
  );

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={DateIcon}
      endControls={getEndControls()}
    >
      <TableColumn.Base
        atomId={atom.id}
        title={atom.data.title}
        width={atom.data.width}
        isDisplayedByDefault={atom.data.isDisplayedByDefault}
        onTitleChange={(v) => (atom.data.title = v)}
        onWidthChange={(v) => (atom.data.width = v)}
        isfixedColumn={atom.data.isfixedColumn}
        onFixedColumnChange={(v) => (atom.data.isfixedColumn = v)}
        onDisplayByDefaultChange={(v) => (atom.data.isDisplayedByDefault = v)}
      />
      <TableColumn.EditorMode
        atomId={atom.id}
        isEditMode={atom.data.isEditMode}
        onIsEditModeChange={(v) => (atom.data.isEditMode = v)}
        canSelectEditorType={false}
      />
      {atom.data.isEditMode ? (
        <DateEditor atom={atom} />
      ) : (
        <Widgets.Date.Base
          atomId={atom.id}
          format={atom.data.cell.view.format}
          onFormatChange={(format) => (atom.data.cell.view.format = format)}
        />
      )}
    </BlockBase>
  );
};

export default observer(DateTableEditorBlock);
