import { NamedExoticComponent, ReactElement, ReactNode, useState } from 'react';

import { ChevronRight, LucideIcon } from 'lucide-react';
import { observer } from 'mobx-react';
import { DragSourceMonitor, useDrag } from 'react-dnd';


import { AtomModel } from '@models/atom.model';

import { ChipLabel } from '@atoms/chipLabel';

import Dots from '@/assets/svg/dragDots.svg?react';
import { getCleanBlockType } from '@/utils/cleanBlockType';

import {
  BlockContainer,
  BlockHeader,
  BlockHeaderContent,
  BlockTitle,
  CollapseButton,
  ECSContainer,
  LeftSide,
  RightSide
} from './blockBase.style';
import BlockCondition from './blockCondition';
import { BlockBody, EndControlsContainer } from './body.block.style';
import { DNDBlock } from 'shared';

interface BlockBaseProps {
  dndBlock: DNDBlock;
  icon: LucideIcon | NamedExoticComponent;
  dataItem?: AtomModel<{ title: string }>;
  title?: string;
  endControls?: ReactElement;
  hasTitle?: boolean;
  isCondition?: boolean;
  isStatement?: boolean;
  header?: ReactNode;
  children?: ReactNode;
}

/** A base for all the DND blocks */
const BlockBase = ({
  dndBlock,
  icon: BlockIcon,
  endControls: EndControls,
  dataItem,
  title = '',
  hasTitle = true,
  isCondition = false,
  isStatement = false,
  header,
  children
}: BlockBaseProps) => {
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: dndBlock.type,
      item: { path: dndBlock.path, type: dndBlock.type },
      collect: (
        monitor: DragSourceMonitor<Partial<DNDBlock>, Partial<DNDBlock>>
      ) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [dndBlock]
  );

  const [isHovering, setIsHovering] = useState(false);

  const isNested = dndBlock.path.split('/').length > 2;

  const toggleCollapse = (): void => {
    if (!dndBlock.blockState) {
      dndBlock.blockState = { isCollapsed: true };
      return;
    }

    if (dndBlock.blockState.isCollapsed == undefined) {
      dndBlock.blockState.isCollapsed = true; // first time toggling the block
      return;
    }

    dndBlock.blockState.isCollapsed = !dndBlock.blockState.isCollapsed;
  };

  const isCollapsed: boolean = !!dndBlock.blockState?.isCollapsed;

  const renderHeader = () => {
    if (header) {
      return header;
    }

    return (
      <>
        <ChipLabel className="text-[10px]" data-tag={`${dndBlock.type}-label`}>
          {getCleanBlockType(dndBlock.type)}
        </ChipLabel>
        {hasTitle && dataItem?.data?.title !== undefined && (
          <>
            <ChevronRight size={'0.8em'} />
            <BlockTitle
              value={title}
              onChange={(e) => (dataItem.data.title = e.target.value)}
              placeholder={`${getCleanBlockType(dndBlock.type)} title`}
            />
          </>
        )}
      </>
    );
  };

  if (isCondition) {
    return (
      <BlockCondition
        isCollapsed={isCollapsed}
        isCondition={isCondition}
        EndControls={EndControls}
        children={children}
        drag={drag}
        preview={preview}
        isNested={isNested}
        isDragging={isDragging}
        setHover={setIsHovering}
        isHovering={isHovering}
      ></BlockCondition>
    );
  }

  return (
    <BlockContainer
      $isDragging={isDragging}
      $isCollapsed={isCollapsed}
      ref={preview}
      $isNested={isNested}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      data-tag={`${dndBlock.type}-block`}
    >
      <LeftSide
        ref={drag}
        data-tag="drag-dots"
        className="transition-all group/drag"
      >
        <div className="w-full flex items-center justify-center h-[25px] shrink-0 ">
          <Dots className="h-5 text-gray-400 group-hover/drag:text-gray-600" />
        </div>
        {!isCollapsed && (
          <div className="h-full w-[2px] bg-gray-200 group-hover/drag:bg-gray-300" />
        )}
      </LeftSide>
      <RightSide>
        <BlockHeader $isStatement={isStatement}>
          <BlockHeaderContent>
            <BlockIcon size={20} color="#4A4A4A" />
            {renderHeader()}
          </BlockHeaderContent>
          {!isStatement ? (
            <CollapseButton
              $isCollapsed={isCollapsed}
              onClick={() => toggleCollapse()}
            />
          ) : (
            <ECSContainer>
              <EndControlsContainer data-tag="end-control">
                {EndControls && (
                  <EndControls.type
                    {...EndControls.props}
                    isHovering={isHovering}
                  />
                )}
              </EndControlsContainer>
              <CollapseButton
                $isCollapsed={isCollapsed}
                onClick={() => toggleCollapse()}
              />
            </ECSContainer>
          )}
        </BlockHeader>
        {!isCollapsed && (
          <section className="flex flex-col justify-between w-full gap-4">
            <BlockBody $isStatement={isStatement}>{children}</BlockBody>
            {!isStatement && (
              <EndControlsContainer data-tag="end-control">
                {EndControls && (
                  <EndControls.type
                    {...EndControls.props}
                    isHovering={isHovering}
                  />
                )}
              </EndControlsContainer>
            )}
          </section>
        )}
      </RightSide>
    </BlockContainer>
  );
};

export default observer(BlockBase);
