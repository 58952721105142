import { useParams } from 'react-router-dom';

import { ActionModel } from '@models/action/action.model';

import { ParamsList } from '@/routes/routes.types';

import useStores from './useStore';

/**
 * A hook that returns the correct instance of `BaseActionModel`
 * using the `actionId` provided or found in the current url params.
 * @example const action = useAction();
 */
const useAction = (actionId?: string): Maybe<ActionModel> => {
  const currentActionId = useParams()[ParamsList.ActionId];
  if (!actionId) actionId = currentActionId;
  const { actionStore } = useStores();
  return actionStore.get(actionId);
};

export default useAction;
