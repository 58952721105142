import { useEffect, useRef } from 'react';

import { Editor, Range } from 'slate';
import { useFocused, useSlate } from 'slate-react';

import { Tooltip } from '@mui/joy';

import { RichTextButton } from '../style';
import { TOOLS, isMarkActive, toggleMark } from '../utils';
import Menu from './components/Menu';
import Portal from './components/Portal';

const HoveringToolbar = () => {
  const ref = useRef<HTMLElement | null>(null);
  const editor = useSlate();
  const inFocus = useFocused();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();
    if (domSelection) {
      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      el.style.opacity = '1';
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
      el.style.left = `${
        rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
      }px`;
    }
  });

  return (
    <Portal>
      <Menu
        ref={ref}
        onMouseDown={(event: MouseEvent) => {
          // prevent toolbar from taking focus away from editor
          event.preventDefault();
        }}
      >
        {TOOLS.map((tool) => (
          <Tooltip key={tool.label} title={tool.label}>
            <RichTextButton
              $reversed
              $active={isMarkActive(editor, tool.format)}
              onClick={() => toggleMark(editor, tool.format)}
            >
              {tool.icon}
            </RichTextButton>
          </Tooltip>
        ))}
      </Menu>
    </Portal>
  );
};

export default HoveringToolbar;
