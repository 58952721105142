import React, { forwardRef } from 'react';

import { Search, X } from 'lucide-react';

import { ChipLabel } from '@atoms/chipLabel';

type Props = {
  searchVar: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedAction: string | null;
  resetSearch: React.MouseEventHandler<SVGSVGElement>;
};

export const SearchBar = forwardRef<HTMLInputElement, Props>(
  ({ searchVar, onSearch: handleSearch, selectedAction, resetSearch }, ref) => (
    <div className="flex items-center gap-1 p-3 h-11">
      <Search size={18} />
      {selectedAction && (
        <ChipLabel className="gap-3 rounded-sm">
          {selectedAction}
          <X size={14} onClick={resetSearch} className="cursor-pointer" />
        </ChipLabel>
      )}
      <input
        className="outline-none border-none bg-transparent w-full"
        ref={ref}
        type="text"
        placeholder={`Search ${
          selectedAction
            ? `${selectedAction}'s variables`
            : 'all your variables...'
        }`}
        autoFocus
        value={searchVar}
        onChange={handleSearch}
      />
    </div>
  )
);
