import { observer } from 'mobx-react';

import { AtomModel } from '@models/atom.model';

import { ColumnElement } from '@atoms/columnElement';
import { DateFormatPicker } from '@atoms/dateFormat/dateFormat';

import { EditorOptions } from '@library/tableEditor/shared/editor.options';
import { TableEditorDateColumnData } from 'shared';

type Props = {
  atom: AtomModel<TableEditorDateColumnData>;
};

export const DateEditor = observer(({ atom }: Props) => {
  return (
    <EditorOptions
      atomId={atom.id}
      onPlaceholderChange={(value) =>
        (atom.data.editorOptions.editors.date.placeholder = value)
      }
      placeholder={atom.data.editorOptions.editors.date.placeholder}
    >
      <ColumnElement>
        <DateFormatPicker
          id={`${atom.id}-input-format`}
          label="Stored date format"
          format={atom.data.editorOptions.editors.date.inputFormat}
          onFormatChange={(v) =>
            (atom.data.editorOptions.editors.date.inputFormat = v)
          }
        />
      </ColumnElement>
      <ColumnElement>
        <DateFormatPicker
          id={`${atom.id}-display-format`}
          label="Display format"
          format={atom.data.editorOptions.editors.date.displayFormat}
          onFormatChange={(v) =>
            (atom.data.editorOptions.editors.date.displayFormat = v)
          }
        />
      </ColumnElement>
    </EditorOptions>
  );
});
