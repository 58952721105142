import { SaveXMLOptions } from 'bpmn-js/lib/BaseViewer';
import Modeler from 'bpmn-js/lib/Modeler';

import { ProcessModel } from '@models/process.model';
import { WorkflowModel } from '@models/workflow.model';

import { newError } from '@/services/errors/errors';
import { HTTPWrapper } from '@/utils/http';

import { transformXML } from './utils/transform';

const SAVE_XML_OPTIONS: Partial<SaveXMLOptions> = {
  format: true,
  preamble: false
};

interface UpdateTemplateDTO {
  workflow_xml: string;
}

export const onElementsChanged = async (
  modeler: Modeler,
  processModel: ProcessModel,
  workflowModel: WorkflowModel
) => {
  const processId = processModel.id;
  if (!processId) {
    newError('PROCS-JdHze', 'No current process found', true);
    return;
  }

  const saveXMLResult = await modeler.saveXML(SAVE_XML_OPTIONS);
  const { xml, error } = saveXMLResult;
  if (!xml || error) {
    newError('PROCS-J1VRy', error, true, {
      customMessage: 'Error while generating the schema'
    });
    return;
  }

  const transformedXml = transformXML(xml);

  workflowModel.workflowXML = transformedXml;

  const httpWrapper = new HTTPWrapper<boolean>('workflow');

  const dto: UpdateTemplateDTO = {
    workflow_xml: transformedXml
  };
  const response = await httpWrapper.post(`/${workflowModel.id}/xml`, dto);
  if (!response) {
    newError('PROCS-XpTc2', response, true, {
      customMessage: 'Error while saving the schema'
    });
    return;
  }
};
