import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { TableColumn } from '@components/trace/column';
import { Label } from '@components/ui/label';

import { useAtom } from '@hooks/useAtom';

import { ResolvedVariableType, DNDBlock } from 'shared'

import { InputInnerLabel } from '@atoms/InputInnerLabel';
import { ColumnElement, ColumnTitle } from '@atoms/columnElement';
import { ExempleTooltip, InfoTooltip } from '@atoms/infoTooltip';
import { SwitchV2 } from '@atoms/switch';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';
import { Widgets } from '@widgets';

import { initialNumberData } from './number.data';
import { NumberSettingsModal } from './number.settings';
import { NumberIcon } from './number.utils';

const NumberTableImporterBlock = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    type: 'Table_Importer_Number',
    sourceId: block.id,
    parentId: actionId,
    parentKind: 'table_importer',
    initialData: initialNumberData,
    variableInfo: {
      resolvedType: ResolvedVariableType.Number
    }
  });

  if (!atom) return null;

  const getModalSettings = (): SettingsConfig => {
    return {
      title: atom.data.title || `Number Column title`,
      content: <NumberSettingsModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getModalSettings()}
      hasRequired
    ></EndControlsFB>
  );

  const onTitleChange = (title: TableColumn.Data['title']) => {
    if (atom.data.title === atom.data.importerOptions.from) {
      atom.data.importerOptions.from = title; // From follows the column header
    }
    atom.data.title = title;
  };

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={NumberIcon}
      endControls={getEndControls()}
    >
      <TableColumn.Base
        atomId={atom.id}
        title={atom.data.title}
        width={atom.data.width}
        isDisplayedByDefault={atom.data.isDisplayedByDefault}
        onTitleChange={onTitleChange}
        onWidthChange={(v) => (atom.data.width = v)}
        isfixedColumn={atom.data.isfixedColumn}
        onFixedColumnChange={(v) => (atom.data.isfixedColumn = v)}
        onDisplayByDefaultChange={(v) => (atom.data.isDisplayedByDefault = v)}
      />
      <Column>
        <ColumnTitle>CSV parser options</ColumnTitle>
        <ColumnElement>
          <SwitchV2
            id={`parser-activated-${atom.id}`}
            checked={atom.data.importerOptions.parserOptions.activated}
            onCheckedChange={(v) =>
              (atom.data.importerOptions.parserOptions.activated = v)
            }
            labelText="Parse CSV"
          />
        </ColumnElement>
        {atom.data.importerOptions.parserOptions.activated === true && (
          <>
            <ColumnElement>
              <div className="flex items-center gap-2">
                <Label htmlFor={`delimiter-decimals-${atom.id}`}>
                  Delimiters
                </Label>
                <InfoTooltip
                  title="Delimiters"
                  description="Character delimiting the thousands and decimal places."
                  additionalContent={
                    <>
                      <ExempleTooltip
                        exampleName="Decimals (,)"
                        input="12,3"
                        output="12.3"
                      />
                      <ExempleTooltip
                        exampleName="Thousands ( )"
                        input="12 345"
                        output="12345"
                      />
                    </>
                  }
                />
              </div>
              <InputInnerLabel
                innerLabel="Decimals"
                type="text"
                id={`delimiter-decimals-${atom.id}`}
                placeholder="Ex: ."
                value={
                  atom.data.importerOptions.parserOptions.parser.delimiters
                    .decimal
                }
                onChange={(e) =>
                  (atom.data.importerOptions.parserOptions.parser.delimiters.decimal =
                    e.target.value)
                }
                className="underline"
                innerLabelClassName="min-w-[5.1rem]"
              />
              <InputInnerLabel
                innerLabel="Thousands"
                type="text"
                id={`delimiter-thousands-${atom.id}`}
                placeholder="Ex: ,"
                value={
                  atom.data.importerOptions.parserOptions.parser.delimiters
                    .thousands
                }
                onChange={(e) =>
                  (atom.data.importerOptions.parserOptions.parser.delimiters.thousands =
                    e.target.value)
                }
                className="underline"
                innerLabelClassName="min-w-[5.1rem]"
              />
            </ColumnElement>
          </>
        )}
      </Column>
      <Widgets.Number.Base
        tabRight={atom.data.cell.view.tabRight}
        onTabRightChange={(v) => (atom.data.cell.view.tabRight = v)}
        onLocaleChange={(v) => (atom.data.cell.view.format.locale = v)}
        selectedLocale={atom.data.cell.view.format.locale}
        atomId={atom.id}
      />
    </BlockBase>
  );
};

export default observer(NumberTableImporterBlock);
