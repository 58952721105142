import { AtomModel } from '@models/atom.model';
import { ReferenceMap } from '@models/referenceModal.model';

import { ActionPath } from '@panel/components/action/form/form.panel.config';
import { TransitionPath } from '@panel/components/transition/transition.config';

export const getPathToSource = (
  atom: AtomModel,
  processId?: string
): string => {
  const { source } = atom.metaInfo;
  if (!processId || !source || !source.parentKind) return '';

  let main = ['ui', 'condition', 'notification'].includes(source.parentKind)
    ? 'editor/p'
    : 'settings';

  main = ['database'].includes(source.parentKind) ? 'database' : 'settings';

  const finalPartMap: Record<typeof source.parentKind, string> = {
    ui: `a/${source.elementId}/${ActionPath.Form}`,
    condition: `t/${source.elementId}/${TransitionPath.Condition}`,
    notification: `t/${source.elementId}/${TransitionPath.Notifications}`,
    traceInfo: 'trace-info',
    database: `${source.elementId}`,
    traceOverview: 'trace-overview',
    template: 'notifications',
    globalVariables: 'global-variables',
    variables: 's/i',
    transition: 's/i',
    form_action: 's/i',
    atom: 's/i',
    table_editor: 's/i',
    table_importer: 's/i'
  };

  const finalPart = finalPartMap[source.parentKind];

  return finalPart ? `/${processId}/${main}/${finalPart}` : '';
};

export const getNumberOfReferences = (refMap: ReferenceMap): number => {
  return Object.values(refMap).reduce(
    (total, references) => total + references.length,
    0
  );
};
