import { FC } from 'react';

import { DNDInfo } from '@library/library.types';

import { GlobalVariable } from './blocks/globalVariableBlock/globalVariable.comp';
import { DNDBlock, GlobalVariablesBlocks } from 'shared';

export const isGlobalVariablesBlocks = (
  blockType: unknown
): blockType is GlobalVariablesBlocks => {
  return (['GlobalVariable'] satisfies GlobalVariablesBlocks[]).includes(
    blockType as GlobalVariablesBlocks
  );
};

export const GLOBAL_VARIABLES_INFO_DND: DNDInfo = {
  title: 'Global Variables',
  description:
    "Define global variables that will be defined during the workflow's execution."
};

const GlobalVariableBlockPlexer = {
  ['GlobalVariable']: GlobalVariable
};

interface LibraryTraceOverviewProps {
  block: DNDBlock;
}

export const LibraryGlobalVariable: FC<LibraryTraceOverviewProps> = ({
  block
}) => {
  const Block = GlobalVariableBlockPlexer[block.type as GlobalVariablesBlocks];
  return <Block {...block} />;
};
