import { observer } from 'mobx-react';

import { AtomModel } from '@models/atom.model';

import { EditorOptions } from '@library/tableEditor/shared/editor.options';
import { TableEditorTextColumnData } from 'shared';

type Props = {
  atom: AtomModel<TableEditorTextColumnData>;
};

export const InputEditor = observer(({ atom }: Props) => {
  return (
    <EditorOptions
      atomId={atom.id}
      onPlaceholderChange={(value) =>
        (atom.data.editorOptions.editors.input.placeholder = value)
      }
      placeholder={atom.data.editorOptions.editors.input.placeholder}
    ></EditorOptions>
  );
});
