import { OperatorMap, OperatorOption, ResolvedVariableType } from 'shared'

const equalOption: OperatorOption = {
  label: '=',
  value: '=='
};

const notEqualOption: OperatorOption = {
  label: '≠',
  value: '!='
};

const superiorOption: OperatorOption = {
  label: '>',
  value: '>'
};

const inferiorOption: OperatorOption = {
  label: '<',
  value: '<'
};

const superiorOrEqualOption: OperatorOption = {
  label: '≥',
  value: '>='
};

const inferiorOrEqualOption: OperatorOption = {
  label: '≤',
  value: '<='
};

const containsOption: OperatorOption = {
  label: 'contains',
  value: 'contains'
};

const isDefinedOption: OperatorOption = {
  label: 'is defined',
  value: 'defined'
};

const startsWithOption: OperatorOption = {
  label: 'starts with',
  value: 'startsWith'
};

const endsWithOption: OperatorOption = {
  label: 'ends with',
  value: 'endsWith'
};

const oneOfOption: OperatorOption = {
  label: 'contains at least one of',
  value: 'oneOf'
};

const allOfOption: OperatorOption = {
  label: 'contains all of',
  value: 'allOf'
};

const noneOfOption: OperatorOption = {
  label: 'contains none of',
  value: 'noneOf'
};

const isTrueOption: OperatorOption = {
  label: 'is true',
  value: 'isTrue'
};

const isFalseOption: OperatorOption = {
  label: 'is false',
  value: 'isFalse'
};

export const operatorOptions: OperatorMap = {
  [ResolvedVariableType.Text]: [
    equalOption,
    notEqualOption,
    containsOption,
    startsWithOption,
    endsWithOption,
    isDefinedOption
  ],
  [ResolvedVariableType.Number]: [
    equalOption,
    notEqualOption,
    superiorOption,
    inferiorOption,
    superiorOrEqualOption,
    inferiorOrEqualOption,
    isDefinedOption
  ],
  [ResolvedVariableType.List]: [
    // equalOption, // removed for now
    allOfOption,
    oneOfOption,
    noneOfOption,
    isDefinedOption
  ],
  [ResolvedVariableType.Boolean]: [
    isTrueOption,
    isFalseOption,
    isDefinedOption
  ],
  [ResolvedVariableType.Date]: [
    equalOption,
    notEqualOption,
    superiorOption,
    inferiorOption,
    isDefinedOption
  ],
  [ResolvedVariableType.Custom]: [isDefinedOption],
  [ResolvedVariableType.File]: [isDefinedOption],
  [ResolvedVariableType.User]: [isDefinedOption],
  [ResolvedVariableType.Comment]: [isDefinedOption],
  [ResolvedVariableType.Table]: [isDefinedOption]
};
