import { ConditionSchemas } from './condition';
import { FBSchemas } from './formBuilder';
import { GlobalVariablesSchemas } from './globalVariable';
import { NotificationSchemas } from './notification';
import { TableEditorSchemas } from './tableEditor';
import { TableImporterSchemas } from './tableImporter';
import { TraceInfoSchemas } from './traceInfo';
import { TraceOverviewSchemas } from './traceOverview';

export * from './condition';
export * from './formBuilder';
export * from './globalVariable';
export * from './notification';
export * from './tableEditor';
export * from './tableImporter';
export * from './traceInfo';
export * from './traceOverview';

export const DndAtomSchemas = [
  ...ConditionSchemas,
  ...FBSchemas,
  ...GlobalVariablesSchemas,
  ...NotificationSchemas,
  ...TableEditorSchemas,
  ...TableImporterSchemas,
  ...TraceInfoSchemas,
  ...TraceOverviewSchemas
] as const;

export const ALL_BLOCKS = [
  'root',
  ...DndAtomSchemas.map((schema) => schema.shape.type.value)
] as const;
type DndAtomType = (typeof DndAtomSchemas)[number]['shape']['type']['value'];
export type BlockType = 'root' | DndAtomType;
