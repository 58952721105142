import { observer } from 'mobx-react';
import { Outlet, useParams } from 'react-router-dom';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';
import { useWorkflow } from '@hooks/useWorkflow';

import { ParamsList } from '@/routes/routes.types';
import PanelHeader from '@panel/PanelHeader';
import { PanelBody } from '@panel/panel.style';

import { TRANSITION_TAB_LINKS } from './transition.config';

const Transition = () => {
  const { transitionStore } = useStores();
  const { process } = useProcess();
  const { workflow } = useWorkflow();

  const transitionId = useParams<string>()[ParamsList.TransitionId] as string;
  const transitionModel = transitionStore.get(transitionId);

  if (process?.loading || !process || !workflow || !transitionModel) {
    return <></>;
  }

  return (
    <>
      <PanelHeader
        workflowName={workflow.name}
        infos={[transitionModel.sourceName, transitionModel.targetName]}
        actionType="transition"
        tabLinks={TRANSITION_TAB_LINKS}
      />
      <PanelBody>
        <Outlet />
      </PanelBody>
    </>
  );
};

export default observer(Transition);
