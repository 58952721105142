import { WorkflowModel } from '@models/workflow.model';

import RootStore from '@stores/root.store';

import { CreateShapeEvent, DeleteShapeEvent } from '@/types/event.types';

import { UserAction, shapeToFunctionPlexer } from './shape.plexer';

export const onShapeCreated = async (
  event: CreateShapeEvent,
  workflowModel: WorkflowModel,
  rootStore: RootStore
) => {
  if (!event.context) return;
  if (!event.context.shape) return;

  const { shape } = event.context;

  const createShapeFunction = shapeToFunctionPlexer(shape, UserAction.Create);
  return await createShapeFunction(event, workflowModel, rootStore);
};

export const onShapeDelete = async (
  event: DeleteShapeEvent,
  workflowModel: WorkflowModel,
  rootStore: RootStore
) => {
  if (!event.context) return;
  if (!event.context.shape) return;

  const { shape } = event.context;
  const deleteShapeFunction = shapeToFunctionPlexer(shape, UserAction.Delete);
  return await deleteShapeFunction(event, workflowModel, rootStore);
};
