import { forEach } from 'min-dash';

import * as createOptions from '../MenuOptions';

/**
 * @typedef {import("bpmn-js/lib//features/BpmnFactory").default} BpmnFactory
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenu").default} PopupMenu
 * @typedef {import("bpmn-js/lib/features/Modeling").default} Modeling
 * @typedef {import("bpmn-js/lib//features/BpmnReplace").default} BpmnReplace
 * @typedef {import("diagram-js/lib/features/Rules").default} Rules
 * @typedef {import("diagram-js/lib/i18n/translate/translate").default} Translate
 * @typedef {import("diagram-js/lib/features/create/Create").default} Create
 * @typedef {import("diagram-js/lib/core/ElementFactory").default} ElementFactory
 *
 *
 * @typedef {import("diagram-js/lib/model/Types").Element} Element
 * @typedef {import("diagram-js/lib/model/Types").Moddle} Moddle
 *
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").PopupMenuEntries} PopupMenuEntries
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").PopupMenuEntry} PopupMenuEntry
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").PopupMenuEntryAction} PopupMenuEntryAction
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").PopupMenuHeaderEntries} PopupMenuHeaderEntries
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").default} PopupMenuProvider
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenu").PopupMenuTarget} PopupMenuTarget
 *
 * @typedef {import("../MenuOptions").MenuOption} MenuOption
 */

/**
 * A BPMN-specific popup menu provider.
 *
 * @implements {PopupMenuProvider}
 *
 * @param {BpmnFactory} bpmnFactory
 * @param {PopupMenu} popupMenu
 * @param {Modeling} modeling
 * @param {Moddle} moddle
 * @param {BpmnReplace} bpmnReplace
 * @param {Rules} rules
 * @param {Translate} translate
 * @param {Create} create
 * @param {ElementFactory} elementFactory
 */

class CreateMenuProvider {
  constructor(
    bpmnFactory,
    popupMenu,
    modeling,
    moddle,
    bpmnReplace,
    rules,
    translate,
    create,
    elementFactory
  ) {
    this.bpmnFactory = bpmnFactory;
    this.popupMenu = popupMenu;
    this.modeling = modeling;
    this.moddle = moddle;
    this.bpmnReplace = bpmnReplace;
    this.rules = rules;
    this.translate = translate;
    this.create = create;
    this.elementFactory = elementFactory;

    this.register();
  }

  register() {
    this.popupMenu.registerProvider('bpmn-create', this);
  }

  getPopupMenuEntries(target) {
    switch (target) {
      case 'event':
        return this.createEntries(target, createOptions.EVENT);
      case 'gateway':
        return this.createEntries(target, createOptions.GATEWAY);
      case 'action':
        return this.createEntries(target, createOptions.TASK);
      default:
        return {};
    }
  }

  createEntries(target, createOptions) {
    const entries = {};

    const self = this;

    forEach(createOptions, (createOption) => {
      entries[createOption.actionName] = self.createEntry(createOption, target);
    });

    return entries;
  }

  createEntry(createOption) {
    const { type, group, className, title, icon } = createOption;

    const create = this.create;
    const elementFactory = this.elementFactory;
    const popupMenu = this.popupMenu;

    const shape = elementFactory.createShape({ type });

    function createListener(event) {
      popupMenu.close();

      create.start(event, shape);
    }

    const shortType = type.replace(/^bpmn:/, '');

    return {
      group: group,
      className,
      imageUrl: icon,
      label: title || this._translate('Create {type}', { type: shortType }),
      action: {
        dragstart: createListener,
        click: createListener
      }
    };
  }
}

CreateMenuProvider.$inject = [
  'bpmnFactory',
  'popupMenu',
  'modeling',
  'moddle',
  'bpmnReplace',
  'rules',
  'translate',
  'create',
  'elementFactory'
];

export default CreateMenuProvider;
