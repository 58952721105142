import { ColumnSchema } from 'shared/src/trace/table/column.schema';

import { ColumnComp } from './column.comp';
import { ColumnImporterSettings } from './column.importer.settings';
import { EditorMode as EditorModeColumn } from './editorMode.column';

export namespace TableColumn {
  export const Base = ColumnComp;
  export const ImporterSettings = ColumnImporterSettings;
  export import Schema = ColumnSchema;
  export type Data = ColumnSchema.Data;
  export const EditorMode = EditorModeColumn;
}
