import { AtomModel } from '@models/atom.model';

import { Prefill } from '@library/formBuilder/prefill';
import { NumberFieldData } from 'shared';

type Props = {
  atom: AtomModel<NumberFieldData>;
};

export const NumberFieldModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
