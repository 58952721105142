import { observer } from 'mobx-react';
import { DataSourceOption, SOURCE_INFO_MAP } from 'shared';

import { Label } from '@components/ui/label';
import { RadioGroup, RadioGroupItem } from '@components/ui/radio-group';

type Props = {
  id: string;
  dataSource: DataSourceOption;
  onDataSourceChange: (source: DataSourceOption) => void;

  possibleSources?: DataSourceOption[];
};

export const SourceSelector = observer((props: Props) => {
  const possibleSources = props.possibleSources ?? ['normalSource', 'database'];
  return (
    <>
      <Label>Data source</Label>
      <RadioGroup
        value={props.dataSource}
        onValueChange={props.onDataSourceChange}
      >
        {possibleSources.map((selectedSource) => {
          const { value, name } = SOURCE_INFO_MAP[selectedSource];
          return (
            <div className="flex items-center space-x-2" key={value}>
              <RadioGroupItem value={value} id={value + props.id} />
              <Label
                htmlFor={value + props.id}
                className="font-normal cursor-pointer"
              >
                {name}
              </Label>
            </div>
          );
        })}
      </RadioGroup>
    </>
  );
});
