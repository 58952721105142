import { DataSourceOption, SourcesData } from 'shared';

import { newError } from '@/services/errors/errors';

import { DatabaseSelector } from '../repositorySelector/repositorySelector';
import { StaticSelector } from '../staticSelector/staticSelector';
import { VariableSelector } from '../variableSelector/variableSelector';

type Props = {
  selectedDataSource: DataSourceOption;
  sourcesData: SourcesData;
  onSourceDataChange: (sourceData: SourcesData) => void;

  id: string;
};
export const RenderSource = (props: Props) => {
  switch (props.selectedDataSource) {
    case 'normalSource':
      return (
        <StaticSelector
          sourceData={props.sourcesData.normalSource}
          onDataChange={(normalSource) => {
            props.onSourceDataChange({
              ...props.sourcesData,
              normalSource
            });
          }}
        />
      );
    case 'database':
      return (
        <DatabaseSelector
          id={props.id}
          sourceData={props.sourcesData.database}
          onDataChange={(database) =>
            props.onSourceDataChange({
              ...props.sourcesData,
              database
            })
          }
        />
      );
    case 'stateVariable':
      return (
        <VariableSelector
          selectedRef={props.sourcesData.stateVariable}
          onSelected={(stateVariable) =>
            props.onSourceDataChange({
              ...props.sourcesData,
              stateVariable: stateVariable
            })
          }
        />
      );
    default:
      newError('DataSource-1234', 'Invalid data source option', true);
      return <></>;
  }
};
