import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';

import { SubHeader } from '@components/header/subheader/sub-header.comp';

import useProcess from '@hooks/useProcess';

const Settings = () => {
  const { process } = useProcess();
  if (process?.isLoading) return <div>Loading...</div>;
  return (
    <div className="w-full h-full flex flex-col">
      <SubHeader />
      {/* <div className="w-full h-[1px] mt-[47px] bg-gray-200" /> */}
      <section className="h-full overflow-auto">
        <Outlet />
      </section>
    </div>
  );
};

export default observer(Settings);
