import { Shape } from 'bpmn-js/lib/model/Types';

import { WorkflowModel } from '@models/workflow.model';

import RootStore from '@stores/root.store';

import { eventFunctionPlexer } from '@/services/bpmn/events/event/event';
import { newError } from '@/services/errors/errors';
import { ElementIdType } from '@/types/bpmn.types';
import {
  CreateShapeEvent,
  DeleteShapeEvent,
  ShapeEvents
} from '@/types/event.types';

import { actionsFunctionPlexer as actionsFunctionMap } from './action';
import { gatewayFunctionPlexer } from './gateway';

export enum UserAction {
  Create = 'create',
  Delete = 'delete'
}
export type CreateFunction = (e: CreateShapeEvent) => Promise<void>;
export type DeleteFunction = (e: DeleteShapeEvent) => Promise<void>;

export const shapeToFunctionPlexer = (
  shape: Shape,
  actionType: UserAction
): ((
  event: ShapeEvents,
  workflowModel: WorkflowModel,
  rootSTore: RootStore
) => Promise<boolean>) => {
  const shapeBpmnType = shape?.id.split('_')[0] as ElementIdType;

  switch (shapeBpmnType) {
    case ElementIdType.Activity:
      return actionsFunctionMap[actionType];
    case ElementIdType.Gateway:
      return gatewayFunctionPlexer[actionType];
    case ElementIdType.Event:
      return eventFunctionPlexer[actionType];
    case ElementIdType.Lane:
    case ElementIdType.Transition:
      // eslint-disable-next-line @typescript-eslint/require-await
      return async () => {
        return true;
      };
    case ElementIdType.Workflow:
    default:
      newError('SHAPE-Wy4GN', 'Shape is not supported', true, {
        isConsoleLogged: true
      });
      // eslint-disable-next-line @typescript-eslint/require-await
      return async () => {
        return false;
      };
  }
};
