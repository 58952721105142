import { z } from "zod";
import { BaseEditorSchema, MultiEditorsSchema } from "./shared.schema";
import { ColumnSchema } from "../../../../trace";
import { DataSelectorSchema } from "../../../dataSelector";

const SelectEditorSchema = BaseEditorSchema.extend({
    type: z.literal("select"),
    options: DataSelectorSchema,
});

export const SelectColumnDataSchema = (valueValidation?: boolean) =>
    z
        .object({
            required: z.boolean(),
        })
        .merge(ColumnSchema.MainSchema(valueValidation))
        .merge(
            MultiEditorsSchema(
                z.object({
                    select: SelectEditorSchema,
                })
            )
        )
        .strict();

export type TableEditorSelectColumnData = z.infer<
    ReturnType<typeof SelectColumnDataSchema>
>;
