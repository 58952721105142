import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@components/ui/button';
import { Input } from '@components/ui/input';

import useProcess from '@hooks/useProcess';

export default observer(() => {
  const navigate = useNavigate();
  const { process } = useProcess();

  return (
    <div className="border">
      <div className="pb-8">
        <h3 className="font-semibold text-md">
          This process is password protected.
        </h3>
        <p className="font-medium text-sm">
          Please provide the password or ask it to the owner.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <Input
          type="password"
          onChange={(event) => {
            process.password = event.target.value;
          }}
          placeholder="Enter password"
        />
        <div className="flex flex-row justify-end gap-x-4">
          <Button
            onClick={() => {
              navigate('/');
            }}
            variant={'ghost'}
          >
            Go back
          </Button>
          <Button
            onClick={() => {
              process.status = 'NEED_LOADING';
            }}
            variant={'default'}
            className="text-white"
          >
            Unlock
          </Button>
        </div>
      </div>
    </div>
  );
});
