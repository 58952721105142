import { observer } from 'mobx-react';

import DndInterface from '@components/testDndKit/dndInterface';

import { DndKitValue, NormalSourceData } from 'shared';

type Props = {
  sourceData: Maybe<NormalSourceData>;
  onDataChange: (data: NormalSourceData) => void;
};

export const StaticSelector = observer(
  ({ sourceData, onDataChange }: Props) => {
    const updateValues = (newValues: DndKitValue[]) => {
      onDataChange({ values: newValues });
    };

    const addValue = (valueToAdd: DndKitValue) => {
      if (!sourceData) {
        onDataChange({ values: [valueToAdd] });
        return;
      }
      const newValues = [...sourceData.values, valueToAdd];
      onDataChange({ values: newValues });
    };

    return (
      <DndInterface
        values={sourceData?.values ?? []}
        addValue={addValue}
        updateValues={updateValues}
        title="Values"
      />
    );
  }
);
