import { z } from "zod";
import { TO_DateDataSchema } from "./date.schema";
import { TO_FileDataSchema } from "./file-compact.schema";
import { TO_ListDataSchema } from "./list.schema";
import { TO_NumberDataSchema } from "./number.schema";
import { TO_TextDataSchema } from "./text.schema";
import { TO_UserDataSchema } from "./user.schema";
import { TO_KeyValueDataSchema } from "./key-value.schema";

export const TraceOverviewSchemas = [
    z.object({
        type: z.literal("TO_Text"),
        data: TO_TextDataSchema(),
    }),
    z.object({
        type: z.literal("TO_Date"),
        data: TO_DateDataSchema(),
    }),
    z.object({
        type: z.literal("TO_Number"),
        data: TO_NumberDataSchema(),
    }),
    z.object({
        type: z.literal('TO_File'),
        data: TO_FileDataSchema(),
    }),
    z.object({
        type: z.literal("TO_List"),
        data: TO_ListDataSchema(),
    }),
    z.object({
        type: z.literal("TO_User"),
        data: TO_UserDataSchema(),
    }),
    z.object({
        type: z.literal("TO_KeyValue"),
        data: TO_KeyValueDataSchema(),
    })
] as const;

export const TRACE_OVERVIEW_TYPES = TraceOverviewSchemas.map(schema => schema.shape.type.value);
export type TOBlocks = typeof TraceOverviewSchemas[number]["shape"]["type"]["value"];
