import { FC } from 'react';

import * as Blocks from '@library/condition/blocks';
import {
  DNDInfo,
  LibraryProps,
  Plexer,
  ToolbarItem
} from '@library/library.types';
import { ConditionBlocks } from 'shared';


export const CDT_TOOLBAR_ITEMS: ToolbarItem<ConditionBlocks>[] = [
  {
    type: 'And',
    icon: <Blocks.AndIcon />
  },
  {
    type: 'Or',
    icon: <Blocks.OrIcon />
  },
  {
    type: 'Not',
    icon: <Blocks.NotIcon />
  },
  {
    type: 'Condition',
    icon: <Blocks.ConditionIcon />
  },
  {
    type: 'Code Condition',
    icon: <Blocks.CodeConditionIcon />
  }
];

export const CONDITION_INFO_DND: DNDInfo = {
  title: 'Condition',
  description: `
  Drag & drop a block to your transition to configure condition.
  Use Block Or, And or Not to create
  complex conditions.
  Each Transition can only have one Block at the root.
  `
};

const TransitionPlexer: Plexer<ConditionBlocks> = {
  ['And']: Blocks.And,
  ['Or']: Blocks.Or,
  ['Not']: Blocks.Not,
  ['Condition']: Blocks.Condition,
  ['Code Condition']: Blocks.CodeCondition
};

export const LibraryTransition: FC<LibraryProps> = ({ block }) => {
  const Block = TransitionPlexer[block.type as ConditionBlocks];
  return <Block {...block} />;
};
