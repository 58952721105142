import { z } from "zod";

import { ImportOptionsWithParserSchema } from "./table-importer.atom";
import { DateWidgetSchema, ColumnSchema } from "../../../../trace";
import { DateFormatSchema } from "../../../dataSelector";

export const TIDateColumnDataSchema = (valueValidation?: boolean) =>
    z
        .object({
            cell: z.object({
                view: DateWidgetSchema.MainSchema,
            }),
            required: z.boolean(),
        })
        .merge(ColumnSchema.MainSchema(valueValidation))
        .merge(
            ImportOptionsWithParserSchema(
                z.object({
                    type: z.literal("date"),
                    inputFormat: DateFormatSchema,
                    outputFormat: DateFormatSchema,
                })
            )
        )
        .strict();

export type TableImporterDateColumnData = z.infer<
    ReturnType<typeof TIDateColumnDataSchema>
>;
