import Model, { ModelError } from '@models/base/base.model';

import EventStore from '@stores/event.store';

interface CreateEvent {
  id: Model['id'];
}

export default class EventModel extends Model {
  store: EventStore;

  constructor(store: EventStore, event: CreateEvent) {
    super(store, event.id, false);
    this.store = store;
  }

  get toJSON() {
    return {};
  }

  public delete() {
    return this.store.delete(this.id);
  }

  public get errors(): ModelError[] {
    return [];
  }
}
