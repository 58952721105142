import { makeObservable, observable } from 'mobx';
import { TraceKey } from 'shared';

import Model from '@models/base/base.model';
import { TraceKeyModel } from '@models/traceKey/traceKey.model';

import BaseStore from '@stores/base/base.store';

export abstract class BaseModelWithTraceKey extends Model {
  public traceKey: TraceKeyModel;

  constructor(
    store: BaseStore<Model>,
    id: string,
    traceKeyDTO: TraceKey,
    isLoading?: boolean
  ) {
    super(store, id, isLoading);
    this.traceKey = new TraceKeyModel(store, id, traceKeyDTO);

    makeObservable(this, {
      traceKey: observable
    });
  }

  abstract get toJSON();
}
