import { z } from 'zod';

import { AtomReferenceSchema } from '../../../../other/state-menu.schema';

export const TO_KEYVALUE_VIEW_TYPES = ['KeyValue'] as const;
export const TO_KeyValueViewTypeSchema = z.enum(TO_KEYVALUE_VIEW_TYPES);

export const TO_KeyValueDataSchema = (_?: boolean) =>
  z
    .object({
      title: z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_KeyValueViewTypeSchema
    })
    .strict();

export type TO_KeyValueData = z.infer<ReturnType<typeof TO_KeyValueDataSchema>>;
