import ElementRegistry from 'diagram-js/lib/core/ElementRegistry.js';

class CustomElementRegistry extends ElementRegistry {
  forEach(fn) {
    const map = this._elements;

    Object.keys(map).forEach(function (id) {
      const container = map[id];
      // Return when contziner don't have element to avoid error
      // Cause by the remove element in BpmnParserProvider
      if (!container?.element) return;
      const element = container.element;
      const gfx = container.gfx;

      return fn(element, gfx);
    });
  }
}

export default CustomElementRegistry;
