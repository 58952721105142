import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { Input } from '@components/ui/input';
import { Label } from '@components/ui/label';
import { RadioGroup, RadioGroupItem } from '@components/ui/radio-group';

import { AtomModel } from '@models/atom.model';

import { AtomReference, DenominatorTypeLabelMap, DenominatorTypeList, ProgressWidgetSchema, ResolvedVariableType } from 'shared'

import { ColumnElement, ColumnTitle } from '@atoms/columnElement';
import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';
import { ExempleTooltip, InfoTooltip } from '@atoms/infoTooltip';

type Props = Omit<ProgressWidgetSchema.Data, 'type'> & {
  atomId: AtomModel['id'];
  onDenominatorTypeChange: (type: ProgressWidgetSchema.DenominatorType) => void;
  onDenominatorNumberChange: (number: Maybe<number>) => void;
  onDenominatorVariableChange: (variableRef: Maybe<AtomReference>) => void;
  onPathRefChange: (pathRef: Maybe<AtomReference>) => void;
};

export const ProgressWidget = (props: React.PropsWithChildren<Props>) => {
  const renderDenominator = () => {
    switch (props.denominator.type) {
      case 'number':
        return (
          <>
            <Label>Denominator value</Label>
            <Input
              type="number"
              value={props.denominator.data.number}
              onChange={(e) => {
                if (e.target.value === '') {
                  props.onDenominatorNumberChange(undefined);
                } else {
                  props.onDenominatorNumberChange(Number(e.target.value));
                }
              }}
              placeholder="Ex: 100"
            />
          </>
        );
      case 'variable':
        return (
          <>
            <Label>Denominator variable</Label>
            <VariableSelector
              onSelected={props.onDenominatorVariableChange}
              selectedRef={props.denominator.data.variableRef}
              specificResolvedTypes={[ResolvedVariableType.Number]}
            />
          </>
        );
      case 'none':
        return null;
    }
  };
  return (
    <>
      {props.children}

      <Column $width="220px">
        <ColumnTitle>Display options</ColumnTitle>

        <ColumnElement>
          <Label htmlFor={`progress-${props.atomId}`}>Progress variable</Label>
          <VariableSelector
            onSelected={props.onPathRefChange}
            selectedRef={props.pathRef}
            specificResolvedTypes={[ResolvedVariableType.Number]}
          />
        </ColumnElement>
        <ColumnElement>
          <div className="flex items-center space-x-2">
            <Label>Denominator type</Label>
            <InfoTooltip
              title="Denominator type"
              description="The progress bar expects a value between 0 and 1. A denominator can be provided to scale the stored progress value if needed."
              additionalContent={
                <ExempleTooltip
                  exampleName="Scale down (100)"
                  input="75"
                  output="0.75"
                />
              }
            />
          </div>
          <RadioGroup
            value={props.denominator.type}
            onValueChange={props.onDenominatorTypeChange}
          >
            {DenominatorTypeList.map((type) => (
              <div className="flex items-center space-x-2" key={type}>
                <RadioGroupItem value={type} id={type} />
                <Label htmlFor={type} className="font-normal cursor-pointer">
                  {DenominatorTypeLabelMap[type]}
                </Label>
              </div>
            ))}
          </RadioGroup>
        </ColumnElement>
        <ColumnElement>{renderDenominator()}</ColumnElement>
      </Column>
    </>
  );
};
