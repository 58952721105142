import LassoToolModule from 'diagram-js/lib/features/lasso-tool';

import DefaultCanvasBehavior from './DefaultCanvasBehavior';

/**
 * @type { import("didi").ModuleDeclaration }
 */
export default {
  __depends__: [LassoToolModule],
  __init__: ['moveCanvas'],
  moveCanvas: ['type', DefaultCanvasBehavior]
};
