import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { TableColumn } from '@components/trace/column';

import { useAtom } from '@hooks/useAtom';

import { ResolvedVariableType, DNDBlock } from 'shared'

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';
import { Widgets } from '@widgets';

import { initialLabelsData } from './labels.data';
import { LabelsSettingsModal } from './labels.settings';
import { LabelsIcon } from './labels.utils';

const LabelsTableImporterBlock = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    type: 'Table_Importer_Labels',
    sourceId: block.id,
    parentId: actionId,
    parentKind: 'table_importer',
    initialData: initialLabelsData,
    variableInfo: {
      resolvedType: ResolvedVariableType.List
    }
  });

  if (!atom) return null;

  const getModalSettings = (): SettingsConfig => {
    return {
      title: atom.data.title || `Labels Column Title`,
      content: <LabelsSettingsModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getModalSettings()}
      hasRequired
    ></EndControlsFB>
  );

  const onTitleChange = (title: TableColumn.Data['title']) => {
    if (atom.data.title === atom.data.importerOptions.from) {
      atom.data.importerOptions.from = title; // From follows the column header
    }
    atom.data.title = title;
  };

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={LabelsIcon}
      endControls={getEndControls()}
    >
      <TableColumn.Base
        atomId={atom.id}
        title={atom.data.title}
        width={atom.data.width}
        isDisplayedByDefault={atom.data.isDisplayedByDefault}
        onTitleChange={onTitleChange}
        onWidthChange={(v) => (atom.data.width = v)}
        isfixedColumn={atom.data.isfixedColumn}
        onFixedColumnChange={(v) => (atom.data.isfixedColumn = v)}
        onDisplayByDefaultChange={(v) => (atom.data.isDisplayedByDefault = v)}
      />
      <Widgets.Labels.Base />
    </BlockBase>
  );
};

export default observer(LabelsTableImporterBlock);
