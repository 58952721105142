import { runInAction } from 'mobx';
import { SmallProcessSchema } from 'shared';
import { z } from 'zod';

import {
  Collaborator,
  InputProcess,
  ProcessModel
} from '@models/process.model';

import AsyncStore from '@stores/base/async.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { UserRoles } from '@/types/process.types';
import { ApiResponseEmpty } from '@/utils/http';
import { parseWithZod } from '@/utils/parseZodSchema';

interface InviteUserDTO {
  role: UserRoles;
  emails: string[];
}

interface InviteUsersResponseDTO {
  users: Collaborator[];
}
interface UpdateRoleDTO {
  role: UserRoles;
  userId: string;
}

interface UpdateProcessDTO {
  password?: string;
  is_public?: boolean;
  name?: string;
  image?: string;
}

export class ProcessStore extends AsyncStore<ProcessModel> {
  currentProcessId?: string;

  constructor(rootStore: RootStore) {
    super(rootStore, ProcessModel, 'process');
  }

  public setCurrentProcess(processId: ProcessModel['id']) {
    this.currentProcessId = processId;
  }

  public get currentProcess(): Maybe<ProcessModel> {
    return this.get(this.currentProcessId);
  }

  public async createNewProcess(inputProcess: InputProcess) {
    const { name, draft, template } = inputProcess;
    template; // TODO: Remove this line when the template is used
    const newProcess = this.create();
    newProcess.name = name;
    newProcess.draft = draft;

    const dto = {
      name: newProcess.name,
      id: newProcess.id,
      draft: newProcess.draft
    };

    const data: ProcessModel = await this.createNew(dto);
    return data;
  }

  public getProcessById(id: ProcessModel['id']): ProcessModel {
    if (!id) throw new Error('Id need to be defined');
    const inMemoryProcess = this.get(id);
    if (inMemoryProcess) {
      return inMemoryProcess;
    }

    const newProcess = new ProcessModel(this, id, true);
    this.set(id, newProcess);

    return newProcess;
  }

  public async generateProcess(processId: ProcessModel['id']) {
    const response = await this.httpWrapper.get<{
      workflowPublishedId: string;
    }>(`/${processId}/generate`);

    if (response) {
      if (this.currentProcess) {
        this.currentProcess.workflowIds = this.currentProcess.workflowIds?.map(
          (wf) => ({ ...wf, published_id: response.workflowPublishedId })
        );
      }
    }
  }

  public async updateProcess(
    id: Maybe<ProcessModel['id']>,
    data: UpdateProcessDTO
  ): Promise<ApiResponseEmpty> {
    const response = (await this.httpWrapper
      .patch(`/${id}`, data)
      .catch((e: Error) => {
        newError('PROCS-8Kc5L', e, true, {
          customMessage: 'An error occurred during updating the process'
        });
      })) as ApiResponseEmpty;
    if (response) {
      runInAction(() => {
        if (this.currentProcess) {
          if ('password' in data) {
            this.currentProcess.password_protected =
              data.password === '' ? false : true;
          } else {
            Object.assign(this.currentProcess, data);
          }
        }
      });
    }
    return response;
  }

  public async inviteUser(
    processId: string,
    emails: string[],
    role: UserRoles
  ): Promise<Maybe<Collaborator[]>> {
    const dto: InviteUserDTO = {
      emails,
      role
    };
    const response = await this.httpWrapper
      .post<InviteUsersResponseDTO>(`/${processId}/invites`, dto)
      .catch((e: Error) => {
        newError('PROCS-5m6gS', e, true, {
          customMessage:
            'An error occured, user is not part of Stratumn or already invited'
        });
      });
    if (this.currentProcess?.collaborators && response) {
      this.currentProcess.collaborators = [
        ...this.currentProcess.collaborators,
        ...response.users
      ];
      return response.users;
    }
    return;
  }

  public async loadRoles(id: string): Promise<Maybe<Collaborator[]>> {
    return await this.httpWrapper.get<Collaborator[]>(`/${id}/roles`);
  }

  public async updateRole(
    id: ProcessModel['id'],
    role: UserRoles,
    userId: string
  ): Promise<void> {
    const dto: UpdateRoleDTO = {
      role,
      userId
    };
    await this.httpWrapper.put(`/${id}/roles`, dto);
    if (this.currentProcess) {
      this.currentProcess.collaborators =
        this.currentProcess.collaborators?.map((collaborator) => {
          if (collaborator.id === userId) return { ...collaborator, role };
          else return collaborator;
        });
    }
  }

  public async deleteRole(
    id: ProcessModel['id'],
    userId: string
  ): Promise<void> {
    await this.httpWrapper.delete(`/${id}/roles/${userId}`);

    if (this.currentProcess) {
      this.currentProcess.collaborators =
        this.currentProcess.collaborators?.filter(
          (collab) => collab.id !== userId
        );
    }
  }

  public async getAllProcesses() {
    const data = await this.httpWrapper.get('/');

    const processes = parseWithZod(
      z.array(SmallProcessSchema),
      data,
      'PROC-qok32n'
    );

    if (!processes) {
      return;
    }

    for (const process of processes) {
      const tmp = new ProcessModel(this, process.id, false);
      tmp.name = process.name;
      tmp.draft = process.draft;
      tmp.updatedAt = process.updatedAt;
      tmp.createdAt = process.createdAt;
      tmp.isPublished = process.published;
      tmp.workflowIds = process.workflows;
      this.set(process.id, tmp);
    }
  }
}
