import { ChangeEvent } from 'react';

import { LayoutGrid, List, Search } from 'lucide-react';

import { ViewType } from '../home.utils';
import {
  AddNewProcessBtn,
  HeaderContainer,
  LayoutContainer,
  LayoutIconContainer,
  SearchBar,
  SearchBarContainer,
  SearchIconContainer
} from './homeHeader.style';

interface HomeHeaderProps {
  onProcessSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onViewChange: (view: ViewType) => void;
  selectedView: ViewType;
}

const HomeHeader = ({
  onProcessSearch,
  onViewChange,
  selectedView
}: HomeHeaderProps) => {
  return (
    <HeaderContainer>
      <SearchBarContainer>
        <SearchIconContainer>
          <Search size={16} />
        </SearchIconContainer>
        <SearchBar
          autoFocus
          onChange={onProcessSearch}
          placeholder="Search..."
        />
      </SearchBarContainer>
      <LayoutContainer>
        <LayoutIconContainer
          $isSelected={selectedView === ViewType.Grid}
          onClick={() => onViewChange(ViewType.Grid)}
        >
          <LayoutGrid size={17} />
        </LayoutIconContainer>
        <LayoutIconContainer
          $isSelected={selectedView === ViewType.List}
          onClick={() => onViewChange(ViewType.List)}
        >
          <List size={17} />
        </LayoutIconContainer>
      </LayoutContainer>
      <AddNewProcessBtn to="/new">Add a new project</AddNewProcessBtn>
    </HeaderContainer>
  );
};

export default HomeHeader;
