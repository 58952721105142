import { z } from "zod";

import { BaseActionSchema, BaseCreateActionDTO } from "./shared.action.schema";
import { ActionType } from "./types";

const FormMetadataSchema = z.object({
  name: z.string(),
});

export const FormDTOSchema = z.object({
  type: z.literal("FORM") satisfies z.ZodSchema<ActionType>,
  metadata: FormMetadataSchema,
});

export const FormSchema = BaseActionSchema.merge(FormDTOSchema);

export const CreateFormDTO = BaseCreateActionDTO.merge(FormDTOSchema);

export type FormActionDTO = z.infer<typeof FormDTOSchema>;
export type FormAction = z.infer<typeof FormSchema>;
export type FormMetadata = z.infer<typeof FormMetadataSchema>;
