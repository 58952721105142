import { z } from 'zod';

import { AtomReferenceSchema } from '../../../atomReference.schema';

export const TO_TEXT_VIEW_TYPES = ['Text', 'Code', 'Link', 'Prose'] as const;
export const TO_TextViewTypeSchema = z.enum(TO_TEXT_VIEW_TYPES);

export const TO_TextIconSchema = z.enum([
  'Text',
  'Link',
  'Code',
  'ScrollText',
  'Pilcrow'
]);

export type TO_TextIconType = z.infer<typeof TO_TextIconSchema>;

export const TO_TextDataSchema = (_?: boolean) =>
  z
    .object({
      title: z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_TextViewTypeSchema,
      hasIcon: z.boolean().default(false),
      icon: TO_TextIconSchema
    })
    .strict();

export type TO_TextData = z.infer<ReturnType<typeof TO_TextDataSchema>>;
