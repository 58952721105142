import { useEffect, useMemo, useState } from 'react';

import { CustomText } from '@pages/Settings/notifications/editor/slate';

import { NotificationTemplateModel } from '@models/notificationTemplate.model';

import useStores from './useStore';

export interface VariableNotification {
  id: string;
  title: string;
  variables: {
    text: string;
  }[];
}

interface ChildType {
  variable?: boolean;
  text?: string;
  children?: CustomText[];
}

const useVariableNotifications = () => {
  const { notificationTemplateStore } = useStores();
  const notificationTemplates = useMemo(() => {
    return notificationTemplateStore.toArray();
  }, [notificationTemplateStore]);
  const [variableNotifications, setVariableNotifications] = useState<
    VariableNotification[] | null
  >(null);

  useEffect(() => {
    if (!notificationTemplates) return;
    const updatedNotifications = notificationTemplates.map(
      (temp: NotificationTemplateModel) => {
        const { id, title, text } = temp;
        const variables: { text: string }[] = [];

        const extractVariables = (children: ChildType[]) => {
          children.forEach((child) => {
            if (child.variable) {
              variables.push({
                text: child.text || ''
              });
            } else if (child.children) {
              extractVariables(child.children);
            }
          });
        };

        // Go through each paragraph to extract variables
        text.forEach((paragraph) => {
          extractVariables(paragraph.children || []);
        });

        return {
          id,
          title,
          variables
        };
      }
    );

    setVariableNotifications(updatedNotifications);
  }, [notificationTemplates]);

  return variableNotifications;
};

export default useVariableNotifications;
