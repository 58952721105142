import { observer } from 'mobx-react';
import { Resizable } from 're-resizable';
import { Outlet } from 'react-router-dom';

import { config, getEnable } from './panel.config';
import { PanelWrapper } from './panel.style';
import { Side, Width } from './panel.type';

interface PanelProps {
  side?: Side;
  width?: Partial<Width>;
}

const Panel = ({ side = Side.Right, width = {} }: PanelProps) => {
  const widthProp: Width = {
    ...config.width,
    ...width
  };

  return (
    <PanelWrapper
      className="shadow-md outline outline-gray-200 outline-1"
      $side={side}
      initial={{
        x: widthProp.default,
        opacity: 0
      }}
      animate={{
        x: 0,
        opacity: 1
      }}
      exit={{
        x: widthProp.max
      }}
      transition={{
        duration: 0.3,
        ease: 'anticipate'
      }}
    >
      <Resizable
        enable={getEnable(side)}
        defaultSize={{ width: widthProp.default, height: '100%' }}
        minWidth={widthProp.min}
        maxWidth={widthProp.max}
      >
        <Outlet />
      </Resizable>
    </PanelWrapper>
  );
};

const PanelObserver = observer(Panel);
export default PanelObserver;
