import { NumberWidgetSchema } from 'shared';
import { DollarSign, Hash, LucideIcon, Percent } from 'lucide-react';

export namespace NumberWidgetConfig {
  export const STYLE_LIST = ['decimal', 'percent', 'currency'] as const;

  type StyleInfo = {
    name: string;
    icon: LucideIcon;
  };
  export const StyleToInfoMap: Record<NumberWidgetSchema.Style, StyleInfo> = {
    decimal: {
      name: 'Decimal',
      icon: Hash
    },
    percent: {
      name: 'Percent',
      icon: Percent
    },
    currency: {
      name: 'Currency',
      icon: DollarSign
    }
  };

  export const CURRENCY_DISPLAY_LIST = ['symbol', 'code', 'name'] as const;

  type CurrencyInfo = {
    name: string;
  };
  export const CurrencyToInfoMap: Record<
    NumberWidgetSchema.CurrencyDisplay,
    CurrencyInfo
  > = {
    symbol: {
      name: 'Symbol (ex: $)'
    },
    code: {
      name: 'Code (ex: USD)'
    },
    name: {
      name: 'Name (ex: Dollar)'
    }
  };

  export const languageOptions = Intl.NumberFormat();
}
