import { Dispatch, SetStateAction } from 'react';

import { Box, ModalDialog } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';

interface BasicModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  children?: React.ReactNode;
  startIcon?: React.ReactNode;
  width?: string;
}

export const BasicModal = ({
  open,
  setOpen,
  title,
  children,
  startIcon,
  width
}: BasicModalProps) => {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      // hideBackdrop
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none',
        zIndex: 100001
      }}
    >
      <ModalDialog sx={{ width: width }}>
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            {startIcon} {title}
          </Box>
        </Typography>
        {children}
      </ModalDialog>
    </Modal>
  );
};
