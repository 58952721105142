import { TableImporterLabelsColumnData } from "shared";

const DEFAULT_HEADER = 'Default Labels Column Header';
export const initialLabelsData: TableImporterLabelsColumnData = {
  title: DEFAULT_HEADER,
  width: 'large',
  isfixedColumn: false,
  isDisplayedByDefault: true,
  required: true,
  cell: {
    view: {
      type: 'labels'
    }
  },
  importerOptions: {
    from: DEFAULT_HEADER
  }
};
