import { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import DNDBase from '@components/dnd/base';

import useStores from '@hooks/useStore';

import { ParamsList } from '@/routes/routes.types';
import { newError } from '@/services/errors/errors';
import { deepCopy } from '@/utils/deepCopy';

import WarningDnd, { WarningType } from '../warning';
import { DNDBlock } from 'shared';



const ConditionDnd = () => {
  const transitionId = useParams<string>()[ParamsList.TransitionId] as string;
  const { transitionStore } = useStores();
  const transition = transitionStore.get(transitionId);
  const conditionDND = transition?.conditionDnd;
  const [showWarning, setShowWarning] = useState<WarningType>(
    (localStorage.getItem('showWarning') as WarningType) ?? WarningType.True
  );

  if (!transitionId || !transition) {
    newError('PANEL-c2a8a', 'Panel: Transition not found', true);
  }

  useEffect(() => {
    if (!localStorage.getItem('showWarning')) {
      localStorage.setItem('showWarning', 'true');
    }
  }, []);

  const canDropItemInBlockNot = (path: string, state: DNDBlock): boolean => {
    const newState = deepCopy(state);

    const isBlockNot = (element: DNDBlock) => {
      const rootPath = path.split(':')[0];
      if (
        element.type === 'Not' &&
        element.path === rootPath &&
        path.includes('not')
      ) {
        if (element.props?.not?.length === 1) {
          return true;
        }
      }

      if (element.props) {
        const keys = Object.keys(element.props ?? {});
        for (const key of keys) {
          const propsArray = element.props[key];

          if (Array.isArray(propsArray)) {
            for (const subElement of propsArray) {
              if (isBlockNot(subElement)) {
                return true;
              }
            }
          }
        }
      }

      return false;
    };

    if (newState.props?.root && Array.isArray(newState.props?.root)) {
      for (const element of newState.props.root) {
        if (isBlockNot(element)) {
          return true;
        }
      }
    }

    return false;
  };

  const handleAlert = (path: string) => {
    const isRoot = path === 'root:root|0' || path === 'root:root|1';

    if (isRoot) {
      const rootItem = conditionDND?.state?.props?.root;

      if (Array.isArray(rootItem) && rootItem.length === 1) {
        return false;
      }
    }

    if (!conditionDND?.state) return false;

    const canDrop = canDropItemInBlockNot(path, conditionDND.state);

    if (canDrop) {
      return false;
    }

    return true;
  };

  const getAlert = useCallback((): JSX.Element | null => {
    return showWarning === WarningType.True ? (
      <WarningDnd setShowWarning={setShowWarning} />
    ) : null;
  }, [showWarning]);

  if (!conditionDND) return <></>;

  return (
    <DNDBase
      dnd={conditionDND}
      hasToolbar
      restriction={handleAlert}
      warning={getAlert()}
    />
  );
};

export default observer(ConditionDnd);
