import { ReactNode, useContext } from 'react';

import { Settings, Trash2 } from 'lucide-react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

import {  DNDMetaContext } from '@components/dnd/base';
import { TraceKeyInput } from '@components/traceKeyInput/traceKeyInput';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@components/ui/dialog';

import useStores from '@hooks/useStore';

import { AtomModel } from '@models/atom.model';

import { Checkbox } from '@mui/joy';
import { DNDBlock } from 'shared';

interface EndControlsLineProps {
  $isDisplayed: boolean;
}

const EndControlsLine = styled.div<EndControlsLineProps>`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  gap: 0.6rem;
  align-items: center;
  user-select: none;
  opacity: 1;

  ${(p) =>
    !p.$isDisplayed &&
    css`
      opacity: 0;
    `}
`;

const Control = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ICON_SIZE = 17;

export interface SettingsConfig {
  title: string;
  content: ReactNode;
}

type EndControlsProps = {
  hasDelete?: boolean;
  noDeleteCheck?: boolean;
  isHovering?: boolean;
  blockPath?: string;
  settings?: SettingsConfig;
  children?: ReactNode;
  dndBlock: DNDBlock;
} & (
  | {
      hasRequired: true;
      dataItem: AtomModel<{ required: boolean }>;
    }
  | {
      hasRequired: false;
      dataItem: AtomModel;
    }
);
const EndControlsFB = ({
  dndBlock,
  dataItem: atom,
  isHovering = true,
  hasDelete = true,
  noDeleteCheck = false,
  hasRequired,
  settings: settingsConfig,
  children
}: EndControlsProps) => {
  const { onDelete } = useContext(DNDMetaContext);

  const { modalStore } = useStores();

  const isDeletable = atom.isDeletable;

  const onDeleteClick = () => {
    if (!noDeleteCheck && !isDeletable) {
      modalStore.referenceModal.open(
        'atom',
        atom.id,
        'referencedBy',
        atom.type
      );

      return;
    }
    onDelete(dndBlock.path);
  };

  return (
    <>
      <EndControlsLine $isDisplayed={isHovering}>
        {hasRequired && (
          <Checkbox
            label="Required"
            variant="outlined"
            color="neutral"
            size="sm"
            checked={atom.data.required}
            onChange={(e) => (atom.data.required = e.target.checked)}
          />
        )}
        <Control>{children}</Control>

        {hasDelete && (
          <Control data-tag="delete-control" onClick={onDeleteClick}>
            <Trash2 size={ICON_SIZE} />
          </Control>
        )}
        {settingsConfig && (
          <Dialog>
            <DialogTrigger>
              <Settings size={ICON_SIZE} />
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Settings — {settingsConfig.title}</DialogTitle>
                <DialogDescription>
                  Manage <i className="font-semibold">{settingsConfig.title}</i>
                  's settings.
                </DialogDescription>
              </DialogHeader>
              <DialogBody>
                <section className="flex flex-col h-full gap-6">
                  {settingsConfig.content}
                  <TraceKeyInput
                    traceKey={atom.traceKey}
                    // @ts-expect-error parentName is not a required prop
                    parentName={atom.data.title as Maybe<string>}
                  />
                </section>
              </DialogBody>
            </DialogContent>
          </Dialog>
        )}
      </EndControlsLine>
    </>
  );
};

export default observer(EndControlsFB);
