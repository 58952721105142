import { ChangeEvent } from 'react';

import { X } from 'lucide-react';
import { DndKitValue } from 'shared';

import DragDots from '@/assets/svg/dragDotsSmall.svg?react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  ButtonIconContainer,
  GrabIconContainer,
  ItemBody,
  StyledInput
} from './sortableItem.style';

interface SortableItemProps {
  value: DndKitValue;
  onValueChange: (
    e: ChangeEvent<HTMLInputElement>,
    valueId: UniqueIdentifier
  ) => void;
  onValueDelete: (valueId: UniqueIdentifier) => void;
}

export const SortableItem = ({
  value,
  onValueChange,
  onValueDelete
}: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: value?.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%'
  };

  return (
    <ItemBody style={style} ref={setNodeRef} {...attributes}>
      <GrabIconContainer {...listeners}>
        <DragDots fontSize={'25px'} />
      </GrabIconContainer>
      <StyledInput
        value={value.name}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onValueChange(e, value.id)
        }
      ></StyledInput>
      <ButtonIconContainer onClick={() => onValueDelete(value.id)}>
        <X size={13} color="#A4A4A4" />
      </ButtonIconContainer>
    </ItemBody>
  );
};
