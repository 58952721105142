import { observer } from 'mobx-react';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@components/ui/card';
import { Input } from '@components/ui/input';
import { Label } from '@components/ui/label';

import { AtomModel } from '@models/atom.model';

import { ColumnElement } from '@atoms/columnElement';
import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';
import { ExempleTooltip, InfoTooltip } from '@atoms/infoTooltip';
import { SwitchV2 } from '@atoms/switch';
import { DateWidgetSchema } from 'shared';

type Props = Pick<
  DateWidgetSchema.Data,
  'deadlineWarningBuffer' | 'isDeadline' | 'doneVariableReference'
> & {
  onDeadlineWarningBufferChange: (
    deadlineWarningBuffer: DateWidgetSchema.Data['deadlineWarningBuffer']
  ) => void;
  onIsDeadlineChange: (isDeadline: DateWidgetSchema.Data['isDeadline']) => void;
  onDoneVariableReferenceChange: (
    doneVariableReference: DateWidgetSchema.Data['doneVariableReference']
  ) => void;
  atomId: AtomModel['id'];
};

export const DateWidgetSettings = observer((props: Props) => {
  const onBufferDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value) {
      props.onDeadlineWarningBufferChange(undefined);
      return;
    }

    props.onDeadlineWarningBufferChange(Number(value));
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Deadline settings</CardTitle>
        <CardDescription>
          The date can be shown in a different color if it's a deadline, and the
          deadline has either been missed or is within the buffer period.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex gap-6">
          <Column $width="220px" $gap="1rem">
            <ColumnElement>
              <SwitchV2
                id={`deadline-${props.atomId}`}
                labelText="Display a deadline"
                checked={props.isDeadline}
                onCheckedChange={props.onIsDeadlineChange}
              />
            </ColumnElement>

            <ColumnElement>
              <div className="flex items-center gap-2">
                <Label>Done condition</Label>
                <InfoTooltip
                  title="Done condition"
                  description="The selected variable will be used to determine if the task is done or not. If the variable exist, and does not equal to false, the deadline will be disabled."
                  additionalContent={
                    <ExempleTooltip
                      input="Trace Terminated: true"
                      output="No deadline"
                    />
                  }
                />
              </div>
              <VariableSelector
                disabled={!props.isDeadline}
                onSelected={props.onDoneVariableReferenceChange}
                selectedRef={props.doneVariableReference}
              />
            </ColumnElement>
          </Column>
          <Column $width="220px">
            <ColumnElement>
              <div className="flex items-center w-full gap-2">
                <Label htmlFor={`deadline-warning-buffer-${props.atomId}`}>
                  Deadline warning buffer
                </Label>
                <InfoTooltip
                  title="Deadline warning buffer"
                  description="The number of days before the deadline to display a warning message"
                />
              </div>

              <Input
                disabled={!props.isDeadline}
                id={`deadline-warning-buffer-${props.atomId}`}
                type="number"
                placeholder="0 (default)"
                className="w-full"
                value={props.deadlineWarningBuffer}
                onChange={onBufferDaysChange}
              />
            </ColumnElement>
          </Column>
        </div>
      </CardContent>
    </Card>
  );
});
