import { z } from 'zod';

import { AtomReferenceSchema } from '../../../atomReference.schema';

export const TO_DATE_VIEW_TYPES = ['Date', 'Deadline'] as const;
export const TO_DateViewTypeSchema = z.enum(TO_DATE_VIEW_TYPES);

export const TO_DateDataSchema = (_?: boolean) =>
  z
    .object({
      title: z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_DateViewTypeSchema
    })
    .strict();

export type TO_DateData = z.infer<ReturnType<typeof TO_DateDataSchema>>;
