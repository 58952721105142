import DndIllu from '@/assets/svg/dnd.svg?react';
import { DndLibrary, LIB_TO_INFO_MAP } from '@library';
import { Typography } from '@mui/joy';

import { EmptyStateRoot } from './emptyState.style';

interface EmptyStateProps {
  library: DndLibrary;
  children: JSX.Element;
}

const SETTINGS_DND = ['TraceOverview', 'TraceInfo', 'Database'];

const EmptyState = ({ library, children }: EmptyStateProps) => {
  return (
    <>
      <EmptyStateRoot $isDndSettings={SETTINGS_DND.includes(library)}>
        <DndIllu className="dnd-illustartion" />
        <Typography
          level="title-md"
          sx={{ textAlign: 'center', my: '10px', userSelect: 'none' }}
        >
          Your {LIB_TO_INFO_MAP[library].title} is empty
        </Typography>
        <Typography level="body-sm" sx={{ userSelect: 'none' }}>
          {LIB_TO_INFO_MAP[library].description}
        </Typography>
      </EmptyStateRoot>
      {children}
    </>
  );
};

export default EmptyState;
