import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import * as monaco from 'monaco-editor';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import cssWorker from 'monaco-editor/esm/vs/language/css/css.worker?worker';
import htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';
import { Toaster } from 'sonner';
import { ThemeProvider } from 'styled-components';

import './styles.css';

import store from '@/mobx/store';
import { loader } from '@monaco-editor/react';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import * as Sentry from '@sentry/react';

import Routes from './routes/general.router';
import history from './routes/history';
import HistoryRouter from './routes/history/HistoryRouter';
import theme from './styles/theme';

self.MonacoEnvironment = {
  getWorker(_, label) {
    if (label === 'json') {
      return new jsonWorker();
    }
    if (label === 'css' || label === 'scss' || label === 'less') {
      return new cssWorker();
    }
    if (label === 'html' || label === 'handlebars' || label === 'razor') {
      return new htmlWorker();
    }
    if (label === 'typescript' || label === 'javascript') {
      return new tsWorker();
    }
    return new editorWorker();
  }
};

loader.config({ monaco });

configure({
  enforceActions: 'never'
});

const muiTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          body: 'white'
        },
        primary: {
          '50': '#edf1ff',
          '100': '#dee4ff',
          '200': '#c4ccff',
          '300': '#a1abff',
          '400': '#7b7ffe',
          '500': '#635cf8',
          '600': '#533eed',
          '700': '#4b35d2',
          '800': '#3a2aa9',
          '900': '#332a85'
        }
      }
    }
  }
});

const toastOptions = {
  style: { border: 'none' }
};

const ARE_TOASTS_EXPANDING = false;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <HistoryRouter history={history}>
      <ThemeProvider theme={theme}>
        <CssVarsProvider theme={muiTheme}>
          <Provider {...store}>
            <SentryRoutes />
            <Toaster
              richColors
              position="bottom-left"
              closeButton
              expand={ARE_TOASTS_EXPANDING}
              toastOptions={toastOptions}
            />
          </Provider>
        </CssVarsProvider>
      </ThemeProvider>
    </HistoryRouter>
  );
}

export default Sentry.withProfiler(App);
