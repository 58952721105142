import form from '@/assets/svg/form.svg';
import tableEditor from '@/assets/svg/tableEditor.svg';
import tableImporter from '@/assets/svg/tableImporter.svg';

/**
 * @typedef { () => string } LabelGetter
 *
 * @typedef { {
 *   title: string | LabelGetter;
 *   actionName: string;
 *   className: string;
 *   type: string;
 *   group: string;
 * } } Option
 */

/**
 * @type {Option[]}
 */
export const EVENT = [
  {
    type: 'bpmn:StartEvent',
    group: 'event',
    actionName: 'create-start-event',
    className: 'bpmn-icon-start-event-none',
    title: 'Start Event'
  },
  {
    type: 'bpmn:EndEvent',
    group: 'event',
    actionName: 'create-node-event',
    className: 'bpmn-icon-end-event-none',
    title: 'End Event'
  }
];

/**
 * @type {Option[]}
 */
export const GATEWAY = [
  {
    title: 'Exclusive Gateway',
    actionName: 'create-exclusive-gateway',
    className: 'bpmn-icon-gateway-xor',
    type: 'bpmn:ExclusiveGateway',
    group: 'gateway'
  },
  {
    title: 'Parallel Gateway',
    actionName: 'create-parallel-gateway',
    className: 'bpmn-icon-gateway-parallel',
    type: 'bpmn:ParallelGateway',
    group: 'gateway'
  },
  {
    title: 'Inclusive gateway',
    actionName: 'create-inclusive-gateway',
    className: 'bpmn-icon-gateway-or',
    type: 'bpmn:InclusiveGateway',
    group: 'gateway'
  }
];

/**
 * @type {Option[]}
 */
export const TASK = [
  {
    title: 'Form',
    actionName: 'create-form',
    type: 'trace:Form',
    group: 'task',
    icon: form
  },
  {
    title: 'Table Editor',
    actionName: 'create-table-editor',
    type: 'trace:TableEditor',
    group: 'task',
    icon: tableEditor
  },
  {
    title: 'Table Importer',
    actionName: 'create-table-importer',
    type: 'trace:TableImporter',
    group: 'task',
    icon: tableImporter
  }
];

/**
 * @type {Option[]}
 */
export const SEQUENCE_FLOW = [
  {
    title: 'Sequence Flow',
    actionName: 'replace-with-sequence-flow',
    className: 'bpmn-icon-connection'
  },
  {
    title: 'Default Flow',
    actionName: 'replace-with-default-flow',
    className: 'bpmn-icon-default-flow'
  },
  {
    title: 'Conditional Flow',
    actionName: 'replace-with-conditional-flow',
    className: 'bpmn-icon-conditional-flow'
  }
];

/**
 * @type {Option[]}
 */
export const PARTICIPANT = [
  {
    title: 'Expanded Pool',
    actionName: 'replace-with-expanded-pool',
    className: 'bpmn-icon-participant',
    type: 'bpmn:Participant',
    isExpanded: true
  },
  {
    title: function (element) {
      let label = 'Empty Pool';

      if (element.children && element.children.length) {
        label += ' (removes content)';
      }

      return label;
    },
    actionName: 'replace-with-collapsed-pool',
    className: 'bpmn-icon-lane',
    type: 'bpmn:Participant',
    isExpanded: false
  }
];
