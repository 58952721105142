import { useState } from 'react';

import { Check, ChevronDown } from 'lucide-react';

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList
} from '@components/ui/command';
import { Input } from '@components/ui/input';
import { Label } from '@components/ui/label';

import { cn } from '@/lib/utils';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@radix-ui/react-popover';
import { Separator } from '@radix-ui/react-separator';
import { TraceWidth, WidthList, WidthToInfoMap } from 'shared';

type Props = {
  selectedWidth: TraceWidth;
  onWidthChange: (width: TraceWidth) => void;
  /** Prevents the input id from collision. */
  id?: string;
  label?: string;
};
export const TraceWidthSelector = ({
  selectedWidth,
  onWidthChange,
  label = 'Column width',
  id
}: Props) => {
  const [open, setOpen] = useState(false);

  const onCustomWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const customWidth = Number(rawValue);
    if (rawValue === '' || Number.isNaN(customWidth) || customWidth < 0) {
      onWidthChange('medium');
      return;
    }
    onWidthChange(customWidth);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div className="grid w-full max-w-sm items-center gap-1.5 select-none cursor-pointer">
          <Label>{label}</Label>
          <div className="flex h-8 rounded-md border border-solid hover:bg border-input bg-background px-3 py-2 text-sm ring-offset-background justify-between items-center w-[220px] font-normal hover:bg-accent hover:text-accent-foreground">
            {typeof selectedWidth === 'string'
              ? WidthToInfoMap[selectedWidth]?.name ?? selectedWidth
              : `${selectedWidth.toString()}px`}
            <ChevronDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[220px] p-0 z-50">
        <Command loop>
          <CommandList>
            <CommandGroup heading="Predefined widths">
              {WidthList.map((widthItem) => {
                const { name: widthName, pixel: widthPixel } =
                  WidthToInfoMap[widthItem];
                return (
                  <CommandItem
                    key={widthItem}
                    value={widthItem}
                    onSelect={(currentValue) => {
                      onWidthChange(currentValue as TraceWidth);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedWidth === widthItem
                          ? 'opacity-100'
                          : 'opacity-0'
                      )}
                    />
                    <div className="flex items-center gap-3">
                      {widthName}
                      <div className="px-2 text-xs bg-gray-100 border border-gray-300 border-solid rounded-sm">
                        {widthPixel}
                      </div>
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            <Separator />
            <CommandGroup heading="Custom width (px)">
              <CommandItem className="pointer-events-none">
                <div className="grid w-full max-w-sm items-center gap-1.5">
                  <Input
                    type="number"
                    className="w-full pointer-events-auto"
                    placeholder="Ex: 210"
                    autoFocus
                    id={id ? `custom-width-${id}` : 'custom-width'}
                    step={1}
                    min={0}
                    value={
                      typeof selectedWidth === 'number' ? selectedWidth : ''
                    }
                    onChange={onCustomWidthChange}
                  />
                </div>
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
