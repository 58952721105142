import { TableSchema } from 'shared';
import { TraceTableInitialData } from './table.data';
import { TableSettings } from './table.settings';

export namespace TraceTable {
  export import Schema = TableSchema;
  export const Settings = TableSettings;

  export type SettingsData = TableSchema.SettingsData;
  export import InitialData = TraceTableInitialData;
}
