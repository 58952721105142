import GatewayModel from '@models/gateway.model';

import {
  GatewayLoaded,
  GatewaySchema,
  GatewayType
} from 'shared'

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { parseWithZod } from '@/utils/parseZodSchema';

export default class GatewayStore extends BaseStore<GatewayModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, GatewayModel, 'gateway');
    this.store_ready = true;
  }

  public async createGateway(
    bpmnGatewayId: string,
    gatewayType: GatewayType,
    workflowId: string
  ) {
    const dto = {
      bpmnId: bpmnGatewayId,
      type: gatewayType,
      workflowId
    };

    const rawResponse = await this.httpWrapper.post('/', dto);
    if (!rawResponse) {
      newError(
        'GATEW-lyoUs',
        `Error while creating new gateway with dto: 
      ${JSON.stringify(dto)}`,
        true,
        {
          customMessage: 'Error while creating new gateway'
        }
      );
      // TODO delete the created action in the BPMN schema
      return;
    }

    const newGatewayData = parseWithZod(
      GatewaySchema,
      rawResponse,
      'GATEW-08blJ'
    );
    if (!newGatewayData) {
      newError('GATEW-dwsrg', 'Gateway was not created, it is an error', true);
      return;
    }
    return this.addGatewayToStore(newGatewayData);
  }

  public addGatewayToStore(gateway: GatewayLoaded) {
    const gatewayModel = new GatewayModel(this, {
      id: gateway.id
    });

    this.set(gatewayModel.id, gatewayModel);

    return gatewayModel;
  }

  public async deleteById(id: string) {
    const gateway = this.get(id);
    if (!gateway) {
      newError('GATEW-j3M8d', 'Gateway not found ? Cannot delete it', true);
      return false;
    }

    return await gateway.delete();
  }
}
