import { z } from 'zod';

const EventTypeSchema = z.enum(['START', 'END', 'LINK', 'CREATE_LINK']);
export const EventSchema = z.object({
  type: EventTypeSchema,
  bpmn_id: z.string(),
  id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable().optional()
});

export type EventType = z.infer<typeof EventTypeSchema>;
export type EventLoaded = z.infer<typeof EventSchema>;
