import { useContext } from 'react';

import { Trash2 } from 'lucide-react';
import { observer } from 'mobx-react';
import { useDragLayer, useDrop } from 'react-dnd';

import { DNDMetaContext } from '@components/dnd/base';

import { Item } from '@models/dnd.model';

import { DndLibrary, getToolbarItemsByLibrary } from '@library';

import { DNDToolbarItem } from './ToolbarItem';
import { DeleteZone, ToolbarContainer } from './toolbar.style';
import { ALL_BLOCKS } from 'shared'; 

interface ToolbarProps {
  library: DndLibrary;
}

const DNDToolbar = ({ library }: ToolbarProps) => {
  const items = getToolbarItemsByLibrary(library);
  const { onDelete } = useContext(DNDMetaContext);

  const isDeletableItem = (item: Item): boolean => {
    return !!item.path && item.path !== 'toolbar';
  };

  const [, drop] = useDrop({
    // Lot of `as` because of a skill issue from react-dnd, we are too typed for them 🤷
    accept: ALL_BLOCKS as unknown as string[], 
    canDrop(item: Item) {
      return isDeletableItem(item);
    },
    drop: (item: Item) => {
      onDelete(item.path);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.canDrop()
    })
  });

  const { isDraggingDeletableItem } = useDragLayer((monitor) => ({
    isDraggingDeletableItem:
      monitor.isDragging() && isDeletableItem(monitor.getItem<Item>())
  }));

  return (
    <ToolbarContainer id="toolbar" ref={drop}>
      {!isDraggingDeletableItem ? (
        items.map((item, index) => <DNDToolbarItem key={index} {...item} />)
      ) : (
        <DeleteZone>
          <Trash2 size={23} />
        </DeleteZone>
      )}
    </ToolbarContainer>
  );
};

export default observer(DNDToolbar);
