import styled from 'styled-components';

export const ExportContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1;
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  outline-color: #e5e7eb;
  outline-width: 1px;
  outline-style: solid;
  &:hover {
    color: initial;
    background: ${({ theme }) => theme.color.grey[7]};
    cursor: pointer;
  }
`;
