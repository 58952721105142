import { z } from 'zod';

import { TitleInputSchema } from '../../../../other/otherUtils';

export const TI_WindowDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      isCollapsable: z.boolean(),
      value: z.string() //! not sure if this is needed
    })
    .strict();

export type TI_WindowData = z.infer<ReturnType<typeof TI_WindowDataSchema>>;
