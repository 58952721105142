import { z } from 'zod';

export * from './dnds';
export * from './database';

export const AtomSourceSchema = z.enum([
  'ui',
  'condition',
  'notification',
  'database',
  'traceInfo',
  'traceOverview',
  'template',
  'globalVariables',
  'variables',
  'transition',
  'form_action',
  'atom',
  'table_editor',
  'table_importer'
]);

export type AtomSource = z.infer<typeof AtomSourceSchema>;
