import { makeObservable, observable } from 'mobx';
import { Class } from 'utility-types';

import Model from '@models/base/base.model';

import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { HTTPWrapper } from '@/utils/http';
import nanoID from '@/utils/nanoID';

export default class BaseStore<GenericModel extends Model> {
  rootStore: RootStore;
  storeURL: string;
  httpWrapper: HTTPWrapper<GenericModel>;
  modelClass: Class<GenericModel>;
  override_name?: string;
  api_enabled = true;
  store_ready = true;

  data: Map<string, GenericModel>;

  constructor(
    rootStore: RootStore,
    model: Class<GenericModel>,
    override_store_url?: string
  ) {
    this.rootStore = rootStore;
    this.modelClass = model;
    this.override_name = override_store_url ?? 'no override_store_url';

    this.storeURL = this.override_name
      ? this.override_name
      : this.modelClass.name.toLowerCase();
    this.httpWrapper = new HTTPWrapper<GenericModel>(this.storeURL);

    this.data = new Map();

    makeObservable(this, {
      data: observable
    });
  }

  public toIter(): IterableIterator<GenericModel> {
    return this.data.values();
  }

  /** Returns all instances of the model in the store as an array.
   * @example const allTransitions: TransitionModel[] = transitionStore.getAll();
   */
  public toArray(): GenericModel[] {
    return Array.from(this.data.values());
  }

  /** Updates an element in backend and in the Mobx store using its `id`. */
  public async update(id: string): Promise<unknown> {
    if (!this.api_enabled || !this.store_ready) {
      throw new Error(`Store ${this.modelClass.name} not ready`);
    }

    const el = this.get(id);
    if (!el)
      throw new Error(`Element ${id} not found in store ${this.storeURL}`);

    const data = el.toJSON as unknown;

    try {
      return await this.httpWrapper.patch(`/${id}`, data);
    } catch (error: unknown) {
      newError('BASE-cabff', error, true, {
        customMessage: `Error while updating ${this.override_name}`,
        description: `Update for "${id}" of "${this.storeURL}" failed`
      });
      throw new Error(`Update for "${id}" of "${this.storeURL}" failed`);
    }
  }

  /** Creates a new element in the store. */
  public create(): GenericModel {
    const el = new this.modelClass(this);
    // console.debug(`[${this.override_name!}]: creating an element`);
    // TODO: p-20 do it in the back ?
    el.id = this.generateId();

    this.set(el.id, el);

    return el;
  }

  // setting an element in the state can
  // create it in the back end (with a POST)
  // update it in (with a PUT)
  public set(
    id: GenericModel['id'],
    el: GenericModel
  ): Map<string, GenericModel> {
    return this._setInStore(id, el);
  }

  /** Returns a element from the store by `id`. */
  public get(id: Maybe<GenericModel['id']>): Maybe<GenericModel> {
    if (!id) {
      console.warn(
        'Store `get` called with an undefined model ID in',
        this.modelClass.name
      );
      return undefined;
    }
    return this.data.get(id);
  }

  // when we delete, we wait for the back to have acknowledge the DELETE
  // before deleting in the front

  public async delete(
    id: Maybe<GenericModel['id']>,
    inApi: boolean = true
  ): Promise<boolean> {
    if (!id) {
      newError(
        'BASE-76c77',
        `Error while deleting ${this.override_name}: delete called with an undefined model ID in,
      ${this.modelClass.name}`,
        false,
        {
          errorType: 'warning'
        }
      );
      return false;
    }
    if (inApi) {
      try {
        await this.httpWrapper.delete(`/${id}`);
      } catch (error: unknown) {
        newError('BASE-4f9a7', error, true, {
          customMessage: `Error while deleting store ${this.override_name}`,
          description: `Delete for ${id} of ${this.storeURL} failed`
        });
        return false;
      }
    }
    return this._deleteInStore(id);
  }

  /* ----------------------------- Private methods ---------------------------- */
  /* ---------------- Only related to Mobx and store internals ---------------- */

  /**  Sets data in the store */
  private _setInStore(id: string, el: GenericModel): Map<string, GenericModel> {
    return this.data.set(id, el);
  }

  /** Gets the data from the store. */
  private _getInStore(id: string): Maybe<GenericModel> {
    return this.data.get(id);
  }

  /**  Deletes an element from the store. */
  private _deleteInStore(id: string): boolean {
    return this.data.delete(id);
  }

  /** Generates a new ID using the custom `nanoId(8)` function. */
  private generateId(): string {
    return nanoID(7);
  }
}
