import { observer } from 'mobx-react';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { TraceTable } from '@components/trace/table';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@components/ui/card';
import { Input } from '@components/ui/input';
import { Label } from '@components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/ui/select';
import { Separator } from '@components/ui/separator';

import useAction from '@hooks/useAction';

import { TableImporterActionModel } from '@models/action/tableImporter.model';

import { encodingOptions } from 'shared'

import { ColumnElement } from '@atoms/columnElement';
import { SwitchV2 } from '@atoms/switch';

import { SettingsSharedTab } from '@panel/components/action/shared/tabs';

export const TableImporterSettingsTab = observer(() => {
  const action = useAction();
  if (!(action instanceof TableImporterActionModel)) return <></>;

  return (
    <SettingsSharedTab>
      <Card>
        <CardHeader>
          <CardTitle>Global parsing options</CardTitle>
          <CardDescription>
            Those settings while apply to all columns, and can be overwritten by
            column specific parsing options.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-wrap gap-6">
            <Column $width="220px">
              <ColumnElement>
                <Label htmlFor={`encoding-${action.id}`}>Encoding</Label>
                <Select
                  onValueChange={(value) =>
                    (action.metadata.settings.parsing.encoding =
                      value as (typeof encodingOptions)[number])
                  }
                  value={action.metadata.settings.parsing.encoding}
                >
                  <SelectTrigger id={`encoding-${action.id}`}>
                    <SelectValue
                      placeholder={action.metadata.settings.parsing.encoding}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {encodingOptions.map((encodingOption) => (
                        <SelectItem value={encodingOption} key={encodingOption}>
                          {encodingOption}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </ColumnElement>
            </Column>
            <Column $width="220px">
              <ColumnElement>
                <Label htmlFor={`delimiter-${action.id}`}>Delimiter</Label>
                <Input
                  id={`delimiter-${action.id}`}
                  type="text"
                  value={action.metadata.settings.parsing.csvParser.delimiter}
                  onChange={(e) =>
                    (action.metadata.settings.parsing.csvParser.delimiter =
                      e.target.value)
                  }
                  placeholder='Ex: ";"'
                />
              </ColumnElement>
            </Column>
          </div>
        </CardContent>
      </Card>
      <Separator />
      <TraceTable.Settings
        sourceId={action.id}
        showEmptyTable={action.metadata.settings.showEmptyTable}
        onShowEmptyTableChange={(v) =>
          (action.metadata.settings.showEmptyTable = v)
        }
        allowColumnsSelection={action.metadata.settings.allowColumnsSelection}
        dataSelectorPathReference={
          action.metadata.settings.dataSelectorPathReference
        }
        onDataSelectorPathReferenceSelected={(ref) =>
          (action.metadata.settings.dataSelectorPathReference = ref)
        }
        onAllowColumnsSelection={(value) =>
          (action.metadata.settings.allowColumnsSelection = value)
        }
      >
        <Column $width="220px">
          <ColumnElement>
            <SwitchV2
              id={`add-file-${action.id}`}
              labelText="Save imported file"
              checked={action.metadata.settings.addFile}
              onCheckedChange={(checked) =>
                (action.metadata.settings.addFile = checked)
              }
            />
          </ColumnElement>
          <ColumnElement>
            <SwitchV2
              id={`enable-comments-${action.id}`}
              labelText="Enable comment"
              checked={action.metadata.settings.addComments}
              onCheckedChange={(checked) =>
                (action.metadata.settings.addComments = checked)
              }
            />
          </ColumnElement>
        </Column>
      </TraceTable.Settings>
    </SettingsSharedTab>
  );
});
