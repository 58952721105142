import { Variable } from 'lucide-react';
import { observer } from 'mobx-react';

import DNDBase from '@components/dnd/base';

import SettingHeader from '@pages/Settings/SettingHeader';
import SettingHelper from '@pages/Settings/SettingHelper';
import { SettingsContent } from '@pages/Settings/settings.style';
import { SettingBody } from '@pages/Settings/style';

import useProcess from '@hooks/useProcess';

import ActionButton from '@atoms/button';

const GlobalVariables = () => {
  const { process } = useProcess();

  const globalVariablesDnd = process?.workflows[0]?.globalVariablesDnd;

  if (!process || !globalVariablesDnd) return <></>;

  const onAddVariable = () => {
    const window = globalVariablesDnd.createDndBlock(
      'GlobalVariable',
      'unrelevant'
    );

    const dropZonePath = 'root:root|0';
    const newState = globalVariablesDnd.insertBlockInState(
      dropZonePath,
      globalVariablesDnd.state,
      window
    );

    globalVariablesDnd.setState(newState);
  };

  return (
    <SettingsContent>
      <SettingHeader
        title="Global Variables"
        description="Customize the big summary table where all trace are referenced"
      />

      <SettingHelper
        title="Global Variables"
        icon={<Variable />}
        description="The Trace overview is the table you see when you open your workflow in Trace. It shows all the Traces in your workflow and gives you an overview of their state. Only use the most relevant variables here. You can change what columns should be displayed by adding widgets."
      />

      {process.loading && <></>}
      {!process.loading && (
        <SettingBody>
          <div className="title-body">
            <h3>Your global variables</h3>
            <ActionButton value="Add a variable" onClick={onAddVariable} />
          </div>
          <DNDBase dnd={globalVariablesDnd} />
        </SettingBody>
      )}
    </SettingsContent>
  );
};

export default observer(GlobalVariables);
