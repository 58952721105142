import { ColDef } from 'ag-grid-community';
import { makeObservable, observable, reaction, toJS } from 'mobx';
import { AtomReference } from 'shared/src/atom/atomReference.schema';
import { RowData } from 'shared/src/atom/sources/database/row.atom';
import {
  Column,
  Columns,
  ValueSetters
} from 'shared/src/database/database.schema';
import { TraceKey } from 'shared/src/other/traceKey.schema';

import { AgGridMultiSelectEditor } from '@pages/Database/grid/customEditors/multi-select.editor';
import { BadgeRenderer } from '@pages/Database/grid/customRenderers/badge';

import DatabaseStore from '@stores/database.store';

import { newError } from '@/services/errors/errors';
import { ValueSetterToFunctionMap } from '@/types/database.types';

import { AtomModel } from './atom.model';
import { ModelError } from './base/base.model';
import { BaseModelWithTraceKey } from './base/baseWithKey.model';

export class DatabaseModel extends BaseModelWithTraceKey {
  rowAtoms: AtomModel<RowData>[] = [];

  constructor(
    store: DatabaseStore,
    id: string,
    private traceKeyDTO: TraceKey,
    private name: string,
    private columns: Columns,
    private rowReferences: AtomReference[],
    private processId: string,
    private created_at: string,
    private updated_at: string,
    private deleted_at: Nullable<string>
  ) {
    const isLoading = false;
    super(store, id, traceKeyDTO, isLoading);

    makeObservable<DatabaseModel, 'name' | 'columns' | 'rowReferences'>(this, {
      name: observable,
      columns: observable,
      rowReferences: observable
    });

    reaction(
      () => this.name,
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError('DBMD-wmtOk', error, true);
        });
      }
    );

    reaction(
      () => toJS(this.rowReferences),
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError('DBMD-N826U', error, true);
        });
      }
    );

    reaction(
      () => toJS(this.columns),
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError('DBMD-wUqBU', error, true);
        });
      }
    );
  }

  /* ------------------------ Class properties getters ------------------------ */
  public get getTraceKey(): TraceKey {
    return this.traceKeyDTO;
  }

  public get getProcessId(): string {
    return this.processId;
  }

  public get getRowReferences(): AtomReference[] {
    return this.rowReferences;
  }

  public get getColumns(): Columns {
    return this.columns;
  }

  public get getName(): string {
    return this.name;
  }

  public get getUpdatedAt(): string {
    return this.updated_at;
  }

  public get getDeletedAt(): Nullable<string> {
    return this.deleted_at;
  }

  public get getCreatedAt(): string {
    return this.created_at;
  }

  /* ----------------------------- Custom getters ----------------------------- */
  public getPrimaryKeyColumn(): Column | undefined {
    const primaryKeyColumn = this.columns.find(
      (column) => column.context != undefined && column.context.isPrimaryKey
    );

    if (!primaryKeyColumn) {
      newError(
        'DBMD-gcUWX',
        `Primary key column not found in database: ${this.id}, 
        will not render the grid`
      );
      return;
    }

    return primaryKeyColumn;
  }

  public getGridColumnDefinitions(): ColDef[] {
    const columnsDefintions: ColDef[] = [];

    const primaryKeyColumn = this.getPrimaryKeyColumn();

    if (!primaryKeyColumn) {
      return columnsDefintions;
    }

    for (const column of this.columns) {
      const baseColumnDefinition: ColDef = {
        field: column.field,
        cellDataType: column.cellDataType,
        context: column.context
      };

      const isColumnReferenceToOtherDTR =
        column.context?.reference !== undefined;
      const isPrimaryKey = primaryKeyColumn.field === column.field;

      if (isPrimaryKey && isColumnReferenceToOtherDTR) {
        newError(
          'DBMD-CWDDH',
          `Primary key column cannot be a reference in database: 
          ${this.id}, this column will not be rendered`
        );
        continue;
      }

      if (isPrimaryKey && !isColumnReferenceToOtherDTR) {
        columnsDefintions.push({
          ...baseColumnDefinition,
          valueSetter: ValueSetterToFunctionMap[ValueSetters.NON_EMPTY_STRING]
        });
        continue;
      }

      if (!isColumnReferenceToOtherDTR) {
        columnsDefintions.push({
          ...baseColumnDefinition
        });
        continue;
      }

      const databaseReferenced = this.store.get(
        column.context?.reference?.databaseId
      );

      if (!databaseReferenced) {
        newError(
          'DBMD-hk_I6',
          `Database referenced by 
            column ${column.field} in DTR ${this.id} not found in store, 
            column will not be rendered`
        );
        continue;
      }

      columnsDefintions.push({
        ...baseColumnDefinition,
        cellRenderer: BadgeRenderer,
        cellEditor: AgGridMultiSelectEditor,
        cellEditorParams: {
          databaseReferenced
        }
      });
    }

    return columnsDefintions;
  }

  public getRowAtoms(): AtomModel<RowData>[] {
    const rowAtoms: AtomModel<RowData>[] = [];

    for (const rowReference of this.rowReferences) {
      const rowAtom = this.store.rootStore.atomStore.getAtomById<RowData>(
        rowReference.dataItemId,
        'Row'
      );

      if (!rowAtom || rowAtom instanceof Error) continue;

      rowAtoms.push(rowAtom);
    }

    return rowAtoms;
  }

  /* ----------------------------- Public setters ----------------------------- */
  public addRowReference(newRowReference: AtomReference): boolean {
    const refAtomIds = this.rowReferences.map((ref) => ref.dataItemId);

    if (refAtomIds.includes(newRowReference.dataItemId)) {
      newError(
        'DBMD-Nje8E',
        `Duplicate row reference found while adding a new one: ${newRowReference.dataItemId}`
      );
      return false;
    }

    const matchedAtom = this.store.rootStore.atomStore.getAtomById<RowData>(
      newRowReference.dataItemId,
      'Row'
    );

    if (!matchedAtom || matchedAtom instanceof Error) {
      newError(
        'DBMD-Iyy29',
        `Atom ${newRowReference.dataItemId} not found in atom store while adding a new row reference. Reference will not be added.`
      );
      return false;
    }

    this.rowReferences.push(newRowReference);

    return true;
  }

  public removeRowReference(rowAtomIdToRemove: string): boolean {
    const matchedRef = this.rowReferences.find(
      (rowRef) => rowRef.dataItemId == rowAtomIdToRemove
    );

    if (!matchedRef) {
      newError(
        'DBMD-wmtOk',
        `Row reference not found while removing one: ${rowAtomIdToRemove}`
      );
      return false;
    }

    const matchedRowAtom = this.store.rootStore.atomStore.getAtomById<RowData>(
      matchedRef.dataItemId,
      'Row'
    );

    if (matchedRowAtom && !(matchedRowAtom instanceof Error)) {
      newError(
        'DBMD-h0_Hf',
        `Row atom ${matchedRef.dataItemId} is still existing in atom store while removing the reference, this will create a ghost atom row, atom will be removed.`
      );

      const isMatchedAtomDeleted = this.store.rootStore.atomStore.deleteAtom(
        matchedRef.dataItemId
      );

      if (!isMatchedAtomDeleted) {
        newError(
          'DBMD-jZyVu',
          `Error while deleting the matched atom with id ${matchedRef.dataItemId} while removing the reference. You might fix this manually.`
        );
        return false;
      }
    }
    this.rowReferences = this.rowReferences.filter(
      (ref) => ref.dataItemId !== matchedRef.dataItemId
    );

    return true;
  }

  /* ------------------------- Abstract class methods ------------------------- */
  get toJSON() {
    return {
      name: this.name,
      traceKey: this.traceKeyDTO,
      columnDefinitions: this.columns,
      rowReferences: this.rowReferences,
      processId: this.processId,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      deletedAt: this.deleted_at
    };
  }

  get isDeletable(): boolean {
    return this.getRowAtoms().every((rowAtom) => rowAtom.isDeletable);
  }

  get errors(): ModelError[] {
    return [];
  }
}
