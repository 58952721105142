import { z } from "zod";

import { BotMetadata, CreateBotDTO } from "./bot.schema";
import { CreateESignatureDTO, ESignatureMetadata } from "./eSignature.schema";
import { CreateFormDTO, FormMetadata, FormSchema } from "./form.schema";
import {
  CreateTableEditorDTO,
  TableEditorMetadata,
  TableEditorSchema,
} from "./tableEditor.schema";
import {
  CreateTableImporterDTO,
  TableImporterMetadata,
  TableImporterSchema,
} from "./tableImporter.schema";
import { ActionType } from "./types";

export const ActionSchema = z.union([
  FormSchema,
  TableImporterSchema,
  TableEditorSchema,
]);

export const CreateActionDTOSchema = z.union([
  CreateFormDTO,
  CreateTableImporterDTO,
  CreateTableEditorDTO,
  CreateBotDTO,
  CreateESignatureDTO,
]);

export type ActionMetadata =
  | FormMetadata
  | TableImporterMetadata
  | TableEditorMetadata
  | BotMetadata
  | ESignatureMetadata;

export type Action = z.infer<typeof ActionSchema>;
export type CreateActionDTO = z.infer<typeof CreateActionDTOSchema>;

const BpmnTaskSchema = z.enum([
  "trace:Form",
  "trace:TableEditor",
  "trace:TableImporter",
  "trace:ESignature",
]);
type BpmnTask = z.infer<typeof BpmnTaskSchema>;

export const BPMNTaskToActionType = {
  "trace:Form": "FORM",
  "trace:TableEditor": "TABLE_EDITOR",
  "trace:TableImporter": "TABLE_IMPORTER",
  "trace:ESignature": "E_SIGNATURE",
} as const satisfies Record<BpmnTask, ActionType>;

export const isBpmnTask = (task: unknown): task is BpmnTask => {
  return BpmnTaskSchema.safeParse(task).success;
};
