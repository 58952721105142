import { AtomModel } from '@models/atom.model';
import { TableEditorLabelsColumnData } from 'shared';

type Props = {
  atom: AtomModel<TableEditorLabelsColumnData>;
};

export const LabelsSettingsModal = ({ atom }: Props) => {
  atom;
  return <></>;
};
