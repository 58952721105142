import { observer } from 'mobx-react';
import { ColumnSchema } from 'shared/src/trace/table/column.schema';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@components/ui/card';
import { Input } from '@components/ui/input';
import { Label } from '@components/ui/label';

import { ImporterOptions } from '@library/tableImporter/sharedSchema/importer.schema';

type Props = {
  from: ImporterOptions['importerOptions']['from'];
  onFromChange: (from: ImporterOptions['importerOptions']['from']) => void;
  columnName: ColumnSchema.Data['title'];
};

export const ColumnImporterSettings = observer((props: Props) => {
  const onFromInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onFromChange(e.target.value);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Additional CSV importer settings</CardTitle>
        <CardDescription>Additional CSV column settings</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-6">
          <Column $width="240px">
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="from">Original CSV column name</Label>
              <Input
                id="from"
                placeholder="Aa"
                value={props.from}
                onChange={onFromInputChange}
              />
            </div>
          </Column>
        </div>
      </CardContent>
    </Card>
  );
});
