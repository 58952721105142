import { TabLink } from '@panel/PanelHeader/panelHeader.types';
import {
  CustomCodePage,
  SettingsSharedTab
} from '@panel/components/action/shared/tabs';

import { FormBuilder } from './tabs/ui.tab.form';

export const enum ActionPath {
  Form = 'form',
  Code = 'code',
  Settings = 'settings'
}

export const ACTION_TAB_LINKS: TabLink[] = [
  {
    content: 'Form Builder',
    path: ActionPath.Form
  },
  {
    content: 'Code',
    path: ActionPath.Code
  },
  {
    content: 'Settings',
    path: ActionPath.Settings
  }
];

export const FORM_ACTION_PATHS_TO_FC_MAP: Record<string, React.ReactNode> = {
  [ActionPath.Form]: <FormBuilder />,
  [ActionPath.Code]: <CustomCodePage />,
  [ActionPath.Settings]: <SettingsSharedTab />
};
