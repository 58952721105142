import { z } from "zod";

import { BaseActionSchema, BaseCreateActionDTO } from "./shared.action.schema";
import { SettingsSchema } from "./generic-table.schema";
import { AtomReferenceSchema } from "../other/state-menu.schema";
import { ActionType } from "./types";

const TableEditorMetadataSchema = z.object({
  name: z.string(),
  settings: SettingsSchema.extend({
    dataPathRef: AtomReferenceSchema.optional(),
    addInitialData: z.boolean(),
    initialDataKey: z.string(),
    modifiedDataKey: z.string(),
    addComments: z.boolean(),
    commentKey: z.string(),
  }),
});

export const TableEditorDTOSchema = z.object({
  type: z.literal("TABLE_EDITOR") satisfies z.ZodSchema<ActionType>,
  metadata: TableEditorMetadataSchema,
});

export const TableEditorSchema = BaseActionSchema.merge(TableEditorDTOSchema);

export const CreateTableEditorDTO =
  BaseCreateActionDTO.merge(TableEditorDTOSchema);

export type TableEditorActionDTO = z.infer<typeof TableEditorDTOSchema>;
export type TableEditorAction = z.infer<typeof TableEditorSchema>;
export type TableEditorMetadata = z.infer<typeof TableEditorMetadataSchema>;
