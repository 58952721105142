import { z } from "zod";

const ActionTypeSchema = z.enum([
  "FORM",
  "TABLE_IMPORTER",
  "TABLE_EDITOR",
  "E_SIGNATURE",
  "BOT",
]);
export type ActionType = z.infer<typeof ActionTypeSchema>;
