import { TableEditorTextColumnData } from 'shared';

const DEFAULT_HEADER = 'Default Text Column Header';
export const initialTextData: TableEditorTextColumnData = {
  title: DEFAULT_HEADER,
  width: 'medium',
  isfixedColumn: false,
  isDisplayedByDefault: true,
  required: true,
  cell: {
    view: {
      type: 'text',
      default: undefined,
      isRichText: false
    }
  },
  isEditMode: false,
  editorOptions: {
    selectedEditor: 'input',
    editors: {
      input: {
        type: 'input',
        placeholder: 'Enter a text',
        disabledPath: undefined,
        delay: undefined
      },
      comment: {
        type: 'comment',
        placeholder: 'Add a comment',
        disabledPath: undefined,
        delay: undefined
      }
    }
  }
};
