import { TableImporterBooleanColumnData } from "shared";

const DEFAULT_HEADER = 'Default Boolean Column Header';
export const initialBooleanData: TableImporterBooleanColumnData = {
  title: DEFAULT_HEADER,
  width: 'small',
  isfixedColumn: false,
  required: true,
  cell: {
    view: {
      type: 'boolean'
    }
  },
  importerOptions: {
    from: DEFAULT_HEADER
  }, 
  isDisplayedByDefault: true
};
