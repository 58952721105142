import { AtomModel } from '@models/atom.model';
import { MultiSelectTableEditorColumnData } from 'shared';

type Props = {
  atom: AtomModel<MultiSelectTableEditorColumnData>;
};

export const MultiSelectSettingsModal = ({ atom }: Props) => {
  atom;
  return <></>;
};
