import { z } from "zod";
import { GlobalVariableDataSchema } from "./globalVariable.atom";

export const GlobalVariablesSchemas = [
    z.object({
        type: z.literal("GlobalVariable"),
        data: GlobalVariableDataSchema(),
    }),
] as const;

export const GLOBAL_VARIABLE_TYPES = GlobalVariablesSchemas.map(schema => schema.shape.type.value);

export type GlobalVariablesBlocks = typeof GlobalVariablesSchemas[number]["shape"]["type"]["value"];
