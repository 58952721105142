import { useEffect } from 'react';

import { Ampersands, LucideIcon } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { InteractionZone } from '@components/dnd/base/interaction';

import { useAtom } from '@hooks/useAtom';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB from '@library/formBuilder/endControls';
import { FormLabel } from '@mui/joy';
import { DNDBlock } from 'shared';

export const AndIcon: LucideIcon = Ampersands;

const And = (dndBlock: DNDBlock) => {
  const dndPath = `${dndBlock.path}:and`;
  const andBlock: DNDBlock[] = dndBlock.props?.and || [];
  const transitionId = useParams()[ParamsList.TransitionId] as string;

  const atom = useAtom({ 
    type: 'And',
    initialData: {},
    sourceId: dndBlock.atomId,
    parentKind: 'condition',
    parentId: transitionId
  });

  // Add reference between parent and child
  useEffect(() => {
    if (!atom) return;
    andBlock.forEach((el) => {
      if (el.atomId === dndBlock.atomId) return;
      atom.addReferencingAtom(el.atomId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atom, dndBlock]);

  if (!atom) return <></>;

  return (
    <BlockBase
      dndBlock={dndBlock}
      hasTitle={false}
      icon={AndIcon}
      isStatement
      endControls={
        <EndControlsFB
          dataItem={atom}
          hasRequired={false}
          dndBlock={dndBlock}
        />
      }
    >
      <Column>
        <FormLabel sx={{ fontSize: '12px' }}>All conditions</FormLabel>
      </Column>
      <Column $width="100%">
        <InteractionZone path={dndPath} dndBlock={andBlock} />
      </Column>
    </BlockBase>
  );
};

export default observer(And);
