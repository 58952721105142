import { z } from 'zod';

import { TitleInputSchema } from '../../../../other/otherUtils';
import { DataSelectorSchema } from '../../../dataSelector';
import { PrefillDataSchema } from './prefill.schema';

export const DropDownDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean()
    })
    .merge(DataSelectorSchema)
    .merge(PrefillDataSchema)
    .strict();

export type DropDownData = z.infer<ReturnType<typeof DropDownDataSchema>>;
