import { Separator } from '@components/ui/separator';

import { AtomModel } from '@models/atom.model';
import { TableEditorNumberColumnData } from 'shared';

import { Widgets } from '@widgets';

type Props = {
  atom: AtomModel<TableEditorNumberColumnData>;
};

export const NumberSettingsModal = ({ atom }: Props) => {
  return (
    <>
      {atom.data.isEditMode ? (
        // We use the number widget settings here
        // because editor and widget's settings are the same
        <Widgets.Number.Settings
          settingsTitle="Number editor format settings"
          atomId={atom.id}
          options={atom.data.editorOptions.editors.number.format.options}
          onOptionsChange={(v) =>
            (atom.data.editorOptions.editors.number.format.options = v)
          }
        />
      ) : (
        <Widgets.Number.Settings
          settingsTitle="Number display format settings"
          atomId={atom.id}
          options={atom.data.cell.view.format.options}
          onOptionsChange={(v) => (atom.data.cell.view.format.options = v)}
        />
      )}
      <Separator />
    </>
  );
};
