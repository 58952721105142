import { z } from "zod";
import { TitleInputSchema } from "../../other/otherUtils";
import { TraceWidthSchema } from "./traceWidthSelector.schema";

export namespace ColumnSchema {
  export const MainSchema = (valueValidation?: boolean) =>
    z.object({
      title: valueValidation ? TitleInputSchema : z.string(),
      width: TraceWidthSchema,
      isfixedColumn: z.boolean(),
      isDisplayedByDefault: z.boolean(),
    });

  export type Data = z.infer<ReturnType<typeof MainSchema>>;
}
