import { TableEditorNumberColumnData } from 'shared';
import { Widgets } from '@widgets';

const DEFAULT_HEADER = 'Default Number Column Header';
const DEFAULT_FORMAT: Widgets.Number.Schema.NumberFormat = {
  locale: 'en-US',
  options: {}
};

export const initialNumberData: TableEditorNumberColumnData = {
  title: DEFAULT_HEADER,
  width: 'medium',
  isfixedColumn: false,
  isDisplayedByDefault: true,
  required: true,
  cell: {
    view: {
      type: 'number',
      tabRight: false,
      format: {
        locale: 'en-US',
        options: {}
      }
    }
  },

  isEditMode: false,
  editorOptions: {
    selectedEditor: 'number',
    editors: {
      number: {
        type: 'number',
        placeholder: '',
        format: DEFAULT_FORMAT,
        saveFormatted: false
      }
    }
  }
};
