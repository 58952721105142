import { ToastT, toast } from 'sonner';
import { ZodError } from 'zod';
import { fromZodError } from 'zod-validation-error';

import { deepCopy } from '@/utils/deepCopy';
import * as Sentry from '@sentry/react';

type Options = Partial<
  {
    customMessage: string;
    errorType: 'error' | 'warning';
    isConsoleLogged: boolean;
  } & ToastT
>;

const defaultOptions: Options = {
  errorType: 'error',
  isConsoleLogged: true
};

export const newError = (
  uniqueErrorCode: `${string}-${string}`,
  error: unknown,
  /** withToast */
  withToast: boolean = false,
  options?: Options
): Error => {
  const finalOptions: Options = {
    ...defaultOptions,
    title: `Error ${uniqueErrorCode}`,
    description: 'An unexpected error has occurred',
    ...options
  };
  Sentry.setExtra('studio_error_code', uniqueErrorCode);

  if (finalOptions.isConsoleLogged)
    toBrowserConsole(error, finalOptions.errorType);

  if (typeof error === 'string') {
    const finalError = new Error(error);
    if (withToast) renderNewToast(error, finalOptions);
    Sentry.captureException(finalError);
    return finalError;
  }

  if (isArrayOfStrings(error)) {
    const finalError = new Error(error.join('\n'));
    error.forEach((errorMessage) => {
      if (withToast) {
        renderNewToast(errorMessage, finalOptions);
      }
    });
    Sentry.captureException(finalError);
    return finalError;
  }

  return handleUnknownError(error, withToast, finalOptions);
};

const handleUnknownError = (
  error: unknown,
  withToast: boolean,
  options: Options
): Error => {
  if (!error) {
    //? this will probably not happen but just in case
    const undefinedError = new Error(
      'Undefined error found in handleUnknownError'
    );
    Sentry.captureException(undefinedError);
    throw undefinedError;
  }

  let message: string = 'Unknown error';

  if (isZodError(error)) message = fromZodError(error).message;
  else if (error instanceof Error || isMessageInError(error)) {
    message = error.message;
  }

  if (withToast) renderNewToast(message, options);

  Sentry.captureException(error);

  return error instanceof Error ? error : new Error(message);
};

const isZodError = (error: unknown): error is ZodError => {
  return error instanceof ZodError;
};

const isArrayOfStrings = (error: unknown): error is string[] => {
  return Array.isArray(error) && error.every((e) => typeof e === 'string');
};

const isMessageInError = (error: unknown): error is { message: string } => {
  return (
    !!error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  );
};

const renderNewToast = (message: string, options: Options): void => {
  const toastMessage = options?.customMessage ?? message;
  const toastOptions = deepCopy(options);
  delete toastOptions.customMessage;
  delete toastOptions.errorType;
  delete toastOptions.isConsoleLogged;
  switch (options?.errorType) {
    case 'warning':
      toast.warning(toastMessage, options);
      break;
    default: // eslint-disable-next-line no-restricted-syntax
      toast.error(toastMessage, options);
  }
};

const toBrowserConsole = (error: unknown, type: Options['errorType']): void => {
  switch (type) {
    case 'warning':
      console.warn(error);
      break;
    default: // eslint-disable-next-line no-restricted-syntax
      console.error(error);
  }
};
