import { z } from 'zod';

import { TI_CommentDataSchema } from './comment.schema';
import { TI_DataTabDataSchema } from './data-tab.schema';
import { TI_DateDataSchema } from './date.schema';
import { TI_FileUploadDataSchema } from './file-upload.schema';
import { TI_KeyValueDataSchema } from './key-value.schema';
import { TI_ListDataSchema } from './list.schema';
import { TI_NumberDataSchema } from './number.schema';
import { TI_TextDataSchema } from './text.schema';
import { TI_WindowDataSchema } from './window.schema';

export const TraceInfoSchemas = [
  z.object({
    type: z.literal('TI_Text'),
    data: TI_TextDataSchema()
  }),
  z.object({
    type: z.literal('TI_DataTab'),
    data: TI_DataTabDataSchema()
  }),
  z.object({
    type: z.literal('TI_Window'),
    data: TI_WindowDataSchema()
  }),
  z.object({
    type: z.literal('TI_File'),
    data: TI_FileUploadDataSchema()
  }),
  z.object({
    type: z.literal('TI_Date'),
    data: TI_DateDataSchema()
  }),
  z.object({
    type: z.literal('TI_List'),
    data: TI_ListDataSchema()
  }),
  z.object({
    type: z.literal('TI_Number'),
    data: TI_NumberDataSchema()
  }),
  z.object({
    type: z.literal('TI_Comment'),
    data: TI_CommentDataSchema()
  }),
  z.object({
    type: z.literal('TI_KeyValue'),
    data: TI_KeyValueDataSchema()
  })
] as const;

export const TRACE_INFO_TYPES = TraceInfoSchemas.map(
  (schema) => schema.shape.type.value
);

export type TIBlocks =
  (typeof TraceInfoSchemas)[number]['shape']['type']['value'];
