import { PropsWithChildren } from 'react';

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@components/ui/hover-card';

import { cn } from '@/lib/utils';

type Props = PropsWithChildren<{
  title?: string;
  description?: string;
  triggerClassName?: string;
  additionalContent?: React.ReactNode;
  cardContent?: React.ReactNode;
  noIcon?: boolean;
}>;
/** Use `InfoTooltip` inside a relative element. */
export const InfoTooltip = (props: Props) => {
  return (
    <HoverCard openDelay={100}>
      <HoverCardTrigger asChild>
        {props.children ?? (
          <span>
            <InfoTooltipIcon noIcon={props.noIcon}></InfoTooltipIcon>
          </span>
        )}
      </HoverCardTrigger>
      <HoverCardContent className="w-80">
        {props.cardContent ?? (
          <div className="space-y-2 font-normal">
            <div className="space-y-1">
              {props.title && (
                <h4 className="text-sm font-semibold">{props.title}</h4>
              )}
              {props.description && (
                <p className="text-sm">{props.description}</p>
              )}
            </div>
            {props.additionalContent}
          </div>
        )}
      </HoverCardContent>
    </HoverCard>
  );
};

type InfoTooltipIconProps = { noIcon?: boolean; className?: string };

const InfoTooltipIcon = ({
  noIcon = false,
  className
}: InfoTooltipIconProps) => {
  if (noIcon) return <></>;
  return (
    <div
      className={cn(
        'select-none w-[14px] h-[14px] p-1 text-[10px] font-medium cursor-pointer flex items-center justify-center text-gray-900 bg-gray-100 rounded-full aspect-square shrink-0 hover:bg-gray-300',
        className
      )}
    >
      ?
    </div>
  );
};

type ExempleTooltipProps = {
  input: string;
  output: string;
  exampleName?: string;
};
export const ExempleTooltip = ({
  input,
  output,
  exampleName
}: ExempleTooltipProps) => {
  return (
    <section className="flex flex-col gap-2 text-sm text-gray-800">
      <i className="font-semibold">
        Example{exampleName ? `: ${exampleName}` : ''}
      </i>
      <div className="pl-2 border-l-2 border-gray-300 border-solid">
        <p>
          Input: <span className="text-blue-600">{input}</span>
        </p>
        <p>
          Output: <span className="font-semibold text-blue-600">{output}</span>
        </p>
      </div>
    </section>
  );
};
