import { ReactNode } from 'react';

import { TabLink } from '@panel/PanelHeader/panelHeader.types';

import Condition from './tabs/condition';
import Notifications from './tabs/notifications';

export const enum TransitionPath {
  Condition = 'condition',
  Notifications = 'notifications',
  Priorities = 'priorities'
}

export const TRANSITION_TAB_LINKS: TabLink[] = [
  {
    content: 'Condition',
    path: TransitionPath.Condition
  },
  {
    content: 'Notifications',
    path: TransitionPath.Notifications
  }
  // {
  //   content: 'Priorities',
  //   path: TransitionPath.Priorities
  // }
];

export const TRANSITION_PATHS_TO_FC_MAP: Record<string, ReactNode> = {
  [TransitionPath.Condition]: <Condition />,
  [TransitionPath.Notifications]: <Notifications />,
  [TransitionPath.Priorities]: <div>priorities</div>
};
