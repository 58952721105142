import { VariableValues } from '@hooks/useVariables';

import RootStore from '@stores/root.store';

export type VariableState = Map<string, VariableValues[]>;

export interface Variable {
  id: number;
  name: string;
  type: string;
}

export class VariablesStore {
  constructor(public rootStore: RootStore) {}
}
