import { ReactNode, useContext } from 'react';

import { Trash2 } from 'lucide-react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

import {  DNDMetaContext } from '@components/dnd/base';
import { DNDBlock } from 'shared';

const EndControlsLine = styled.div<{ $isDisplayed: boolean }>`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  gap: 0.6rem;
  align-items: center;
  user-select: none;
  opacity: 1;

  ${(p) =>
    !p.$isDisplayed &&
    css`
      opacity: 0;
    `}
`;

const Control = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ICON_SIZE = 17;

export interface SettingsConfig {
  title: string;
  content: ReactNode;
}

interface EndControlsProps {
  dndBlock: DNDBlock;
  isHovering?: boolean;
  hasDelete?: boolean;
  blockPath?: string;
  children?: ReactNode;
}

/** @deprecated EndControls component already exists in FormBuilder block*/
const EndControls = ({
  dndBlock,
  hasDelete = true,
  isHovering,
  children
}: EndControlsProps) => {
  const { onDelete } = useContext(DNDMetaContext);

  const onDeleteClick = () => {
    onDelete(dndBlock.path);
  };

  return (
    <EndControlsLine $isDisplayed={isHovering ?? true}>
      <Control>{children}</Control>
      {hasDelete && (
        <Control data-tag="control-delete-condition" onClick={onDeleteClick}>
          <Trash2 size={ICON_SIZE} />
        </Control>
      )}
    </EndControlsLine>
  );
};

export default observer(EndControls);
