import styled from 'styled-components';

export const GeneralSettingsInput = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
`;

export const ButtonContainer = styled.footer`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: end;
  box-sizing: border-box;
  margin-top: 20px;
`;

export const GeneralSettingsTitle = styled.h3`
  margin-bottom: 20px;
  padding-bottom: 0px;
`;

export const RowDiv = styled.div`
  display: flex;
  flexdirection: row;
  gap: 1rem;
  align-items: end;
  padding-top: 1rem;
`;
