import { useEffect } from 'react';

import { LucideIcon, Tally2 } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { InteractionZone } from '@components/dnd/base/interaction';

import { useAtom } from '@hooks/useAtom';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB from '@library/formBuilder/endControls';
import { FormLabel } from '@mui/joy';
import { DNDBlock } from 'shared';

export const OrIcon: LucideIcon = Tally2;

const Or = (dndBlock: DNDBlock) => {
  const dndPath = `${dndBlock.path}:or`;
  const orBlock: DNDBlock[] = dndBlock.props?.or || [];
  const transitionId = useParams()[ParamsList.TransitionId] as string;

  const atom = useAtom({
    type: 'Or',
    initialData: {},
    parentId: transitionId,
    sourceId: dndBlock.id,
    parentKind: 'transition'
  });

  // Add reference between parent and child
  useEffect(() => {
    if (!atom) return;
    orBlock.forEach((el) => {
      if (el.atomId === dndBlock.atomId) return;
      atom.addReferencingAtom(el.atomId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atom, dndBlock]);

  if (!atom) return <></>;

  return (
    <BlockBase
      dndBlock={dndBlock}
      hasTitle={false}
      icon={OrIcon}
      isStatement
      endControls={
        <EndControlsFB
          dataItem={atom}
          hasRequired={false}
          dndBlock={dndBlock}
        />
      }
    >
      <Column>
        <FormLabel sx={{ fontSize: '12px' }}>All conditions</FormLabel>
      </Column>
      <Column $width="100%">
        <InteractionZone path={dndPath} dndBlock={orBlock} />
      </Column>
    </BlockBase>
  );
};

export default observer(Or);
