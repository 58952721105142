import { z } from 'zod';

import { StaticType } from '../../../../bpmn';
import {
  TextInputSchema,
  TitleInputSchema
} from '../../../../other/otherUtils';

export const ALL_TYPE_KIND = ['normal', 'list', 'custom'] as const;

export const PRIMARY_TYPES = [
  'string',
  'number',
  'boolean',
  StaticType.Email,
  StaticType.URL,
  StaticType.FormFile
] as const;

export type PrimaryType = (typeof PRIMARY_TYPES)[number];
export type AllKindOfType = (typeof ALL_TYPE_KIND)[number];

const VariableTypeSchema = z.object({
  selectedKind: z.enum(ALL_TYPE_KIND),
  type: z.enum(PRIMARY_TYPES),
  customType: z.string()
});

export const GlobalVariableDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      variableType: VariableTypeSchema,
      defaultValue: z.any().optional(),
      description: z.string().optional()
    })
    .strict();

export type GlobalVariableData = z.infer<
  ReturnType<typeof GlobalVariableDataSchema>
>;
