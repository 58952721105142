import { ChangeEvent } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';

import {  DNDBlock, ResolvedVariableType } from 'shared'

import { DataSelector } from '@atoms/dataSelectors/dataSelector/dataSelector';
import { InputField } from '@atoms/input';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';
import { FormControl, FormLabel, Radio, RadioGroup } from '@mui/joy';

import { initialCheckBoxData } from './checkbox.data';
import { CheckBoxModal } from './checkbox.modal';
import {
  CheckBoxIcon,
  CheckBoxTypeOption,
  checkBoxTypeOptions,
  isCorrectCheckBoxType
} from './checkbox.utils';

const CheckBoxField = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    parentId: actionId,
    initialData: initialCheckBoxData,
    type: 'Check Box',
    sourceId: block.id,
    parentKind: 'form_action',
    variableInfo: {
      //? will change depending on checkbox type
      resolvedType: ResolvedVariableType.List
    }
  });

  if (!atom) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: atom.data.title || `Check Box title`,
      content: <CheckBoxModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  const onCheckBoxTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isCorrectCheckBoxType(e.target.value)) return;
    const newCheckBoxType = e.target.value;
    atom.data.checkboxType = newCheckBoxType;
    if (newCheckBoxType === 'radio') {
      // @ts-expect-error at this point, we know that variableInfo is defined
      atom.variableInfo.resolvedType = ResolvedVariableType.Text;
    } else {
      // @ts-expect-error at this point, we know that variableInfo is defined
      atom.variableInfo.resolvedType = ResolvedVariableType.List;
    }
  };

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={CheckBoxIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={atom.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            atom.data.title = e.target.value;
          }}
        />
      </Column>

      <Column $width="100px">
        <FormControl size="sm">
          <FormLabel>Checkbox type</FormLabel>
          <RadioGroup value={atom.data.checkboxType} name="radio-buttons-group">
            {checkBoxTypeOptions.map((option: CheckBoxTypeOption) => (
              <Radio
                key={option.value}
                value={option.value}
                onChange={onCheckBoxTypeChange}
                label={option.name}
                color="neutral"
                size="sm"
                disabled={option.isDisabled ?? false}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Column>
      <DataSelector
        id={atom.id}
        dataSource={atom.data.dataSource}
        onDataSourceChange={(v) => {
          atom.data.dataSource = v;
        }}
        sourcesData={atom.data.dataSourceData}
        onSourceDataChange={(v) => {
          atom.data.dataSourceData = v;
        }}
      />
    </BlockBase>
  );
};

export default observer(CheckBoxField);
