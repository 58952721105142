import { observer } from 'mobx-react';

import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { AtomModel } from '@models/atom.model';

import { ColumnTitle } from '@atoms/columnElement';
import { RenderSource } from '@atoms/dataSelectors/sourceSelector/renderSource';
import { SourceSelector } from '@atoms/dataSelectors/sourceSelector/sourceSelector';

import { EditorOptions } from '@library/tableEditor/shared/editor.options';
import { MultiSelectTableEditorColumnData } from 'shared';

type Props = {
  atom: AtomModel<MultiSelectTableEditorColumnData>;
};

export const MultiSelectEditor = observer(({ atom }: Props) => {
  return (
    <EditorOptions
      atomId={atom.id}
      onPlaceholderChange={(v) =>
        (atom.data.editorOptions.editors.multiselect.placeholder = v)
      }
      placeholder={atom.data.editorOptions.editors.multiselect.placeholder}
      additionalChildren={
        <Column $width="220px">
          <ColumnTitle>Sources options</ColumnTitle>
          <RenderSource
            id={atom.id}
            selectedDataSource={
              atom.data.editorOptions.editors.multiselect.options.dataSource
            }
            sourcesData={
              atom.data.editorOptions.editors.multiselect.options.dataSourceData
            }
            onSourceDataChange={(v) =>
              (atom.data.editorOptions.editors.multiselect.options.dataSourceData =
                v)
            }
          />
        </Column>
      }
    >
      <SourceSelector
        id={atom.id}
        dataSource={
          atom.data.editorOptions.editors.multiselect.options.dataSource
        }
        onDataSourceChange={(v) =>
          (atom.data.editorOptions.editors.multiselect.options.dataSource = v)
        }
      />
    </EditorOptions>
  );
});
