import { z } from "zod";

import { LocaleList, SharedSchema } from "./utils";

export namespace NumberWidgetSchema {
  const StyleSchema = z.enum(["decimal", "percent", "currency"] as const);
  const CurrencyDisplaySchema = z.enum(["symbol", "code", "name"] as const);
  const LocaleSchema = z.enum(LocaleList);

  export type Style = z.infer<typeof StyleSchema>;
  export type CurrencyDisplay = z.infer<typeof CurrencyDisplaySchema>;
  export type Locale = z.infer<typeof LocaleSchema>;

  const FormatOptionsSchema = z
    .object({
      style: StyleSchema,
      currency: z.string(),
      currencyDisplay: CurrencyDisplaySchema,
      useGrouping: z.boolean(),
      minimumFractionDigits: SharedSchema.PositiveIntegerSchema,
      maximumFractionDigits: SharedSchema.PositiveIntegerSchema,
      minimumIntegerDigits: SharedSchema.PositiveIntegerSchema,
      minimumSignificantDigits: SharedSchema.PositiveIntegerSchema,
      maximumSignificantDigits: SharedSchema.PositiveIntegerSchema,
    })
    .partial();

  export type FormatOptions = z.infer<typeof FormatOptionsSchema>;

  export const NumberFormatSchema = z.object({
    locale: LocaleSchema,
    options: FormatOptionsSchema,
  });

  export const MainSchema = z.object({
    type: z.literal("number"),
    tabRight: z.boolean(),
    format: NumberFormatSchema,
  });

  export type NumberFormat = z.infer<typeof NumberFormatSchema>;
  export type Data = z.infer<typeof MainSchema>;
}
