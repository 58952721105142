import { DataSelectorData } from 'shared';

import { INITIAL_REPOSITORY_DATA } from '@atoms/dataSelectors/repositorySelector/repositorySelector.data';
import { INITIAL_STATIC_DATA } from '@atoms/dataSelectors/staticSelector/staticSelector.data';

export const INITIAL_DATA_SELECTOR_DATA: DataSelectorData = {
  dataSource: 'normalSource',
  dataSourceData: {
    normalSource: INITIAL_STATIC_DATA,
    database: INITIAL_REPOSITORY_DATA
  }
};
