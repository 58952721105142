import { z } from "zod";

import { ImportOptionsWithParserSchema } from "./table-importer.atom";
import { NumberWidgetSchema, ColumnSchema } from "../../../../trace";

export const TINumberColumnDataSchema = (valueValidation?: boolean) =>
    z
        .object({
            cell: z.object({
                view: NumberWidgetSchema.MainSchema,
            }),
            required: z.boolean(),
        })
        .merge(ColumnSchema.MainSchema(valueValidation))
        .merge(
            ImportOptionsWithParserSchema(
                z.object({
                    type: z.literal("number"),
                    delimiters: z.object({
                        thousands: z.string(),
                        decimal: z.string(),
                    }),
                })
            )
        )
        .strict();

export type TableImporterNumberColumnData = z.infer<
    ReturnType<typeof TINumberColumnDataSchema>
>;
