import { ProgressWidgetSchema } from 'shared';

export const initialProgressWidgetData: ProgressWidgetSchema.Data = {
  type: 'progress',
  pathRef: undefined,
  denominator: {
    type: 'none',
    data: {
      number: 100,
      variableRef: undefined
    }
  }
};
