import { PasswordProtectedModel } from '@models/passwordProtected.model';
import { ReferenceModalModel } from '@models/referenceModal.model';

import RootStore from '@stores/root.store';

export default class ModalStore {
  rootStore: RootStore;

  referenceModal: ReferenceModalModel;
  passwordProtectedModal: PasswordProtectedModel;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.referenceModal = new ReferenceModalModel(this.rootStore);
    this.passwordProtectedModal = new PasswordProtectedModel(this.rootStore);
  }
}
