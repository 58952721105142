import { z } from "zod";

export const TextSchema = z.array(z.any()); //! bad

export const NotificationTemplateSchema = z
  .object({
    id: z.string(),
    title: z.string(),
    text: TextSchema,
    createdAt: z.string(),
    updatedAt: z.string(),
    process_id: z.string(),
  })
  .strict();

export type NotificationTemplate = z.infer<typeof NotificationTemplateSchema>;
