import styled from 'styled-components';

export const BpmnEditor = styled.div`
  height: 100%;

  .djs-palette {
    background: #fff;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    outline-color: #e5e7eb;
    outline-width: 1px;
    outline-style: solid;

    --tw-shadow: 0px 2px 4px 0px #00000017;
    --tw-shadow-colored: 0px 2px 4px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    lg .entry {
      width: 100%;

      &:hover {
        color: initial;
        background: ${({ theme }) => theme.color.grey[7]};
        cursor: pointer;
      }
    }

    .disabled {
      pointer-events: none;
      color: ${({ theme }) => theme.color.grey[5]};
      background: ${({ theme }) => theme.color.grey[8]};
    }
  }

  .bpmn-create,
  .bpmn-append,
  .bpmn-replace {
    box-shadow: ${({ theme }) => theme.boxShadow.indigoHover};
    border-radius: 5px;

    .djs-popup-group li {
      cursor: pointer;
    }
  }

  .djs-context-pad {
    .entry:hover {
      cursor: pointer;
    }
  }
`;

export const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
`;

export const LoaderBox = styled.div`
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
