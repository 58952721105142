import { FC, cloneElement } from 'react';

import { LucideProps } from 'lucide-react';
import { useDrag } from 'react-dnd';

import { getCleanBlockType } from '@/utils/cleanBlockType';

import { ItemName, ToolbarItem } from './toolbarItem.style';

interface ItemProps {
  type: string;
  icon: JSX.Element;
}

export const DNDToolbarItem: FC<ItemProps> = ({ type, icon }) => {
  const [, drag] = useDrag(() => ({
    type,
    item: {
      path: 'toolbar',
      type
    }
  }));

  const iconProps: LucideProps = {
    size: '17px',
    color: '#616161'
  };

  return (
    <ToolbarItem ref={drag} data-tag={type}>
      {cloneElement(icon, iconProps)}
      <ItemName>{getCleanBlockType(type)}</ItemName>
    </ToolbarItem>
  );
};
