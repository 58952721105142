import { ValueSetterParams } from 'ag-grid-community';
import { ValueSetters } from 'shared/src/database/database.schema';

const nonEmptyValueSetter = (params: ValueSetterParams) => {
  if (!params.newValue || params.newValue.trim() === '') {
    return false;
  }
  params.data[params.colDef.field ?? ''] = params.newValue;
  return true;
};

export const ValueSetterToFunctionMap: Record<
  ValueSetters,
  (params: ValueSetterParams) => boolean
> = {
  [ValueSetters.NON_EMPTY_STRING]: nonEmptyValueSetter
};
