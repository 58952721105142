import { Bold, Italic, Underline, Variable } from 'lucide-react';
import { Descendant, Editor, Element as SlateElement, Text } from 'slate';
import { RenderElementProps, RenderLeafProps } from 'slate-react';

import { CustomEditor, CustomText } from './slate';
import { CustomChip } from './style';

interface CustomRenderLeafProps extends RenderLeafProps {
  variables: CustomText[];
}

export const Leaf = ({
  attributes,
  children,
  leaf,
  variables
}: CustomRenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underlined) {
    children = <u>{children}</u>;
  }

  if (leaf.variable) {
    const currentIndex = variables.findIndex(
      (variable) => leaf.text === variable.text
    );
    children = (
      <CustomChip color={randomColor(currentIndex)}>{children}</CustomChip>
    );
  }

  return <span {...attributes}>{children}</span>;
};

export const Element = ({ children, element }: RenderElementProps) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote>{children}</blockquote>;
    case 'bulleted-list':
      return <ul>{children}</ul>;
    case 'heading-one':
      return <h1>{children}</h1>;
    case 'heading-two':
      return <h2>{children}</h2>;
    case 'heading-three':
      return <h3>{children}</h3>;
    case 'list-item':
      return <li>{children}</li>;
    default:
      return <p>{children}</p>;
  }
};

export const toggleMark = (editor: CustomEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isMarkActive = (editor: CustomEditor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const TOOLS = [
  {
    label: 'Bold',
    format: 'bold',
    icon: <Bold />
  },
  {
    label: 'Italic',
    format: 'italic',
    icon: <Italic />
  },
  {
    label: 'Underline',
    format: 'underlined',
    icon: <Underline />
  },
  {
    label: 'Variable',
    format: 'variable',
    icon: <Variable />
  }
];

export const SHORTCUTS: Record<string, string> = {
  '-': 'list-item',
  '>': 'block-quote',
  '#': 'heading-one',
  '##': 'heading-two',
  '###': 'heading-three',
  '####': 'heading-four',
  '#####': 'heading-five',
  '######': 'heading-six'
};

const variableColors = [
  '#867EF9',
  '#8dd3c7',
  '#bebada',
  '#80b1d3',
  '#fb8072',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#d9d9d9',
  '#bc80bd',
  '#ccebc5',
  '#ffed6f'
];

const hashString = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
};

export const randomColor = (seed: number): string => {
  const index = Math.abs(hashString(seed.toString())) % variableColors.length;
  return variableColors[index];
};

export const getVariables = (nodes: Descendant[]): CustomText[] => {
  return nodes.reduce((variables, node: Descendant) => {
    if (Text.isText(node) && node.variable) {
      variables.push(node);
    } else if (SlateElement.isElement(node)) {
      variables.push(...getVariables(node.children));
    }
    return variables;
  }, [] as CustomText[]);
};

export const isArrayEqual = (arr1, arr2) => {
  const json1 = JSON.stringify(arr1);
  const json2 = JSON.stringify(arr2);

  return json1 === json2;
};
