import { z } from 'zod';

import { AtomReferenceSchema } from '../../../atomReference.schema';

export const TO_NUMBER_VIEW_TYPES = ['Number', 'Progress', 'Code'] as const;
export const TO_NumberViewTypeSchema = z.enum(TO_NUMBER_VIEW_TYPES);

export const TO_NumberIconSchema = z.enum([
  'Hash',
  'Loader',
  'Code',
  'FileDigit',
  'CircleDashed'
]);

export type TO_NumberIconType = z.infer<typeof TO_NumberIconSchema>;

export const TO_NumberDataSchema = (_?: boolean) =>
  z
    .object({
      title: z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_NumberViewTypeSchema,
      hasIcon: z.boolean().default(false),
      icon: TO_NumberIconSchema
    })
    .strict();

export type TO_NumberData = z.infer<ReturnType<typeof TO_NumberDataSchema>>;
