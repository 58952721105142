import { Locale } from "shared/src/trace/widget/utils";

type LocalInfo = {
  emoji: string;
  name: string;
};
export const LocalToInfosMap = {
  'en-US': {
    emoji: '🇺🇸',
    name: 'United States'
  },
  'fr-FR': {
    emoji: '🇫🇷',
    name: 'France'
  },
  'it-IT': {
    emoji: '🇮🇹',
    name: 'Italy'
  }
} as const satisfies Record<Locale, LocalInfo>;
