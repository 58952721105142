import { observer } from 'mobx-react';

import DNDBase from '@components/dnd/base';

import useAction from '@hooks/useAction';
import useStores from '@hooks/useStore';

export const UiTableImporter = observer(() => {
  const { dndStore } = useStores();
  const action = useAction();
  const uiDnd = dndStore.get(action?.uiDndId);
  if (!uiDnd) {
    return <></>;
  }

  return <DNDBase dnd={uiDnd} hasToolbar />;
});
