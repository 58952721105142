import { z } from "zod";

const ParserSchema = <P>(parserTypeSchema: z.Schema<P>) =>
    z
        .object({
            activated: z.boolean(),
            parser: parserTypeSchema,
        })
        .strict();

const BaseImportOptionsSchema = z.object({
    from: z.string(),
});

export const ImportOptionsSchema = z
    .object({
        importerOptions: BaseImportOptionsSchema,
    })
    .strict();

export const ImportOptionsWithParserSchema = <P>(
    parserTypeSchema: z.Schema<P>
) =>
    z
        .object({
            importerOptions: BaseImportOptionsSchema.merge(
                z.object({ parserOptions: ParserSchema(parserTypeSchema) })
            ),
        })
        .strict();

export type ImporterOptions = z.infer<
    ReturnType<typeof ImportOptionsWithParserSchema>
>;
