import { useContext } from 'react';

import { useDrop } from 'react-dnd';

import { DNDMetaContext } from '@components/dnd/base';

import { DropZone as DropZoneType, Item } from '@models/dnd.model';

import { DropIndicator, DroppableDiv } from './dropZone.style';
import { ALL_BLOCKS } from 'shared';
type DropZoneProps = {
  data: DropZoneType;
  isLast?: boolean;
};

type Collect = {isOver: boolean, isDroppable: boolean}

const DropZone = ({ data, isLast }: DropZoneProps) => {
  const { onDropFunction, restriction } = useContext(DNDMetaContext);

  const [{ isOver, isDroppable }, drop] = useDrop<Item, void, Collect>({
    accept: ALL_BLOCKS as unknown as string[], 
    canDrop: (item: Item): boolean => {
      if (restriction) return restriction(data.path, item);
      return !!item.path;
    },
    drop: (item: Item): void => {
      onDropFunction(data, item);
    },
    collect: (monitor): Collect => ({
      isOver: monitor.isOver(),
      isDroppable: monitor.canDrop()
    })
  });

  return (
    <DroppableDiv
      $isOver={isOver}
      $isLast={isLast}
      ref={drop}
      data-tag={data.path}
    >
      {<DropIndicator $isVisible={isOver && isDroppable} />}
    </DroppableDiv>
  );
};

export default DropZone;
