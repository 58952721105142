import { PropsWithChildren } from 'react';

import { observer } from 'mobx-react';
import { ColumnSchema } from 'shared/src/trace/table/column.schema';
import { TraceWidth } from 'shared/src/trace/table/traceWidthSelector.schema';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { Input } from '@components/ui/input';
import { Label } from '@components/ui/label';

import { ColumnElement, ColumnTitle } from '@atoms/columnElement';
import { InfoTooltip } from '@atoms/infoTooltip';
import { SwitchV2 } from '@atoms/switch';
import { TraceWidthSelector } from '@atoms/traceWidthSelector/traceWidthSelector';

type Props = PropsWithChildren<
  ColumnSchema.Data & {
    onWidthChange: (width: TraceWidth) => void;
    onTitleChange: (title: string) => void;
    onFixedColumnChange: (isfixedColumn: boolean) => void;
    onDisplayByDefaultChange: (displayByDefault: boolean) => void;
    atomId: string;
  }
>;

export const ColumnComp = observer((props: Props) => {
  return (
    <>
      {props.children}

      <Column $width="220px">
        <ColumnTitle htmlFor={`title-${props.atomId}`}>
          Column options
        </ColumnTitle>
        <ColumnElement>
          <Label htmlFor={`title-${props.atomId}`}>Column header</Label>
          <Input
            id={`title-${props.atomId}`}
            placeholder="Aa"
            value={props.title}
            onChange={(e) => props.onTitleChange(e.target.value)}
          />
        </ColumnElement>
        <ColumnElement>
          <TraceWidthSelector
            selectedWidth={props.width}
            onWidthChange={props.onWidthChange}
            id={props.atomId}
            label="Column width"
          />
        </ColumnElement>
        <ColumnElement>
          <SwitchV2
            id={`display-by-default-${props.atomId}`}
            checked={props.isDisplayedByDefault}
            onCheckedChange={props.onDisplayByDefaultChange}
            labelText="Displayed by default"
            tooltip={
              <InfoTooltip
                title="Displayed by default"
                description="Whether the column is displayed by default or if the user needs to activate it."
              />
            }
          />
        </ColumnElement>
        <ColumnElement>
          <SwitchV2
            id={`fixed-column-${props.atomId}`}
            checked={props.isfixedColumn}
            onCheckedChange={props.onFixedColumnChange}
            labelText="Fixed column"
            tooltip={
              <InfoTooltip
                title="Fixed column"
                description="Whether the column is always visible if the table is scrolled horizontally."
              />
            }
          />
        </ColumnElement>
      </Column>
    </>
  );
});
