import { Fragment, useContext } from 'react';

import {  DNDMetaContext } from '@components/dnd/base';

import { DNDBlockRender } from '@library';

import EmptyState from './EmptyState';
import { InteractionZone } from './interaction';
import { DNDBlock } from 'shared';

type DNDRootBlockProps = {
  root: DNDBlock[];
};

export const DNDRootBlock = ({ root }: DNDRootBlockProps) => {
  const { library } = useContext(DNDMetaContext);
  if (!root) return null;
  if (root.length === 0) {
    return (
      <>
        <EmptyState library={library}>
          <InteractionZone path={`root:root|0`} dndBlock={[]} isLast={true} />
        </EmptyState>
      </>
    );
  }

  return (
    <>
      {root.map((blockElement, index) => (
        <Fragment key={index}>
          {index === 0 && <InteractionZone dndBlock={[]} path="root:root|0" />}
          <DNDBlockRender library={library} block={blockElement} path="root" />
          <InteractionZone
            path={`root:root|${index + 1}`}
            isLast={index === root.length - 1}
            dndBlock={[]}
          />
        </Fragment>
      ))}
    </>
  );
};
