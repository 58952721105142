import { ActionType } from 'shared'
import { TableEditorAction } from 'shared'

import BaseStore from '@stores/base/base.store';

import { ActionModel } from './action.model';

export class TableEditorActionModel extends ActionModel {
  public actionType = 'TABLE_EDITOR' as const satisfies ActionType;
  constructor(store: BaseStore<ActionModel>, action: TableEditorAction) {
    super(store, action);
  }

  get metadata() {
    return this._metadata as TableEditorAction['metadata'];
  }
}
