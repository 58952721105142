import {
  Cpu,
  Database,
  File,
  Landmark,
  Leaf,
  PencilRuler,
  Pyramid
} from 'lucide-react';

const ICON_SIZE = 18;

enum TemplateList {
  Default = 'default',
  Bank = 'bank',
  Energy = 'energy',
  Technology = 'technology',
  DataScience = 'dts'
}

export enum ProjectTypeList {
  Stratumn = 'stratumn',
  Draft = 'draft'
}

export interface FormItem {
  name: string;
  icon: JSX.Element;
  value: TemplateList | ProjectTypeList;
  disabled?: boolean;
}

export const defaultProjectType: FormItem = {
  name: `Stratumn process`,
  icon: <Pyramid size={ICON_SIZE} />,
  value: ProjectTypeList.Stratumn
};

export const defaultTemplate: FormItem = {
  name: `An empty project`,
  icon: <File size={ICON_SIZE} />,
  value: TemplateList.Default
};

export const templates: FormItem[] = [
  {
    name: `A bank project`,
    icon: <Landmark size={ICON_SIZE} />,
    value: TemplateList.Bank,
    disabled: true
  },
  {
    name: `An energy project`,
    icon: <Leaf size={ICON_SIZE} />,
    value: TemplateList.Energy,
    disabled: true
  },
  {
    name: `A tech project`,
    icon: <Cpu size={ICON_SIZE} />,
    value: TemplateList.Technology,
    disabled: true
  },
  {
    name: `A data science project`,
    icon: <Database size={ICON_SIZE} />,
    value: TemplateList.DataScience,
    disabled: true
  }
];

export const projectTypes: FormItem[] = [
  {
    name: `Draft`,
    icon: <PencilRuler size={ICON_SIZE} />,
    value: ProjectTypeList.Draft
  }
];

export const isTextInString = (str: string): boolean => {
  return str.replace(/\s/g, '').length > 0;
};
