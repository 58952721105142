import { z } from "zod";

import { ImportOptionsSchema } from "./table-importer.atom";
import { LabelsWidgetSchema, ColumnSchema } from "../../../../trace";

export const TILabelsColumnDataSchema = (valueValidation?: boolean) =>
    z
        .object({
            cell: z.object({
                view: LabelsWidgetSchema.MainSchema,
            }),
            required: z.boolean(),
        })
        .merge(ColumnSchema.MainSchema(valueValidation))
        .merge(ImportOptionsSchema)
        .strict();

export type TableImporterLabelsColumnData = z.infer<
    ReturnType<typeof TILabelsColumnDataSchema>
>;
