// from: https://github.com/bpmn-io/diagram-js/tree/develop/lib/features/hand-tool
import { isKey } from 'diagram-js/lib/features/keyboard/KeyboardUtil';
import { hasPrimaryModifier } from 'diagram-js/lib/util/Mouse';

/**
 * @typedef {import("didi").Injector} Injector
 *
 * @typedef {import("diagram-js/lib/core/Canvas").default} Canvas
 * @typedef {import("diagram-js/lib/features/dragging/Dragging").default} Dragging
 * @typedef {import("diagram-js/lib/core/EventBus").default} EventBus
 * @typedef {import("diagram-js/lib/features/mouse/Mouse").default} Mouse
 * @typedef {import("diagram-js/lib/features/tool-manager/ToolManager").default} ToolManager
 */

/**
 * @param {EventBus} eventBus
 * @param {Canvas} canvas
 * @param {Dragging} dragging
 * @param {Injector} injector
 * @param {ToolManager} toolManager
 * @param {Mouse} mouse
 */

const HIGH_PRIORITY = 1500;
const HAND_CURSOR = 'grab';

class HandTool {
  constructor(eventBus, canvas, dragging, injector, toolManager, mouse) {
    this._dragging = dragging;
    this._mouse = mouse;

    const self = this;
    const keyboard = injector.get('keyboard', false);

    toolManager.registerTool('hand', {
      tool: 'hand',
      dragging: 'hand.move'
    });

    eventBus.on('element.mousedown', HIGH_PRIORITY, function (event) {
      if (!hasPrimaryModifier(event)) {
        return;
      }

      self.activateMove(event.originalEvent, true);

      return false;
    });

    if (keyboard) {
      keyboard.addListener(
        HIGH_PRIORITY,
        function (e) {
          if (!isSpace(e.keyEvent) || self.isActive()) {
            return;
          }

          const mouseEvent = self._mouse.getLastMoveEvent();

          self.activateHand(mouseEvent, !!mouseEvent);
        },
        'keyboard.keydown'
      );

      keyboard.addListener(
        HIGH_PRIORITY,
        function (e) {
          if (!isSpace(e.keyEvent) || !self.isActive()) {
            return;
          }

          self.toggle();
        },
        'keyboard.keyup'
      );
    }

    eventBus.on('hand.end', function (event) {
      const target = event.originalEvent.target;

      // only reactive on diagram click
      // on some occasions, event.hover is not set and we have to check if the target is an svg
      if (!event.hover && !(target instanceof SVGElement)) {
        return false;
      }

      eventBus.once('hand.ended', function () {
        self.activateMove(event.originalEvent, { reactivate: true });
      });
    });

    eventBus.on('hand.move.move', function (event) {
      const scale = canvas.viewbox().scale;

      canvas.scroll({
        dx: event.dx * scale,
        dy: event.dy * scale
      });
    });

    // Don"t reactivate if the user is using the keyboard keybinding
    eventBus.on('hand.move.end', function (event) {
      const context = event.context,
        reactivate = context.reactivate;

      if (!hasPrimaryModifier(event) && reactivate) {
        eventBus.once('hand.move.ended', function (event) {
          self.activateHand(event.originalEvent, true, true);
        });
      }

      return false;
    });
  }

  activateMove(event, autoActivate, context) {
    if (typeof autoActivate === 'object') {
      context = autoActivate;
      autoActivate = false;
    }

    this._dragging.init(event, 'hand.move', {
      autoActivate: autoActivate,
      cursor: 'grabbing',
      data: {
        context: context || {}
      }
    });
  }

  activateHand(event, autoActivate, reactivate) {
    this._dragging.init(event, 'hand', {
      trapClick: false,
      autoActivate: autoActivate,
      cursor: 'grab',
      data: {
        context: {
          reactivate: reactivate
        }
      }
    });
  }

  toggle() {
    if (this.isActive()) {
      return this._dragging.cancel();
    }

    const mouseEvent = this._mouse.getLastMoveEvent();

    this.activateHand(mouseEvent, !!mouseEvent);
  }

  isActive() {
    const context = this._dragging.context();

    if (context) {
      return /^(hand|hand\.move)$/.test(context.prefix);
    }

    return false;
  }
}

HandTool.$inject = [
  'eventBus',
  'canvas',
  'dragging',
  'injector',
  'toolManager',
  'mouse'
];

export default HandTool;

// helpers //////////

function isSpace(keyEvent) {
  return isKey('Space', keyEvent);
}
