import { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';

import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';

import Dots from '@/assets/svg/dragDots.svg?react';

import {
  BlockConditionContainer,
  LeftSide,
  RightSideCondition
} from '../blockBase.style';
import { BlockBody, EndControlsContainer } from '../body.block.style';

type Props = {
  isDragging: boolean;
  isCollapsed: boolean;
  isNested: boolean;
  isCondition: boolean;
  preview: ConnectDragPreview;
  drag: ConnectDragSource;
  setHover: Dispatch<SetStateAction<boolean>>;
  isHovering: boolean;
  children: ReactNode;
  EndControls?: ReactElement;
};

const BlockCondition = ({
  isDragging,
  isCollapsed,
  isNested,
  isCondition,
  preview,
  drag,
  setHover,
  isHovering,
  EndControls,
  children
}: Props) => {
  return (
    <BlockConditionContainer
      $isDragging={isDragging}
      $isCollapsed={isCollapsed}
      ref={preview}
      $isNested={isNested}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-tag="Condition-block"
    >
      <LeftSide $isCondition={isCondition} ref={drag} data-tag="drag-dots">
        <div className="w-full flex items-center justify-center h-[25px] shrink-0">
          <Dots className="h-5" />
        </div>
      </LeftSide>
      <RightSideCondition>
        <section className="flex flex-col justify-between w-full gap-4">
          <BlockBody>{children}</BlockBody>
        </section>
        <EndControlsContainer $isCondition={isCondition}>
          {EndControls && (
            <EndControls.type {...EndControls.props} isHovering={isHovering} />
          )}
        </EndControlsContainer>
      </RightSideCondition>
    </BlockConditionContainer>
  );
};

export default BlockCondition;
