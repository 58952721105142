const theme = {
  color: {
    brand: {
      backgroundPrimary: '#E5E5E5',
      primary: '#2e128b'
    },
    indigoTailwind: {
      '50': 'hsl(227, 100%, 96%)',
      '100': 'hsl(229, 100%, 94%)',
      '200': 'hsl(232, 100%, 88%)',
      '300': 'hsl(234, 100%, 82%)',
      '400': 'hsl(238, 98%, 74%)',
      '500': 'hsl(243, 92%, 67%)',
      '600': 'hsl(247, 83%, 59%)',
      '700': 'hsl(248, 64%, 52%)', // default indigo
      '800': 'hsl(248, 60%, 41%)',
      '900': 'hsl(246, 52%, 34%)',
      '950': 'hsl(247, 51%, 20%)'
    },

    indigo: {
      1: '#2E058D',
      2: '#4B35D2',
      3: '#5246F7',
      4: '#586FEF',
      pastel1: '#867EF9',
      pastel2: '#E1DEFF',
      pastel3: '#EEEDFD'
    },
    teal: {
      1: '#30CECA',
      2: '#6BEDD8'
    },
    grey: {
      1: '#34323A',
      2: '#736F7F',
      3: '#8F8B9B',
      4: '#B4B1BD',
      5: '#D0CED5',
      6: '#E5E3EB',
      7: '#ECEBF2',
      8: '#F2F1F5',
      9: '#FBFBFB',
      table: '#F8F8F8',
      dark: '#4A4A4A'
    },
    red: {
      warning: '#E8544D'
    },
    white: {
      1: '#FFFFFF',
      2: '#fafafa'
    },
    green: {
      valid: '#54C063',
      validDark: '#41AF50'
    },
    orange: {
      caution: '#F5A125',
      cautionDark: '#E59113'
    },
    disabled: {
      indigo3: '#9790FA',
      grey3: '#BCB9C3',
      warningRed: '#F19894'
    },
    border: {
      light: '#EDEDED'
    }
  },

  boxShadow: {
    indigo01: '0px 3px 20px rgba(75, 53, 210, 0.1)',
    indigo02: '0px 3px 20px rgba(75, 53, 210, 0.2)',
    indigoLight: '0px 3px 20px rgba(75, 53, 210, 0.05)',
    indigoHover: '0px 10px 25px rgba(75, 53, 210, 0.25)',
    red02: '0px 3px 20px rgba(232, 84, 77, 0.2)',
    light: '0px 2px 4px 0px #00000017'
  },

  border: {
    default: `1px solid #EDEDED`
  },

  verRail: {
    border: '1px solid hsla(0, 0%, 92%, 1)',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.08), 0 4px 6px rgba(0, 0, 0, 0.04)',
    boxShadowHover:
      '0 0 0 1px rgba(0, 0, 0, 0.08), 0 6px 14px rgba(0, 0, 0, 0.08)',
    background: {
      page: '#fafafa',
      content: 'white',
      contentHover: 'hsl(0, 0%, 95%);'
    },
    borderRadius: '8px',
    glass: {
      backdropFilter:
        'blur(12px) saturate(190%) contrast(50%) brightness(130%)',
      background: 'rgba(255, 255, 255, 0.5)',
      border: '0.5px solid rgb(216, 216, 216)'
    }
  },

  // fixed sizes used across clients
  headerHeight: '50px'
};

export default theme;
