import { PropsWithChildren } from 'react';

import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { AtomModel } from '@models/atom.model';

import { ColumnElement, ColumnTitle } from '@atoms/columnElement';
import { LocalFormatSelector } from '@atoms/localFormatSelector/localFormatSelector';
import { SwitchV2 } from '@atoms/switch';
import { NumberWidgetSchema } from 'shared';

type Props = {
  selectedLocale: NumberWidgetSchema.Locale;
  onLocaleChange: (locale: NumberWidgetSchema.Locale) => void;

  tabRight: boolean;
  onTabRightChange: (tabRight: boolean) => void;

  atomId: AtomModel['id'];
};

export const NumberWidget = (props: PropsWithChildren<Props>) => {
  return (
    <>
      {props.children}

      <Column $width="200px">
        <ColumnTitle>Display options</ColumnTitle>

        <ColumnElement>
          <LocalFormatSelector
            id={props.atomId}
            selectedLocale={props.selectedLocale}
            onLocaleChange={props.onLocaleChange}
          />
        </ColumnElement>
        <ColumnElement>
          <SwitchV2
            id={`tab-right-${props.atomId}`}
            labelText="Place to right side"
            checked={props.tabRight}
            onCheckedChange={(tabRight) => props.onTabRightChange(tabRight)}
          />
        </ColumnElement>
      </Column>
    </>
  );
};
