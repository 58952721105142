import { z } from "zod";

export namespace TextWidgetSchema {
  export const MainSchema = z.object({
    type: z.literal("text"),
    default: z.string().optional(),
    isRichText: z.boolean().optional(),
    editor: z.unknown().optional(),
  });

  export type Data = z.infer<typeof MainSchema>;
}
