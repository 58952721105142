import { Separator } from '@components/ui/separator';

import { AtomModel } from '@models/atom.model';
import { TableEditorDateColumnData } from 'shared';

import { Widgets } from '@widgets';

type Props = {
  atom: AtomModel<TableEditorDateColumnData>;
};

export const DateSettingsModal = ({ atom }: Props) => {
  // no specific settings for the date editor
  if (atom.data.isEditMode) return null;
  return (
    <>
      <Widgets.Date.Settings
        isDeadline={atom.data.cell.view.isDeadline}
        deadlineWarningBuffer={atom.data.cell.view.deadlineWarningBuffer}
        doneVariableReference={atom.data.cell.view.doneVariableReference}
        onDeadlineWarningBufferChange={(v) =>
          (atom.data.cell.view.deadlineWarningBuffer = v)
        }
        onIsDeadlineChange={(v) => (atom.data.cell.view.isDeadline = v)}
        onDoneVariableReferenceChange={(v) =>
          (atom.data.cell.view.doneVariableReference = v)
        }
        atomId={atom.id}
      />
      <Separator />
    </>
  );
};
