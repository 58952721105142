import { observer } from 'mobx-react';

import ReferencesModal from '@components/referencesModal';

export const Modals = observer(() => {
  return (
    <>
      <ReferencesModal />
    </>
  );
});
