import { PropsWithChildren } from 'react';

import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { Label } from '@components/ui/label';

import { AtomModel } from '@models/atom.model';

import { ColumnElement, ColumnTitle } from '@atoms/columnElement';
import { DateFormatPicker } from '@atoms/dateFormat/dateFormat';
import { DateWidgetSchema } from 'shared';

type Props = {
  format: DateWidgetSchema.Data['format'];
  onFormatChange: (format: DateWidgetSchema.Data['format']) => void;

  atomId: AtomModel['id'];
};

export const DateWidget = (props: PropsWithChildren<Props>) => {
  return (
    <>
      {props.children}

      <Column $width="220px">
        <ColumnTitle>Display options</ColumnTitle>
        <ColumnElement>
          <Label htmlFor={`format-${props.atomId}`}>Date display format</Label>
          <DateFormatPicker
            id={`format-${props.atomId}`}
            format={props.format}
            onFormatChange={props.onFormatChange}
          />
        </ColumnElement>
      </Column>
    </>
  );
};
