import { SettingsHeaderContainer } from './settings.style';
import { SettingDescription, SettingTitle } from './style';

type SettingHeaderProps = {
  title: string;
  description: string;
};

const SettingHeader = ({ title, description }: SettingHeaderProps) => {
  return (
    <SettingsHeaderContainer>
      <SettingTitle>{title}</SettingTitle>
      <SettingDescription>{description}</SettingDescription>
    </SettingsHeaderContainer>
  );
};

export default SettingHeader;
