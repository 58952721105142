export enum UserRoles {
  Owner = 'owner',
  Editor = 'editor',
  Viewer = 'viewer'
}

export enum DeleteRoleDraft {
  Remove = 'remove',
  Leave = 'leave'
}

//!: this Enum could disappear in a close future, that why we do not parse image with zod in the store
export enum ProcessImageTag {
  STAGING = 'staging-stratumn-com',
  PREPROD = 'preprod-stratumn-com',
  DEMO = 'demo-stratumn-com',
  RELEASE = 'release-stratumn-com'
}
