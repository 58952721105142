import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';

import {  ResolvedVariableType, DNDBlock } from 'shared'

import { DataSelector } from '@atoms/dataSelectors/dataSelector/dataSelector';
import { InputField } from '@atoms/input';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';

import { initialDropDownData } from './dropDown.data';
import { DropDownModal } from './dropDown.modal';
import { DropDownIcon } from './dropDown.utils';

const DropDown = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    type: 'Drop Down',
    sourceId: block.id,
    parentId: actionId,
    parentKind: 'form_action',
    initialData: initialDropDownData,
    variableInfo: {
      resolvedType: ResolvedVariableType.Text
    }
  });

  if (!atom) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: atom.data.title || `Drop Down title`, 
      content: <DropDownModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={DropDownIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={atom.data.title}
          onChange={(e) => (atom.data.title = e.target.value)}
        />
      </Column>
      <DataSelector
        id={atom.id}
        dataSource={atom.data.dataSource}
        onDataSourceChange={(v) => (atom.data.dataSource = v)}
        sourcesData={atom.data.dataSourceData}
        onSourceDataChange={(v) => (atom.data.dataSourceData = v)}
      />
    </BlockBase>
  );
};

export default observer(DropDown);
