import { Fragment } from 'react';

import { LucideIcon } from 'lucide-react';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';

import useStores from '@hooks/useStore';

import { AtomModel } from '@models/atom.model';

import { GlassModal } from '@atoms/glassModal';

import { ParamsList } from '@/routes/routes.types';
import { newError } from '@/services/errors/errors';
import { getCleanBlockType } from '@/utils/cleanBlockType';
import { pluralize } from '@/utils/pluralize';
import { iconMap } from '@library/icons.map';

import {
  ContentContainer,
  CurrentAtomTitle,
  DataType,
  DescriptionContainer,
  ReferenceContainerTitle,
  ReferenceHeader,
  ReferenceItem,
  ReferenceItemContainer,
  ReferenceTitle
} from './referenceModal.style';
import { getNumberOfReferences, getPathToSource } from './utils';
import {  AtomType, BlockType } from 'shared';

const ReferencesModal = () => {
  const { atomStore, modalStore } = useStores();
  const processId = useParams()[ParamsList.ProcessId];
  const navigate = useNavigate();

  const referenceModal = modalStore.referenceModal;

  const { isOpen, itemName } = referenceModal;

  const onRefItemClick = (atom: AtomModel): void => {
    referenceModal.close();
    const path = getPathToSource(atom, processId);
    if (!path) {
      newError(
        'RM-1234',
        `Failed to generate path to source for atom ${JSON.stringify(atom)}`
      );
      return;
    }
    navigate(path);
  };

  const referencedByMap = referenceModal.referencedByMap;
  const nbReferencedBy = getNumberOfReferences(referencedByMap);

  const nbKeysWithArrayNonEmpty = Object.keys(referencedByMap).filter(
    (key) => referencedByMap[key]?.length > 0
  ).length;

  const getIconFromBlockType = (blockType: AtomType | BlockType): LucideIcon => {
    return iconMap[blockType];
  };

  return (
    <GlassModal open={isOpen} onClose={() => referenceModal.close()}>
      <ReferenceHeader>
        <ReferenceContainerTitle>
          Manual action required
        </ReferenceContainerTitle>
        <DescriptionContainer>
          {`This ${getCleanBlockType(itemName)} cannot be deleted since `}{' '}
          <b>{pluralize(nbReferencedBy, 'element')}</b>{' '}
          {nbReferencedBy > 1 ? ' are ' : ' is '} referencing it.
        </DescriptionContainer>
        <DescriptionContainer>{`To remove it, you first need to delete the ${pluralize(
          nbReferencedBy,
          'reference',
          false
        )}.`}</DescriptionContainer>
      </ReferenceHeader>
      <ContentContainer>
        {Object.keys(referencedByMap).map((currentAtomId: string) => (
          <Fragment key={currentAtomId}>
            {referencedByMap[currentAtomId]?.length > 0 && (
              <>
                {nbKeysWithArrayNonEmpty >= 1 && (
                  <CurrentAtomTitle>
                    {
                      (
                        atomStore.get(currentAtomId) as AtomModel<{
                          title: string;
                        }>
                      ).data.title
                    }
                    <i>{` (${pluralize(
                      referencedByMap[currentAtomId]?.length,
                      'reference'
                    )})`}</i>
                  </CurrentAtomTitle>
                )}
                <ReferenceItemContainer key={currentAtomId}>
                  {(
                    referencedByMap[currentAtomId] as AtomModel<{
                      title: Maybe<string>;
                    }>[]
                  ).map((refAtom) => {
                    const Icon = getIconFromBlockType(
                      refAtom.type
                    );

                    return (
                      <ReferenceItem
                        key={refAtom.id}
                        onClick={() => onRefItemClick(refAtom)}
                      >
                        <DataType>
                          <>
                            <Icon size={15} />
                            {refAtom.metaInfo.source.name}
                          </>
                        </DataType>
                        <ReferenceTitle>
                          {refAtom.data.title || refAtom.type}
                        </ReferenceTitle>
                      </ReferenceItem>
                    );
                  })}
                </ReferenceItemContainer>
              </>
            )}
          </Fragment>
        ))}
      </ContentContainer>
    </GlassModal>
  );
};

export default observer(ReferencesModal);
