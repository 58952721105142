import { observer } from 'mobx-react';

import { AtomModel } from '@models/atom.model';

import { ColumnElement } from '@atoms/columnElement';
import { LocalFormatSelector } from '@atoms/localFormatSelector/localFormatSelector';
import { SwitchV2 } from '@atoms/switch';

import { EditorOptions } from '@library/tableEditor/shared/editor.options';
import { TableEditorNumberColumnData } from 'shared';

type Props = {
  atom: AtomModel<TableEditorNumberColumnData>;
};

export const NumberEditor = observer(({ atom }: Props) => {
  return (
    <EditorOptions
      atomId={atom.id}
      onPlaceholderChange={(value) =>
        (atom.data.editorOptions.editors.number.placeholder = value)
      }
      placeholder={atom.data.editorOptions.editors.number.placeholder}
    >
      <ColumnElement>
        <LocalFormatSelector
          id={atom.id}
          selectedLocale={atom.data.editorOptions.editors.number.format.locale}
          onLocaleChange={(v) =>
            (atom.data.editorOptions.editors.number.format.locale = v)
          }
        />
      </ColumnElement>
      <ColumnElement>
        <SwitchV2
          id={`save-formatted-${atom.id}`}
          labelText="Save the formatted value"
          checked={atom.data.editorOptions.editors.number.saveFormatted}
          onCheckedChange={(v) =>
            (atom.data.editorOptions.editors.number.saveFormatted = v)
          }
        />
      </ColumnElement>
    </EditorOptions>
  );
});
