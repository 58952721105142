import { z } from "zod";

export namespace DateFormatUtils {
  export const ClassicFormats = [
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "YYYY/MM/DD",
  ] as const satisfies string[];
  export const VerboseFormats = [
    "DD MMM, YYYY",
    "DD MMMM, YYYY",
    "MMM DD, YYYY",
    "MMMM DD, YYYY",
    "ddd, MMM DD, YYYY",
    "dddd, MMMM DD, YYYY",
  ] as const satisfies string[];
  export const OtherFormats = [
    "YYYY [Q]Q",
    "[Q]Q YYYY",
    "[Q]Q",
    "YYYY-MM",
    "YYYY",
  ] as const satisfies string[];

  export const AllFormats = [
    ...ClassicFormats,
    ...VerboseFormats,
    ...OtherFormats,
  ] as const satisfies string[];
}

export const DateFormatSchema = z.enum(DateFormatUtils.AllFormats);
export type DateFormatType = z.infer<typeof DateFormatSchema>;
