import { ChangeEvent } from 'react';

import { observer } from 'mobx-react';

import { TraceKeyInput } from '@components/traceKeyInput/traceKeyInput';
import { Separator } from '@components/ui/separator';

import useAction from '@hooks/useAction';

import { InputField } from '@atoms/input';

type Props = {
  children?: React.ReactNode;
};
export const SettingsSharedTab = observer(({ children }: Props) => {
  const action = useAction();

  if (!action) return <div>No action found</div>;

  const onActionNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    action._metadata.name = event.target.value;
  };

  return (
    <section className="h-full p-6 bg-[#fafafa]">
      <section className="flex h-full w-full flex-col gap-6">
        {children && (
          <>
            {children}
            <Separator />
          </>
        )}
        <InputField
          label="Action name"
          value={action._metadata.name}
          onChange={onActionNameChange}
          placeholder="Aa"
          required
        />
        <TraceKeyInput traceKey={action.traceKey} parentName={action.name} />
      </section>
    </section>
  );
});
