import { ReactNode } from 'react';

import { ChevronDown } from 'lucide-react';

import {
  FormControl,
  FormLabel,
  Select,
  SelectProps,
  selectClasses
} from '@mui/joy';

interface SelectFieldProps extends SelectProps<string, false> {
  value?: string;
  label?: string;
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  required?: boolean;
  width?: string;
}

export const SelectField = (props: SelectFieldProps) => (
  <FormControl size={props.size || 'sm'} sx={{ width: props.width }}>
    {props.label && (
      <FormLabel required={props.required}>{props.label}</FormLabel>
    )}
    <Select
      indicator={<ChevronDown size={15} />}
      sx={{
        [`& .${selectClasses.indicator}`]: {
          transition: '0.2s',
          [`&.${selectClasses.expanded}`]: {
            transform: 'rotate(-180deg)'
          }
        },
        backgroundColor: 'white' //! temp
      }}
      {...props}
    >
      {props.children}
    </Select>
  </FormControl>
);
