import { z } from "zod";
import { CheckBoxDataSchema } from "./checkbox.atom";
import { CommentDataSchema } from "./comment.atom";
import { DateFieldDataSchema } from "./date.atom";
import { DropDownDataSchema } from "./dropdown.atom";
import { FileUploadDataSchema } from "./file-upload.atom";
import { NumberFieldDataSchema } from "./number.atom";
import { RichTextDataSchema } from "./rich-text.atom";
import { TextFieldDataSchema } from "./text-field.atom";

export const FBSchemas = [
    z.object({
        type: z.literal("Check Box"),
        data: CheckBoxDataSchema(),
    }),
    z.object({
        type: z.literal("Date"),
        data: DateFieldDataSchema(),
    }),
    z.object({
        type: z.literal("Drop Down"),
        data: DropDownDataSchema(),
    }),
    z.object({
        type: z.literal("File Upload"),
        data: FileUploadDataSchema(),
    }),
    z.object({
        type: z.literal("Number"),
        data: NumberFieldDataSchema(),
    }),
    z.object({
        type: z.literal("Rich Text"),
        data: RichTextDataSchema(),
    }),
    z.object({
        type: z.literal("TextField"),
        data: TextFieldDataSchema(),
    }),
    z.object({
        type: z.literal("Comment"),
        data: CommentDataSchema(),
    }),
] as const;

export const FB_TYPES = FBSchemas.map(schema => schema.shape.type.value);

export type FbBlocks = typeof FBSchemas[number]["shape"]["type"]["value"];