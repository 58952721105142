const DEFAULT_LOADING_CARDS_AMOUT = 5;

export const getNumberOfProcesses = (): number => {
  const numberOfProcesses = localStorage.getItem('numberOfProcesses');
  return numberOfProcesses
    ? Number(numberOfProcesses)
    : DEFAULT_LOADING_CARDS_AMOUT;
};

export enum ViewType {
  Grid = 'grid',
  List = 'list'
}
/** @deprecated */
export const loadingLoremProcess = {
  name: 'Lorem ipsum is',
  id: '0',
  iconUrl: '',
  createdAt: '2023-08-24',
  updatedAt: '2023-08-24',
  published: false,
  workflows: [],
  draft: false
};
