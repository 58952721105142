import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { RenderSource } from '../sourceSelector/renderSource';
import { SourceSelector } from '../sourceSelector/sourceSelector';
import { DataSourceOption, SourcesData } from 'shared';

type Props = {
  id: string;
  dataSource: DataSourceOption;
  onDataSourceChange: (source: DataSourceOption) => void;

  sourcesData: SourcesData;
  onSourceDataChange: (sourceData: SourcesData) => void;
  possibleSources?: DataSourceOption[];
};

export const DataSelector = (props: Props) => {
  return (
    <>
      <Column>
        <SourceSelector
          id={props.id}
          dataSource={props.dataSource}
          onDataSourceChange={props.onDataSourceChange}
          possibleSources={props.possibleSources}
        />
      </Column>
      <Column $width="220px">
        <RenderSource
          id={props.id}
          selectedDataSource={props.dataSource}
          onSourceDataChange={props.onSourceDataChange}
          sourcesData={props.sourcesData}
        />
      </Column>
    </>
  );
};
