import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { PANEL_CONTENT_PADDING } from '@pages/panel/panel.style';

export const HeaderContainer = styled.header`
  box-sizing: border-box;
  width: 100%;
  height: 7rem;
  background-color: ${({ theme }) => theme.color.white[1]};
  border-bottom: ${({ theme }) => theme.border.default};
  padding-inline: ${PANEL_CONTENT_PADDING};
  padding-top: ${PANEL_CONTENT_PADDING};
  border-top-left-radius: 10px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  user-select: none;
`;

export const Navbar = styled.nav`
  height: 28px;
  flex-grow: 0;
  & * {
    margin: 0;
    padding: 0;
  }
  ul {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 24px;
    height: 100%;
  }
  li {
    list-style-type: none;
    text-decoration: none;
    display: flex;
    height: 100%;
  }

  a {
    display: inline-block;
    color: ${({ theme }) => theme.color.grey.dark};
    height: 100%;
    font-size: 13px;
    text-decoration: none;
    position: relative;

    &.active,
    &:hover {
      color: ${({ theme }) => theme.color.grey.dark};
    }

    &.active::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      display: block;
      width: 100%;
      height: 6px;
      background: linear-gradient(#d9d9d900, #4b34d226);
      border-bottom: solid 1px ${({ theme }) => theme.color.indigo[2]};
    }
  }
`;

export const StyledNavLink = styled(NavLink) <{ $fontFamily?: string }>`
  ${({ $fontFamily }) => ($fontFamily ? `font-family: ${$fontFamily};` : '')};
`;
