import { FC } from 'react';

import { LibraryProps, Plexer, ToolbarItem } from '@library/library.types';
import { BooleanIcon } from '@library/tableEditor//blocks/boolean/boolean.utils';
import { LabelsIcon } from '@library/tableEditor//blocks/labels/labels.utils';
import { NumberIcon } from '@library/tableEditor//blocks/number/number.utils';
import { TextIcon } from '@library/tableEditor//blocks/text/text.utils';
import * as Blocks from '@library/tableEditor/blocks';
import { DateIcon } from '@library/tableEditor/blocks/date/date.utils';

import { MultiSelectIcon } from './blocks/multiSelect/multiSelect.utils';
import { SelectIcon } from './blocks/select/select.utils';
import { TableEditorBlocks } from 'shared';

export const TableEditor_TOOLBAR_ITEMS: ToolbarItem<TableEditorBlocks>[] = [
  {
    type: 'Table_Editor_Text',
    icon: <TextIcon />
  },
  {
    type: 'Table_Editor_Number',
    icon: <NumberIcon />
  },
  {
    type: 'Table_Editor_Date',
    icon: <DateIcon />
  },
  {
    type: 'Table_Editor_Boolean',
    icon: <BooleanIcon />
  },
  {
    type: 'Table_Editor_Labels',
    icon: <LabelsIcon />
  },
  {
    type: 'Table_Editor_Select',
    icon: <SelectIcon />
  },
  {
    type: 'Table_Editor_MultiSelect',
    icon: <MultiSelectIcon />
  }
];

export const TableEditor_INFO_DND = {
  title: 'Table Editor',
  description:
    'Drag and drop a block to add a column to the table. Each block represents a column that you can customize.'
};

const FormBuilderPlexer: Plexer<TableEditorBlocks> = {
  ['Table_Editor_Text']: Blocks.Text,
  ['Table_Editor_Number']: Blocks.Number,
  ['Table_Editor_Date']: Blocks.DateField,
  ['Table_Editor_Boolean']: Blocks.Boolean,
  ['Table_Editor_Labels']: Blocks.Labels,
  ['Table_Editor_Select']: Blocks.Select,
  ['Table_Editor_MultiSelect']: Blocks.MultiSelect
};

export const LibraryTableEditorUI: FC<LibraryProps> = ({ block }) => {
  const Block = FormBuilderPlexer[block.type as TableEditorBlocks];
  return <Block {...block} />;
};
