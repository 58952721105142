import { GlobalVariableData } from "shared";

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialGlobalVariableData: GlobalVariableData = {
  title: 'New variable',
  variableType: {
    selectedKind: 'normal',
    type: 'string',
    customType: ''
  },
  description: ''
};
