import { AtomModel } from '@models/atom.model';
import { TableEditorTextColumnData } from 'shared';

type Props = {
  atom: AtomModel<TableEditorTextColumnData>;
};

export const TextSettingsModal = ({ atom }: Props) => {
  atom;
  return <></>;
};
