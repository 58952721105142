import { MouseEvent, useState } from 'react';

import {
  Activity,
  MoreHorizontal,
  Pencil,
  Pyramid,
  Settings,
  Trash2
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { DeleteModal } from '@pages/Settings/general-setting';

import useStores from '@hooks/useStore';

import { ProcessModel } from '@models/process.model';

import { TRACE_UI_URL } from '@/utils/constants';
import { Dropdown, Menu, MenuButton, MenuItem, Skeleton } from '@mui/joy';
import { Tooltip } from '@mui/joy';

import { ViewType } from '../home.utils';
import {
  LastEditContainer,
  LastEditInfo,
  ProcessBody,
  ProcessCardContainer,
  ProcessFooter,
  ProcessHeader,
  ProcessIcon,
  ProcessInfos,
  ProcessName,
  ProcessNameEllipsis,
  ProcessSettings,
  ProcessStatus,
  RightSide
} from './processCard.style';
import { getTimeAgo } from './processCard.utils';

interface ProcessCardProps {
  process: ProcessModel;
  view: ViewType;
  loading?: boolean;
}

const ProcessCard = ({ process, view, loading = false }: ProcessCardProps) => {
  const [isIconLoading, setIsIconLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const hasBeenGenerated =
    process &&
    process.workflows.length > 0 &&
    process.workflows[0]?.published_id;
  const divElement = document.getElementById(process.id);

  const isTextTruncated =
    divElement && divElement.scrollWidth > divElement?.clientWidth;

  const renderFooter = () => {
    return (
      <ProcessFooter $view={view}>
        <LastEditContainer>
          {loading ? (
            <Skeleton
              variant="circular"
              width={18}
              height={18}
              loading={loading}
              animation="wave"
            />
          ) : (
            <Activity size={14} />
          )}

          <LastEditInfo color="neutral" level="title-sm" noWrap>
            <Skeleton loading={loading} animation="wave">
              Last updated {getTimeAgo(process.createdAt)}
            </Skeleton>
          </LastEditInfo>
        </LastEditContainer>
      </ProcessFooter>
    );
  };
  const { processStore } = useStores();

  async function deleteProcess() {
    await processStore.delete(process.id);
    window.location.assign('/');
  }

  const workflowId =
    process.workflowIds && process.workflowIds?.length > 0
      ? process.workflowIds[0].id
      : 'loading';

  const stratumnEditorPath = `${process.id}/${workflowId}/editor`;

  return (
    <ProcessCardContainer
      $isLoading={loading}
      $view={view}
      to={loading ? '' : stratumnEditorPath}
      draggable="false"
      data-process-name={process.name}
    >
      <DeleteModal
        open={modalOpen}
        setOpen={setModalOpen}
        nuke={deleteProcess}
        isDraft={process?.draft ?? false}
      />
      <ProcessHeader>
        <ProcessIcon
          src={process.iconUrl}
          onLoad={() => setIsIconLoading(false)}
        >
          <Skeleton loading={loading || isIconLoading} animation="wave" />
        </ProcessIcon>
        <ProcessInfos>
          <ProcessName level="title-md">
            <Skeleton loading={loading} animation="wave">
              <Tooltip
                title={process.name}
                enterDelay={200}
                variant="outlined"
                color="neutral"
                disableHoverListener={!isTextTruncated}
              >
                <ProcessNameEllipsis id={process.id}>
                  {process.name}
                </ProcessNameEllipsis>
              </Tooltip>
            </Skeleton>
          </ProcessName>
          {!process.draft && (
            <LastEditInfo color="neutral" level="title-sm" noWrap>
              <Skeleton loading={loading} animation="wave">
                Process with 1 workflow
              </Skeleton>
            </LastEditInfo>
          )}
        </ProcessInfos>
        {view === ViewType.List && renderFooter()}
        <RightSide>
          {loading ? (
            <Skeleton
              variant="circular"
              width={8}
              height={8}
              loading={loading}
              animation="wave"
            />
          ) : (
            !process.draft && (
              <Tooltip
                title={
                  process.isPublished
                    ? 'This workflow is in production'
                    : 'This workflow is not yet in production'
                }
                enterDelay={700}
                variant="outlined"
                color="primary"
              >
                <ProcessStatus
                  $isPublished={process.isPublished}
                ></ProcessStatus>
              </Tooltip>
            )
          )}

          <ProcessSettings
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.preventDefault();
            }}
            data-tag="settings"
          >
            <Dropdown>
              <MenuButton
                sx={{
                  border: 'none'
                }}
              >
                <MoreHorizontal size={16} />
              </MenuButton>
              <Menu>
                <MenuItem
                  onClick={() => {
                    navigate(stratumnEditorPath);
                  }}
                >
                  <Pencil size={15} /> Editor
                  {/* TODO : add can_edit in /processed to change emoji here */}
                </MenuItem>
                {!process.draft && hasBeenGenerated && (
                  <MenuItem
                    onClick={() => {
                      window.open(
                        `${TRACE_UI_URL}/workflowoverview/${process?.workflows[0]?.published_id}`,
                        '_blank',
                        'noopener,noreferrer'
                      );
                    }}
                  >
                    <Pyramid size={15} /> Go to Trace
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    navigate(`${process.id}/${workflowId}/settings/general`);
                  }}
                >
                  <Settings size={15} /> Settings
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    if (!process?.id) return;
                    setModalOpen(true);
                  }}
                  color="danger"
                >
                  <Trash2 size={15} /> Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          </ProcessSettings>
        </RightSide>
      </ProcessHeader>
      {view === ViewType.Grid && (
        <>
          <ProcessBody></ProcessBody>
          {renderFooter()}
        </>
      )}
    </ProcessCardContainer>
  );
};

export default ProcessCard;
