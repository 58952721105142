import { z } from "zod";

import { AtomReferenceSchema } from "../../other/state-menu.schema";

export const DenominatorTypeList = ["none", "number", "variable"] as const;

export const DenominatorTypeLabelMap = {
  none: "No denominator",
  number: "Static number",
  variable: "State variable",
};

export namespace ProgressWidgetSchema {
  const DenominatorTypeSchema = z.enum(DenominatorTypeList);
  export const MainSchema = z.object({
    type: z.literal("progress"),
    pathRef: AtomReferenceSchema.optional(),
    denominator: z.object({
      type: DenominatorTypeSchema,
      data: z.object({
        number: z.number().optional(),
        variableRef: AtomReferenceSchema.optional(),
      }),
    }),
  });

  export type Data = z.infer<typeof MainSchema>;
  export type DenominatorType = z.infer<typeof DenominatorTypeSchema>;
}
