import { z } from "zod";
import { AtomReferenceSchema } from "../../other/state-menu.schema";

export namespace TableSchema {
  export const SettingsSchema = z.object({
    dataSelectorPathReference: AtomReferenceSchema.optional(),
    allowColumnsSelection: z.boolean(),
    showEmptyTable: z.boolean(),
    sortSetup: z
      .object({
        columnReference: AtomReferenceSchema.optional(),
        direction: z.enum(["ASC", "DESC"]).optional(),
      })
      .optional(),
  });

  export type SettingsData = z.infer<typeof SettingsSchema>;
}
