import { z } from "zod";
import { SharedSchema } from "../widget/utils";

export const WidthList = [
  /** 50px */
  "xsmall",
  /** 100px */
  "small",
  /** 200px */
  "medium",
  /** 300px */
  "large",
  /** 500px */
  "xlarge",
] as const;

export const WidthToInfoMap = {
  xsmall: {
    name: "Smaller",
    pixel: "50px",
  },
  small: {
    name: "Small",
    pixel: "100px",
  },
  medium: {
    name: "Medium",
    pixel: "200px",
  },
  large: {
    name: "Large",
    pixel: "300px",
  },
  xlarge: {
    name: "Larger",
    pixel: "500px",
  },
} as const satisfies Record<
  (typeof WidthList)[number],
  { name: string; pixel: string }
>;

export const TraceWidthSchema = z.union([
  z.enum(WidthList),
  SharedSchema.PositiveIntegerSchema,
]);

export type TraceWidth = z.infer<typeof TraceWidthSchema>;
