import { z } from 'zod';

export const DataSourceOptionSchema = z.enum([
  'normalSource',
  'database',
  'stateVariable'
]);

export type DataSourceOption = z.infer<typeof DataSourceOptionSchema>;

export interface DataSourceOptionInfo {
  name: string;
  value: DataSourceOption;
  isDisabled?: boolean;
}

export const SOURCE_INFO_MAP = {
  normalSource: {
    name: 'Static Source',
    value: 'normalSource'
  },
  database: {
    name: 'Database',
    value: 'database'
  },
  stateVariable: {
    name: 'State Variable',
    value: 'stateVariable'
  }
} as const satisfies Record<DataSourceOption, DataSourceOptionInfo>;
