import { z } from 'zod';

import { TraceKeySchema } from '../other/traceKey.schema';
import { AtomSourceSchema, DatabaseSchemas, DndAtomSchemas } from './sources';
import { VariableInfoSchema } from './variables';

export const MetaInfoSchema = z
  .object({
    source: z
      .object({
        /**
         * @description the reference of the element where the atom was created
         * @example in a text block of the form editor
         * @returns the id of the element where the atom was created
         */
        elementId: z.string(),

        /**
         * @description the parent containing the element
         * @example the form library is the parent of the text block
         * @returns the id of the parent containing the element
         */
        parentId: z.string(),

        /**
         * @description the "kind" of parent
         * @example formEditor, database etc
         */
        parentKind: AtomSourceSchema,

        /**
         * @description a client facing name for the element
         * @example Text Block in Action 5
         */
        name: z.string().optional()
      })
      .strict()
  })
  .strict();

/**
 * Do not use this schema directly, use the AtomSchema instead.
 * The base of the Atom, `data` and `type` are then added to this.
 * */
const BaseAtomSchema = z.object({
  /**
   * @description ID of the atom
   */
  id: z.string(),

  /**
   * @description ID of the process related to the atom
   */
  process_id: z.string(),

  /**
   * @description key used in the state
   */
  traceKey: TraceKeySchema,

  deletedAt: z.string().datetime().nullable().optional(),
  createdAt: z.string().datetime().nullable().optional(),
  updatedAt: z.string().datetime().nullable().optional(),

  /**
   * @description list of atom ID which reference this atom
   */
  referenced_by: z.array(z.string()),

  /**
   * @description list of atom id referencing
   */
  references: z.array(z.string()),

  meta_info: MetaInfoSchema.strict(),
  variable_info: VariableInfoSchema.optional().nullable()
});

export type BaseAtom = z.infer<typeof BaseAtomSchema>;

export type MetaInfo = z.infer<typeof MetaInfoSchema>;
export type DataSourceLibrary = z.infer<typeof AtomSourceSchema>;

export const DiscriminatedAtomDataSchema = z.discriminatedUnion('type', [
  ...DndAtomSchemas,
  ...DatabaseSchemas
]);

export const AtomSchema = BaseAtomSchema.and(DiscriminatedAtomDataSchema);

export type AtomType =
  (typeof DiscriminatedAtomDataSchema)['options'][number]['shape']['type']['value'];
export type DiscriminatedAtomData<TType extends AtomType = AtomType> = z.infer<
  typeof DiscriminatedAtomDataSchema
> & { type: TType };

export type Atom<TType extends AtomType = AtomType> = {
  type: TType;
} & z.infer<typeof AtomSchema>;

export const ATOM_TYPES = DiscriminatedAtomDataSchema.options.map(
  (option) => option.shape.type.value
);
