import { z } from 'zod';

import { LoadedCodeDataSchema } from '../bpmn/code.schema';
import { DNDSchema } from '../bpmn/dnd.schema';
import { TraceKeySchema } from '../other/traceKey.schema';

export const BaseActionSchema = z.object({
  id: z.string(),
  bpmn_id: z.string(),

  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable().optional(),

  calculated: z.unknown(),
  generation_id: z.number(),
  workflowId: z.string(),

  uiDndId: z.string().optional(),
  effectsDndId: z.string().optional(),
  customCodeId: z.string().optional(),

  ui: DNDSchema,
  effects: DNDSchema,
  generated_code: z.null().optional(),

  traceKey: TraceKeySchema,
  customCode: LoadedCodeDataSchema
});

export const BaseCreateActionDTO = z.object({
  bpmn_id: z.string(),
  workflow_id: z.string()
});
