import { z } from "zod";
import { AtomReferenceSchema } from "../../other/state-menu.schema";
import { DateFormatSchema } from "../../atom/dataSelector/data-date.atom";

export namespace DateWidgetSchema {
  export const MainSchema = z.object({
    type: z.literal("date"),
    format: DateFormatSchema,
    inputFormat: DateFormatSchema.optional(),
    isDeadline: z.boolean(),
    /** @default 0 */
    deadlineWarningBuffer: z.number().optional(),
    doneVariableReference: AtomReferenceSchema.optional(),
    /** Let's not use this for now, the default icon is better */
    deadlineWarningIcon: z.string().optional(),
  });

  export type Data = z.infer<typeof MainSchema>;
}
