import { AtomReference, RowData } from 'shared';
import { HydratedRowAtomReference } from 'shared/src/atom/atomReference.schema';

import { newError } from '@/services/errors/errors';

import useStores from './useStore';

/**
 * Returns hydrated references of row atom references.
 */
export const useHydratedRowAtomReference = (
  atomRefs: AtomReference[]
): HydratedRowAtomReference[] => {
  const { atomStore, databaseStore } = useStores();

  const hydratedRefs: HydratedRowAtomReference[] = [];

  for (const atomRef of atomRefs) {
    const matchedRowAtom = atomStore.getAtomById<RowData>(
      atomRef.dataItemId,
      'Row'
    );

    if (!matchedRowAtom || matchedRowAtom instanceof Error) {
      newError(
        'UHRR-9AHAI',
        `Row atom ${atomRef.dataItemId} 
            not found in store while hydrating this reference, 
            reference will not be displayed`
      );
      continue;
    }

    const matchedDatabaseOfRowAtom = databaseStore.get(
      matchedRowAtom.metaInfo.source.parentId
    );

    if (!matchedDatabaseOfRowAtom) {
      newError(
        'UHRR-NuIuV',
        `Database ${matchedRowAtom.metaInfo.source.parentId} of row atom reference 
            ${atomRef.dataItemId} not found in store, reference will not be displayed`
      );
      continue;
    }

    let primaryKeyColumn = matchedDatabaseOfRowAtom.getColumns.find(
      (column) => column.context?.isPrimaryKey
    );

    if (!primaryKeyColumn) {
      newError(
        'UHRR-N6iX7',
        `Primary key column of DTR ${matchedDatabaseOfRowAtom.id} not found, 
            this must be fixed, taking the first column as displayed value.`
      );
      primaryKeyColumn = matchedDatabaseOfRowAtom.getColumns[0];
    }

    hydratedRefs.push({
      dataItemId: matchedRowAtom.id,
      sourceId: matchedRowAtom.metaInfo.source.parentId,
      blockType: 'Row',
      displayedValue:
        matchedRowAtom.data[primaryKeyColumn.field]?.toString() ?? ''
    });
  }

  return hydratedRefs;
};
