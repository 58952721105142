import { forEach } from 'min-dash';

import * as appendOptions from '../MenuOptions';

/**
 * @typedef {import("../features/BpmnFactory").default} BpmnFactory
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenu").default} PopupMenu
 * @typedef {import("../features/Modeling").default} Modeling
 * @typedef {import("../features/BpmnReplace").default} BpmnReplace
 * @typedef {import("diagram-js/lib/features/Rules").default} Rules
 * @typedef {import("diagram-js/lib/i18n/translate/translate").default} Translate
 * @typedef {import("diagram-js/lib/features/create/Create").default} Create
 * @typedef {import("diagram-js/lib/core/ElementFactory").default} ElementFactory
 * @typedef {import("didi").Injector} Injector
 *
 *
 *
 * @typedef {import("../../model/Types").Element} Element
 * @typedef {import("../../model/Types").Moddle} Moddle
 *
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").PopupMenuEntries} PopupMenuEntries
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").PopupMenuEntry} PopupMenuEntry
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").PopupMenuEntryAction} PopupMenuEntryAction
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").PopupMenuHeaderEntries} PopupMenuHeaderEntries
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenuProvider").default} PopupMenuProvider
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenu").PopupMenuTarget} PopupMenuTarget
 *
 * @typedef {import("./CreateOptions").CreateOption} CreateOption
 */

/**
 * A BPMN-specific popup menu provider.
 *
 * @implements {PopupMenuProvider}
 *
 * @param {BpmnFactory} bpmnFactory
 * @param {PopupMenu} popupMenu
 * @param {Modeling} modeling
 * @param {Moddle} moddle
 * @param {BpmnReplace} bpmnReplace
 * @param {Rules} rules
 * @param {Translate} translate
 * @param {Create} create
 * @param {ElementFactory} elementFactory
 * @param {Injector} injector
 */

class AppendMenuProvider {
  constructor(
    bpmnFactory,
    popupMenu,
    modeling,
    moddle,
    bpmnReplace,
    rules,
    translate,
    create,
    elementFactory,
    injector
  ) {
    this.bpmnFactory = bpmnFactory;
    this.popupMenu = popupMenu;
    this.modeling = modeling;
    this.moddle = moddle;
    this.bpmnReplace = bpmnReplace;
    this.rules = rules;
    this.translate = translate;
    this.create = create;
    this.elementFactory = elementFactory;
    this.autoPlace = injector.get('autoPlace', false);

    this.register();
  }

  register() {
    this.popupMenu.registerProvider('bpmn-append', this);
  }

  getPopupMenuEntries(target) {
    switch (target.type) {
      case 'event':
        const eventLinkable = appendOptions.EVENT.filter(
          (opt) => opt.type !== 'bpmn:StartEvent'
        );
        return this.createEntries(target.element, eventLinkable);
      case 'gateway':
        return this.createEntries(target.element, appendOptions.GATEWAY);
      case 'action':
        return this.createEntries(target.element, appendOptions.TASK);
      default:
        return {};
    }
  }

  createEntries(target, appendOptions) {
    const entries = {};

    const self = this;

    forEach(appendOptions, (appendOption) => {
      entries[appendOption.actionName] = self.createEntry(appendOption, target);
    });

    return entries;
  }

  createEntry(appendOption, target) {
    const { type, group, className, title, icon } = appendOption;

    const create = this.create;
    const elementFactory = this.elementFactory;
    const autoPlace = this.autoPlace;
    const shape = elementFactory.createShape({ type });

    function appendStart(event, element) {
      create.start(event, shape, {
        source: target
      });
    }

    const append = autoPlace
      ? function () {
          autoPlace.append(target, shape);
        }
      : appendStart;

    const shortType = type.replace(/^bpmn:/, '');

    return {
      group,
      className,
      label: title || translate('Append {type}', { type: shortType }),
      imageUrl: icon,
      action: {
        dragstart: appendStart,
        click: append
      }
    };
  }
}

AppendMenuProvider.$inject = [
  'bpmnFactory',
  'popupMenu',
  'modeling',
  'moddle',
  'bpmnReplace',
  'rules',
  'translate',
  'create',
  'elementFactory',
  'injector'
];

export default AppendMenuProvider;
