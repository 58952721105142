import { TableEditorDateColumnData } from "shared";

const DEFAULT_HEADER = 'Default Date Column Header';
const DEFAULT_FORMAT = 'DD/MM/YYYY';

export const initialDateData: TableEditorDateColumnData = {
  title: DEFAULT_HEADER,
  width: 'medium',
  isfixedColumn: false,
  isDisplayedByDefault: true,
  required: true,
  cell: {
    view: {
      type: 'date',
      format: DEFAULT_FORMAT,
      isDeadline: false,
      deadlineWarningBuffer: 10,
      doneVariableReference: undefined,
      deadlineWarningIcon: undefined,
      inputFormat: undefined
    }
  },

  isEditMode: false,
  editorOptions: {
    selectedEditor: 'date',
    editors: {
      date: {
        type: 'date',
        inputFormat: DEFAULT_FORMAT,
        displayFormat: DEFAULT_FORMAT,
        saveFormatted: false,
        placeholder: '',
        delay: undefined,
        disabledPath: undefined
      }
    }
  }
};
