import styled, { css } from 'styled-components';

interface DroppableDivProps {
  $isOver: boolean;
  $isLast?: boolean;
}

export const DroppableDiv = styled.div<DroppableDivProps>`
  height: ${({ $isOver }) => ($isOver ? '25px' : '2px')};
  width: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 5px;
  z-index: 1;
  transition: height ease-out 0.2s;
  ${(p) =>
    p.$isLast &&
    css`
      padding-block: 11px;
      flex-grow: 1;
      align-items: flex-start;
      min-height: 4rem;
    `}
`;

export const DropIndicator = styled.div<{ $isVisible: boolean }>`
  width: 100%;
  border-radius: 8px;
  height: 2px;
  background-color: transparent;
  transition: all 0.2s;

  ${(p) =>
    p.$isVisible &&
    css`
      background-color: ${(p) => p.theme.color.indigo[2]};
    `}
  z-index: 1;
`;
