import { z } from "zod";

import { BaseActionSchema, BaseCreateActionDTO } from "./shared.action.schema";
import { ActionType } from "./types";

const BotMetadataSchema = z.object({
  name: z.string(),
});

export const BotDTOSchema = z.object({
  type: z.literal("BOT") satisfies z.ZodSchema<ActionType>,
  metadata: BotMetadataSchema,
});

export const BotSchema = BaseActionSchema.merge(BotDTOSchema);

export const CreateBotDTO = BaseCreateActionDTO.merge(BotDTOSchema);

export type BotActionDTO = z.infer<typeof BotDTOSchema>;
export type BotAction = z.infer<typeof BotSchema>;
export type BotMetadata = z.infer<typeof BotMetadataSchema>;
