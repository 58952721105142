import { z } from 'zod';

import { TitleInputSchema } from '../../../../other/otherUtils';
import { PrefillDataSchema } from './prefill.schema';

export const DateFieldDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean()
    })
    .merge(PrefillDataSchema)
    .strict();

export type DateFieldData = z.infer<ReturnType<typeof DateFieldDataSchema>>;
