import { EmailData } from "shared";

export const initialEmailData: EmailData = {
  dataSource: {
    template: '',
    selectedDestination: ''
  },
  dataSourceData: {
    templateVariables: null,
    to: ''
  }
};
