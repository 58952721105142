import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { TableColumn } from '@components/trace/column';

import { useAtom } from '@hooks/useAtom';

import { EditorType, ResolvedVariableType, DNDBlock } from 'shared'

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';
import { CommentEditor } from '@library/tableEditor/editors/comment.editor';
import { InputEditor } from '@library/tableEditor/editors/input.editor';
import { Widgets } from '@widgets';

import { initialTextData } from './text.data';
import { TextSettingsModal } from './text.settings';
import { TextIcon } from './text.utils';

const TextTableEditorBlock = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    type: 'Table_Editor_Text',
    sourceId: block.id,
    parentId: actionId,
    parentKind: 'table_editor',
    initialData: initialTextData,
    variableInfo: {
      resolvedType: ResolvedVariableType.Text
    }
  });

  if (!atom) return null;

  const getModalSettings = (): SettingsConfig => {
    return {
      title: atom.data.title || `Text Column Title`,
      content: <TextSettingsModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getModalSettings()}
      hasRequired
    ></EndControlsFB>
  );

  const renderEditorOptions = () => {
    if (atom.data.editorOptions.selectedEditor === 'input') {
      return <InputEditor atom={atom} />;
    }
    if (atom.data.editorOptions.selectedEditor === 'comment') {
      return <CommentEditor atom={atom} />;
    }
    return null;
  };
  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={TextIcon}
      endControls={getEndControls()}
    >
      <TableColumn.Base
        atomId={atom.id}
        title={atom.data.title}
        width={atom.data.width}
        isDisplayedByDefault={atom.data.isDisplayedByDefault}
        onTitleChange={(v) => (atom.data.title = v)}
        onWidthChange={(v) => (atom.data.width = v)}
        isfixedColumn={atom.data.isfixedColumn}
        onFixedColumnChange={(v) => (atom.data.isfixedColumn = v)}
        onDisplayByDefaultChange={(v) => (atom.data.isDisplayedByDefault = v)}
      />
      <TableColumn.EditorMode
        atomId={atom.id}
        isEditMode={atom.data.isEditMode}
        onIsEditModeChange={(value) => (atom.data.isEditMode = value)}
        canSelectEditorType
        selectedEditorType={atom.data.editorOptions.selectedEditor}
        possibleEditorTypes={
          Object.keys(atom.data.editorOptions.editors) as EditorType[]
        }
        onSelectedEditorTypeChange={(value) =>
          (atom.data.editorOptions.selectedEditor = value)
        }
      />
      {atom.data.isEditMode ? (
        renderEditorOptions()
      ) : (
        <Widgets.Text.Base
          onDefaultChange={(v) => (atom.data.cell.view.default = v)}
          onIsRichTextChange={(v) => (atom.data.cell.view.isRichText = v)}
          default={atom.data.cell.view.default}
          isRichText={atom.data.cell.view.isRichText}
          atomId={atom.id}
        />
      )}
    </BlockBase>
  );
};

export default observer(TextTableEditorBlock);
