import { GATEWAY, TASK } from '../pop-menu/MenuOptions';

class BpmnParserProvider {
  constructor(eventBus, bpmnReplace, elementRegistry, modeling, selection) {
    this.eventBus = eventBus;
    this.bpmnReplace = bpmnReplace;
    this.elementRegistry = elementRegistry;
    this.modeling = modeling;
    this.selection = selection;

    eventBus.on('import.done', () => {
      const form = TASK.find((task) => task.type === 'trace:Form');
      const tableEditor = TASK.find(
        (task) => task.type === 'trace:TableEditor'
      );
      const tableImporter = TASK.find(
        (task) => task.type === 'trace:TableImporter'
      );

      const exclusiveGateway = GATEWAY.find(
        (gtw) => gtw.type === 'bpmn:ExclusiveGateway'
      );

      function replaceTaskElement(elem) {
        switch (elem.businessObject.$type) {
          case 'bpmn:SendTask':
          case 'bpmn:ReceiveTask':
            bpmnReplace.replaceElement(elem, tableImporter);
            break;
          case 'bpmn:BusinessRuleTask':
          case 'bpmn:ServiceTask':
            bpmnReplace.replaceElement(elem, tableEditor);
            break;
          default:
            bpmnReplace.replaceElement(elem, form);
            break;
        }
      }

      function replaceGatewayElement(elem) {
        if (
          elem.businessObject.$type !== 'bpmn:ParallelGateway' &&
          elem.businessObject.$type !== 'bpmn:ExclusiveGateway'
        ) {
          bpmnReplace.replaceElement(elem, exclusiveGateway);
        }
      }

      function removeEventElement(elem) {
        if (
          (elem.businessObject.$type !== 'bpmn:StartEvent' &&
            elem.businessObject.$type !== 'bpmn:EndEvent') ||
          elem.businessObject.eventDefinitions
        ) {
          modeling.removeElements([elem]);
        }
      }

      // Browse list of elements in elementRegistry
      elementRegistry.forEach(function (elem) {
        // Replace Task type elements with a Studio"s elements
        if (elem.businessObject.$instanceOf('bpmn:Task')) {
          replaceTaskElement(elem);
        }

        // Replace Gateway type elements, except ParallelGateway & ExclusiveGateway, with an ExclusiveGateway
        if (elem.businessObject.$instanceOf('bpmn:Gateway')) {
          replaceGatewayElement(elem);
        }

        // Remove Event type elements, except StartEvent & EndEvent
        if (elem.businessObject.$instanceOf('bpmn:Event')) {
          removeEventElement(elem);
        }
      });

      // Deactivate the selection caused by the replaceElement
      selection.select();
    });
  }
}

BpmnParserProvider.$inject = [
  'eventBus',
  'bpmnReplace',
  'elementRegistry',
  'modeling',
  'selection'
];

export default BpmnParserProvider;
