import { Database, DatabaseSchema } from 'shared/src/database/database.schema';

import { DatabaseModel } from '@models/database.model';

import { newError } from '@/services/errors/errors';
import { parseWithZod } from '@/utils/parseZodSchema';

import BaseStore from './base/base.store';
import RootStore from './root.store';

export default class DatabaseStore extends BaseStore<DatabaseModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, DatabaseModel, 'databases');
    this.store_ready = true;
  }

  public async createNewDatabase(dto: Database): Promise<Maybe<DatabaseModel>> {
    const rawResponse = await this.httpWrapper.post<Database>('/', dto);
    if (!rawResponse) {
      newError(
        'DBST-ORydG',
        `Error while creating new database with dto: 
      ${JSON.stringify(dto)}`,
        true,
        {
          customMessage: 'Error while creating new database'
        }
      );
    }

    if (!rawResponse) return;

    return this.loadDatabaseToStore(rawResponse);
  }

  public loadDatabaseToStore(databaseJson: Database): Maybe<DatabaseModel> {
    const newDatabaseData = parseWithZod(
      DatabaseSchema,
      databaseJson,
      'DBST-1J2k3'
    );
    if (!newDatabaseData) return;

    const newDatabase = new DatabaseModel(
      this,
      newDatabaseData.id,
      newDatabaseData.traceKey,
      newDatabaseData.name,
      newDatabaseData.columnDefinitions,
      newDatabaseData.rowReferences,
      newDatabaseData.processId,
      newDatabaseData.createdAt,
      newDatabaseData.updatedAt,
      newDatabaseData.deletedAt
    );

    this.set(newDatabase.id, newDatabase);

    return newDatabase;
  }

  public async deleteById(databaseId: string): Promise<boolean> {
    const databaseToDelete: Maybe<DatabaseModel> = this.get(databaseId);

    if (!databaseToDelete) {
      newError(
        'DBST-Dl1kr',
        `No database found with the id: ${databaseId}`,
        true,
        {
          customMessage: 'Error while deleting the database'
        }
      );
      return false;
    }

    if (!databaseToDelete.isDeletable) {
      newError(
        'DBST-QOWi5',
        `database ${databaseToDelete.id} is not deletable, it's row atoms are still referenced by atoms`
      );
      return false;
    }

    for (const rowRefToDelete of databaseToDelete.getRowReferences) {
      const isRowAtomDeleted = this.rootStore.atomStore.deleteAtom(
        rowRefToDelete.dataItemId
      );
      if (!isRowAtomDeleted) {
        newError(
          'DBST-L0l7Z',
          `Error while deleting row atom ${rowRefToDelete.dataItemId}`
        );
        return false;
      }
    }

    return await this.delete(databaseId);
  }
}
