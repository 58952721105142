import { z } from 'zod';

export const UserSchema = z
  .object({
    avatar: z.string().url().nullish(),
    email: z.string().email(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    rowId: z.string(),
    __typename: z.string().optional()
  })
  .strict();

export type User = z.infer<typeof UserSchema>;
