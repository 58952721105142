import { TableImporterTextColumnData } from "shared";

const DEFAULT_HEADER = 'Default Text Column Header';
export const initialTextData: TableImporterTextColumnData = {
  title: DEFAULT_HEADER,
  width: 'medium',
  isfixedColumn: false,
  isDisplayedByDefault: true,
  required: true,
  cell: {
    view: {
      type: 'text',
      default: undefined,
      isRichText: false
    }
  },
  importerOptions: {
    from: DEFAULT_HEADER
  }
};
