import { z } from 'zod';

import { TextInputSchema } from '../../../../other/otherUtils';
import { PrefillDataSchema } from './prefill.schema';

export const NumberFieldDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TextInputSchema : z.string(),
      required: z.boolean(),
      placeholder: valueValidation ? TextInputSchema : z.string(),
      type: z.enum(['number', 'integer'])
    })
    .merge(PrefillDataSchema)
    .strict();

export type NumberFieldData = z.infer<ReturnType<typeof NumberFieldDataSchema>>;
