import { FC, Fragment } from 'react';

import { DNDBlock } from 'shared';

import { InteractionZone } from '@components/dnd/base/interaction';

import nanoID from '@/utils/nanoID';
import toCamelCase from '@/utils/toCamelCase';
import { DNDInfo, LibraryProps, ToolbarItem } from '@library/library.types';

import {
  CDT_TOOLBAR_ITEMS,
  CONDITION_INFO_DND,
  LibraryTransition
} from './condition';
import { FB_INFO_DND, FB_TOOLBAR_ITEMS, LibraryFB } from './formBuilder';
import {
  GLOBAL_VARIABLES_INFO_DND,
  LibraryGlobalVariable
} from './globalVariables';
import { LibraryNF, NF_INFO_DND, NOTIF_TOOLBAR_ITEMS } from './notification';
import {
  LibraryTableEditorUI,
  TableEditor_INFO_DND,
  TableEditor_TOOLBAR_ITEMS
} from './tableEditor';
import {
  LibraryTableImporterUI,
  TableImporter_INFO_DND,
  TableImporter_TOOLBAR_ITEMS
} from './tableImporter';
import { LibraryTI, TI_INFO_DND } from './traceInfo';
import { LibraryTO, TO_INFO_DND } from './traceOverview';

export const enum DndLibrary {
  /* ------------------------------- Action DNDs ------------------------------ */
  FormBuilder = 'FormBuilder',
  EffectsBuilder = 'EffectsBuilder',
  /** @deprecated */
  CodeEditor = 'CodeEditor',
  TableImporterUI = 'TableImporterUI',
  TableEditor = 'TableEditor',
  /* ------------------------------ Settings DNDs ----------------------------- */
  TraceOverview = 'TraceOverview',
  TraceInfo = 'TraceInfo',

  /* ----------------------------- Transition DNDs ---------------------------- */
  Condition = 'Condition',
  Notification = 'Notification',
  /** @deprecated */
  Priorities = 'Priorities',
  GlobalVariables = 'GlobalVariables'
}

const LIB_TO_ITEMS_MAP: Record<DndLibrary, ToolbarItem[]> = {
  /* -------------------------------------------------------------------------- */
  /*                                   Actions                                  */
  /* -------------------------------------------------------------------------- */

  [DndLibrary.FormBuilder]: FB_TOOLBAR_ITEMS,
  [DndLibrary.TableImporterUI]: TableImporter_TOOLBAR_ITEMS,
  [DndLibrary.TableEditor]: TableEditor_TOOLBAR_ITEMS,

  [DndLibrary.EffectsBuilder]: [],
  [DndLibrary.CodeEditor]: [],

  /* -------------------------------------------------------------------------- */
  /*                                 Transitions                                */
  /* -------------------------------------------------------------------------- */

  [DndLibrary.Condition]: CDT_TOOLBAR_ITEMS,
  [DndLibrary.Notification]: NOTIF_TOOLBAR_ITEMS,
  [DndLibrary.Priorities]: [],

  /* -------------------------------------------------------------------------- */
  /*                                  Settings                                  */
  /* -------------------------------------------------------------------------- */

  [DndLibrary.TraceInfo]: [],
  [DndLibrary.TraceOverview]: [],
  [DndLibrary.GlobalVariables]: []
};

const LIB_TO_LIBRARY_MAP: Record<DndLibrary, FC<LibraryProps>> = {
  [DndLibrary.FormBuilder]: LibraryFB,
  [DndLibrary.EffectsBuilder]: () => <p>EffectsBuilder</p>,
  [DndLibrary.CodeEditor]: () => <p>CodeEditor</p>,
  [DndLibrary.TableEditor]: LibraryTableEditorUI,
  [DndLibrary.TableImporterUI]: LibraryTableImporterUI,
  [DndLibrary.TraceInfo]: LibraryTI,
  [DndLibrary.TraceOverview]: LibraryTO,
  [DndLibrary.Condition]: LibraryTransition,
  [DndLibrary.Notification]: LibraryNF,
  [DndLibrary.Priorities]: () => <p>Priorities</p>,
  [DndLibrary.GlobalVariables]: LibraryGlobalVariable
};

export const LIB_TO_INFO_MAP: Record<DndLibrary, DNDInfo> = {
  [DndLibrary.FormBuilder]: FB_INFO_DND,
  [DndLibrary.EffectsBuilder]: { title: '', description: '' },
  [DndLibrary.CodeEditor]: { title: '', description: '' },
  [DndLibrary.TableImporterUI]: TableImporter_INFO_DND,
  [DndLibrary.TraceInfo]: TI_INFO_DND,
  [DndLibrary.TraceOverview]: TO_INFO_DND,
  [DndLibrary.Condition]: CONDITION_INFO_DND,
  [DndLibrary.Notification]: NF_INFO_DND,
  [DndLibrary.Priorities]: { title: '', description: '' },
  [DndLibrary.GlobalVariables]: GLOBAL_VARIABLES_INFO_DND,
  [DndLibrary.TableEditor]: TableEditor_INFO_DND
};

export const getToolbarItemsByLibrary = (
  library: DndLibrary
): ToolbarItem[] => {
  return LIB_TO_ITEMS_MAP[library] ?? [];
};

interface RenderLibraryBlockProps {
  library: DndLibrary;
  block: DNDBlock;
}
// general stuff for the DND rendering
const RenderLibraryBlock = ({ library, block }: RenderLibraryBlockProps) => {
  const error = "DND error: librairy doesn't exist";
  const Library = LIB_TO_LIBRARY_MAP[library] ?? <p>{error}</p>;
  if (!block) {
    console.warn('block is undefined');
    return;
  }

  return <Library block={block} />;
};

interface DNDBlockRenderProps {
  library: DndLibrary;
  block: DNDBlock | DNDBlock[];
  path: string;
  isLast?: boolean;
}

export const DNDBlockRender = ({
  library,
  block,
  path,
  isLast
}: DNDBlockRenderProps) => {
  const newDndBlocks: DNDBlock[] = !Array.isArray(block) ? [block] : block;

  const prepareSpawnedBlock = (dndBlock: DNDBlock) => {
    if (!dndBlock.blockState) {
      dndBlock.blockState = {};
    }
    if (!dndBlock.id) {
      dndBlock.id = `${library}_${toCamelCase(dndBlock.type)}_${nanoID(7)}`;
    }
  };

  newDndBlocks.forEach((dndBlock) => {
    prepareSpawnedBlock(dndBlock);
  });

  return (
    <>
      {newDndBlocks.map((dndBlock: DNDBlock, index: number) => (
        <Fragment key={index}>
          {index === 0 && path !== 'root' && (
            <InteractionZone isLast={isLast} path={`${path}|0`} dndBlock={[]} />
          )}
          <RenderLibraryBlock library={library} block={dndBlock} />
          {path !== 'root' && (
            <InteractionZone
              isLast={isLast}
              path={`${path}|${index + 1}`}
              dndBlock={[]}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
