import { z } from "zod";
import { DNDBlock } from "../block";

const StateSchema = z.any() as z.ZodType<DNDBlock<'root'>>; //! bad

export const DNDSchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable().optional(),
  state: StateSchema,
});

export type LoadedDndModel = z.infer<typeof DNDSchema>;
