import { Input, InputProps } from '@components/ui/input';
import { Label } from '@components/ui/label';

import { cn } from '@/lib/utils';

type InputInnerLabelProps = {
  innerLabel: string;
  id: string;
  innerLabelClassName?: string;
  wrapperClassName?: string;
} & InputProps;
export const InputInnerLabel = ({
  innerLabel,
  innerLabelClassName,
  wrapperClassName,
  ...inputProps
}: InputInnerLabelProps) => {
  return (
    <div
      className={cn(
        'h-8 border border-input box-border border-solid flex rounded-md focus-within:outline-none focus-within:ring-2 focus-within:ring-ring overflow-hidden ring-offset-background',
        wrapperClassName
      )}
    >
      <Label
        htmlFor={inputProps.id}
        className={cn(
          'flex items-center justify-start h-full px-2 text-xs bg-gray-100 border-r border-solid select-none min-w-11 shrink-0 border-input',
          innerLabelClassName
        )}
      >
        {innerLabel}
      </Label>
      <Input
        {...inputProps}
        className={cn(
          'border-none h-full grow focus-visible:ring-0 focus-visible:outline-none pr-0',
          inputProps.className
        )}
        style={{
          textDecorationColor: 'hsl(var(--primary))',
          textUnderlineOffset: '2px'
        }}
      />
    </div>
  );
};
