import { FC } from 'react';

import * as Blocks from '@library/formBuilder/blocks';
import { LibraryProps, Plexer, ToolbarItem } from '@library/library.types';
import {
  DateIcon,
  FileUploadIcon,
  NumberIcon
} from '@library/traceInfo/blocks';

import { CheckBoxIcon } from './blocks/checkbox/checkbox.utils';
import { CommentIcon } from './blocks/comment/comment.utils';
import { DropDownIcon } from './blocks/dropDown/dropDown.utils';
import { RichTextIcon } from './blocks/richText/richText.utils';
import { TextFieldIcon } from './blocks/textField/textField.utils';
import { FbBlocks } from 'shared';

export const FB_TOOLBAR_ITEMS: ToolbarItem<FbBlocks>[] = [
  {
    type: 'TextField',
    icon: <TextFieldIcon />
  },
  {
    type: 'Check Box',
    icon: <CheckBoxIcon />
  },
  {
    type: 'Drop Down',
    icon: <DropDownIcon />
  },
  {
      type: 'Number',
    icon: <NumberIcon />
  },
  {
      type: 'Date',
    icon: <DateIcon />
  },
  {
    type: 'Comment',
    icon: <CommentIcon />
  },
  {
    type: 'File Upload',
    icon: <FileUploadIcon />
  },
  {
    type: 'Rich Text',
    icon: <RichTextIcon />
  }
];

export const FB_INFO_DND = {
  title: 'Form Builder',
  description:
    'Drag and drop a block to add a field to your form. Each block represents a field that you can customize.'
};

const FormBuilderPlexer: Plexer<FbBlocks> = {
  ['Check Box']: Blocks.CheckBoxField,
  ['Date']: Blocks.DateField,
  ['Drop Down']: Blocks.DropDown,
  ['File Upload']: Blocks.FileUpload,
  ['Number']: Blocks.Number,
  ['Rich Text']: Blocks.RichText,
  ['TextField']: Blocks.TextField,
  ['Comment']: Blocks.Comment
};

export const LibraryFB: FC<LibraryProps> = ({ block }) => {
  const Block = FormBuilderPlexer[block.type as FbBlocks];
  return <Block {...block} />;
};
