import { FC } from 'react';

import { LibraryProps, Plexer, ToolbarItem } from '@library/library.types';
import * as Blocks from '@library/tableImporter/blocks';

import { DateIcon, NumberIcon } from '../traceInfo/blocks';
import { BooleanIcon } from './blocks/boolean/boolean.utils';
import { LabelsIcon } from './blocks/labels/labels.utils';
import { TextIcon } from './blocks/text/text.utils';
import { TableImporterBlocks } from 'shared';

export const TableImporter_TOOLBAR_ITEMS: ToolbarItem<TableImporterBlocks>[] = [
  {
    type: 'Table_Importer_Text',
    icon: <TextIcon />
  },
  {
    type: 'Table_Importer_Number',
    icon: <NumberIcon />
  },
  {
    type: 'Table_Importer_Date',
    icon: <DateIcon />
  },
  {
    type: 'Table_Importer_Boolean',
    icon: <BooleanIcon />
  },
  {
    type: 'Table_Importer_Labels',
    icon: <LabelsIcon />
  }
];

export const TableImporter_INFO_DND = {
  title: 'Table Importer',
  description:
    'Drag and drop a block to add a column to the table. Each block represents a column that you can customize.'
};

const FormBuilderPlexer: Plexer<TableImporterBlocks> = {
  'Table_Importer_Text': Blocks.Text,
  'Table_Importer_Number': Blocks.Number,
  'Table_Importer_Date': Blocks.DateField,
  'Table_Importer_Boolean': Blocks.Boolean,
  'Table_Importer_Labels': Blocks.Labels
};

export const LibraryTableImporterUI: FC<LibraryProps> = ({ block }) => {
  const Block = FormBuilderPlexer[block.type as TableImporterBlocks];
  return <Block {...block} />;
};
