import { Dispatch, SetStateAction, useEffect } from 'react';

import { AtomModel } from '@models/atom.model';

import VariableItem from './VariableItem';
import { StateMenuList, StateMenuResults } from './stateMenu.style';

interface SearchResultProps {
  filteredVariables: AtomModel<{
    title: string;
  }>[];
  cursor: number | null;
  handleVarClick: (index: number) => void;
  setHovered: Dispatch<
    SetStateAction<AtomModel<{
      title: string;
    }> | null>
  >;
  searchVar: string;
  actionsState: Map<string, AtomModel[]>;
}

const SearchResult = ({
  filteredVariables,
  cursor,
  searchVar,
  handleVarClick,
  setHovered,
  actionsState
}: SearchResultProps) => {
  useEffect(() => {
    if (searchVar && cursor) {
      const selected = document.querySelector(`#result-${cursor}`);
      if (selected) {
        selected?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [cursor, searchVar]);

  return (
    <StateMenuResults>
      <h2 className="text-xs p-3 font-semibold text-gray-900">All variables</h2>
      {filteredVariables.length > 0 ? (
        <StateMenuList>
          {filteredVariables.map((variable, index: number) => {
            const actionName = Object.keys(actionsState).find((key: string) =>
              actionsState.get(key)?.includes(variable)
            );

            return (
              <VariableItem
                key={`${actionName}_${variable.id}`}
                id={`result-${index}`}
                onHover={index === cursor}
                onClick={() => handleVarClick(index)}
                onMouseEnter={() => setHovered(variable)}
                onMouseLeave={() => setHovered(null)}
                searchVar={searchVar}
                variable={variable}
                actionName={actionName}
              />
            );
          })}
        </StateMenuList>
      ) : (
        <p style={{ margin: 'auto' }}>No variables found</p>
      )}
    </StateMenuResults>
  );
};

export default SearchResult;
