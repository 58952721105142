import { z } from 'zod';

import {
  TextInputSchema,
  TitleInputSchema
} from '../../../../other/otherUtils';
import { PrefillDataSchema } from './prefill.schema';

export const TextFieldDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean(),
      placeholder: valueValidation ? TextInputSchema : z.string()
    })
    .merge(PrefillDataSchema)
    .strict();

export type TextFieldData = z.infer<ReturnType<typeof TextFieldDataSchema>>;
