import {
  ActionType,
  AtomType,
  ResolvedVariableType,
  TableImporterAction
} from 'shared';

import BaseStore from '@stores/base/base.store';

import { AtomWithTitle } from '@/types/atom';

import { ActionModel } from './action.model';

export class TableImporterActionModel extends ActionModel {
  public actionType = 'TABLE_IMPORTER' as const satisfies ActionType;
  constructor(store: BaseStore<ActionModel>, action: TableImporterAction) {
    super(store, action);
    this.createTableAtom();
  }

  get metadata() {
    return this._metadata as TableImporterAction['metadata'];
  }
  // todo fix with atom id
  public createTableAtom() {
    if (this.atom) return;
    const atomStore = this.store.rootStore.atomStore;

    return atomStore.createAtom(
      this.id,
      'variable' as AtomType,
      {
        title: 'Table Importer'
      },
      {
        source: {
          elementId: this.id,
          name: 'Imported table',
          parentKind: 'ui',
          parentId: this.id
        }
      },
      {
        resolvedType: 'Table' as ResolvedVariableType
      }
    ) as Maybe<AtomWithTitle>;
  }

  get atom() {
    const atomStore = this.store.rootStore.atomStore;
    return atomStore.getAtomById_Unsafe(this.id) as Maybe<AtomWithTitle>;
  }
}
