import { TransitionModel } from '@models/transition.model';

import { Transition, TransitionSchema } from 'shared'

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { parseWithZod } from '@/utils/parseZodSchema';
import { DndLibrary } from '@library';

export class TransitionStore extends BaseStore<TransitionModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, TransitionModel, 'transition');
  }

  public async createNewTransition(
    transitionId: string
  ): Promise<Maybe<TransitionModel>> {
    const processId: Maybe<string> =
      this.rootStore.processStore.currentProcessId;

    if (!processId) {
      newError('TRAN-247c0', 'No current process found in the store', true, {
        description: 'Current process not found'
      });
      return;
    }

    const dto = {
      processId,
      bpmnId: transitionId
    };

    const rawResponse = await this.httpWrapper.post('/', dto);
    if (!rawResponse) {
      newError(
        'TRAN-6a7cb',
        `Error while creating a new action with dto:
      ${JSON.stringify(dto)}`,
        true
      );
      // TODO delete the created action in the BPMN schema
      return;
    }

    const parsedResponse = parseWithZod(
      TransitionSchema,
      rawResponse,
      'TRAN-e4c44'
    );

    if (!parsedResponse) return;

    const transitionLoaded = parsedResponse;

    const dndStore = this.rootStore.dndStore;

    const newConditionDnd = dndStore.createDndFromLoaded(
      DndLibrary.Condition,
      transitionLoaded.condition
    );

    const newNotificationDnd = dndStore.createDndFromLoaded(
      DndLibrary.Notification,
      transitionLoaded.notification
    );

    const newPrioritiesDnd = dndStore.createDndFromLoaded(
      DndLibrary.Condition,
      transitionLoaded.priorities
    );

    const newTransition = new TransitionModel(
      this,
      transitionLoaded.id,
      newConditionDnd.id,
      newNotificationDnd.id,
      newPrioritiesDnd.id,
      false
    );

    this.set(newTransition.id, newTransition);
    return newTransition;
  }

  public addLoadedTransitionToStore(
    rawLoadedTransition: Transition
  ): Maybe<TransitionModel> {
    const loadedTransition = parseWithZod(
      TransitionSchema,
      rawLoadedTransition,
      'TRAN-b72b1'
    );

    if (!loadedTransition) return;

    const IS_LOADED = true;

    const newConditionDnd = this.rootStore.dndStore.createDndFromLoaded(
      DndLibrary.Condition,
      loadedTransition.condition
    );

    const newNotificationDnd = this.rootStore.dndStore.createDndFromLoaded(
      DndLibrary.Notification,
      loadedTransition.notification
    );

    const newPrioritiesDnd = this.rootStore.dndStore.createDndFromLoaded(
      DndLibrary.Condition,
      loadedTransition.priorities
    );

    const newTransition = new TransitionModel(
      this,
      loadedTransition.id,
      newConditionDnd.id,
      newNotificationDnd.id,
      newPrioritiesDnd.id,
      IS_LOADED
    );
    this.set(newTransition.id, newTransition);
    return newTransition;
  }

  public loadTransitions(
    loadedTransitions: Transition[]
  ): Maybe<TransitionModel>[] {
    return loadedTransitions.map((loadedTransition) =>
      this.addLoadedTransitionToStore(loadedTransition)
    );
  }

  public async deleteById(transitionId: string): Promise<boolean> {
    const transitionToDelete: Maybe<TransitionModel> = this.get(transitionId);

    await this.rootStore.atomStore.deleteAtomsBySourceId(transitionId);

    if (!transitionToDelete) {
      newError(
        'TRAN-611ca',
        `No transition found in the store with the id: ${transitionId}`,
        true,
        {
          description: 'An error occured while deleting the transition'
        }
      );
      return false;
    }

    return await transitionToDelete.delete();
  }
}
