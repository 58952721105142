import { z } from 'zod';

import { AtomReferenceSchema } from '../../../../other/state-menu.schema';

export const TI_ListDataSchema = (_?: boolean) =>
  z
    .object({
      title: z.string(),
      selectedVariable: AtomReferenceSchema
    })
    .strict();

export type TI_ListData = z.infer<ReturnType<typeof TI_ListDataSchema>>;
