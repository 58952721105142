import { PropsWithChildren, forwardRef } from 'react';

import styled from 'styled-components';

import { BaseProps } from './type';

const StyledMenu = styled.div<BaseProps>`
  position: absolute;
  display: flex;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  padding: 2px;
  border-radius: 5px;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.75s;
  box-shadow: ${({ theme }) => theme.verRail.boxShadow};

  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
`;
const Menu = forwardRef<HTMLElement, PropsWithChildren<BaseProps>>(
  ({ className, ...props }, ref) => {
    return <StyledMenu {...props} ref={ref} className={className} />;
  }
);

export default Menu;
