import {
  Building,
  Database,
  Mail,
  MenuSquare,
  User,
  Users
} from 'lucide-react';

import { OptionsType } from './email.type';

export const metaOptions: OptionsType[] = [
  {
    label: 'Person',
    value: 'person',
    icon: <User size={18} />,
    disabled: true
  },
  {
    label: 'Group',
    value: 'group',
    icon: <Users size={18} />,
    disabled: true
  },
  {
    label: 'Organisation',
    value: 'organisation',
    icon: <Building size={18} />,
    disabled: true
  }
];
export const stateOptions: OptionsType[] = [
  {
    label: 'Email',
    value: 'mail',
    icon: <Mail size={18} />,
    disabled: false
  },
  {
    label: 'State value',
    value: 'state',
    icon: <MenuSquare size={18} />,
    disabled: false
  },
  {
    label: 'Database',
    value: 'database',
    icon: <Database size={18} />,
    disabled: false
  }
];
