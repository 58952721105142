import { ChangeEvent } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';

import {  ResolvedVariableType, DNDBlock } from 'shared'

import { InputField } from '@atoms/input';
import { SwitchField } from '@atoms/switch';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB, {
  SettingsConfig
} from '@library/formBuilder/endControls';
import { FormControl, FormLabel } from '@mui/joy';

import { initialRichTextData } from './richText.data';
import { RichTextModal } from './richText.modal';
import { RichTextIcon } from './richText.utils';

const RichText = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId] as string;

  const atom = useAtom({
    type: 'Rich Text',
    sourceId: block.id,
    parentId: actionId,
    parentKind: 'form_action',
    initialData: initialRichTextData,
    variableInfo: {
      resolvedType: ResolvedVariableType.Text
    }
  });

  if (!atom) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: atom.data.title || `Rich Text title`, 
      content: <RichTextModal atom={atom} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={atom}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={RichTextIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          label="Field title"
          placeholder="Aa"
          required
          value={atom.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            atom.data.title = e.target.value;
          }}
        />
      </Column>

      <Column>
        <InputField
          label="Placeholder"
          placeholder="Aa"
          value={atom.data.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            atom.data.placeholder = e.target.value;
          }}
        />
      </Column>

      <Column $gap="0">
        <FormControl size="sm">
          <FormLabel>Additional features</FormLabel>
        </FormControl>
        <SwitchField
          label="Mentions"
          checked={atom.data.withMention}
          onChange={(e) => {
            atom.data.withMention = e.target.checked;
          }}
        />
      </Column>
    </BlockBase>
  );
};

export default observer(RichText);
