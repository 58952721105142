/** Replaces all occurence of custom trace elements to bpmn elements. */
export const transformXML = (xml: string): string => {
  const replacements = [
    { from: /trace:form/g, to: 'bpmn:task' },
    { from: /trace:tableImporter/g, to: 'bpmn:sendTask' },
    { from: /trace:tableEditor/g, to: 'bpmn:serviceTask' }
  ];

  return replacements.reduce((acc, { from, to }) => acc.replace(from, to), xml);
};
