import { z } from 'zod';

import { AtomReferenceSchema } from '../../../../other/state-menu.schema';

export const BaseEditorSchema = z.object({
  placeholder: z.string().optional(),
  delay: z.number().optional(),
  disabledPath: AtomReferenceSchema.optional()
});

export const EditorTypes = [
  'comment',
  'date',
  'input',
  'multiselect',
  'number',
  'select'
] as const;

const EditorTypeSchema = z.enum(EditorTypes);

export const MultiEditorsSchema = <T extends z.ZodObject<z.ZodRawShape>>(
  editors: T
) =>
  z
    .object({
      isEditMode: z.boolean(),
      editorOptions: z.object({
        selectedEditor: EditorTypeSchema,
        editors
      })
    })
    .strict();

export type BaseEditorOptions = z.infer<typeof BaseEditorSchema>;
export type EditorType = z.infer<typeof EditorTypeSchema>;
export type MultiEditorsOptions = z.infer<
  ReturnType<typeof MultiEditorsSchema>
>;
