import { TableEditorMetadata, TableSchema } from 'shared'
import { TableImporterMetadata } from 'shared'

export namespace TraceTableInitialData {
  const sharedTableSettingsData = {
    allowColumnsSelection: true,
    showEmptyTable: true,
    sortSetup: {
      columnReference: undefined,
      direction: 'ASC'
    }
  } as const satisfies TableSchema.SettingsData;

  export const overviewSettingsData: TableSchema.SettingsData =
    sharedTableSettingsData;

  export const editorSettingsData: TableEditorMetadata['settings'] = {
    ...sharedTableSettingsData,
    addInitialData: true,
    initialDataKey: 'initialData',
    modifiedDataKey: 'modifiedData',
    allowColumnsSelection: false,
    addComments: true,
    commentKey: 'comment'
  };

  export const importerSettingsData: TableImporterMetadata['settings'] = {
    ...sharedTableSettingsData,
    allowColumnsSelection: false,
    addFile: true,
    fileKey: 'file',
    addComments: true,
    commentKey: 'comment',
    parsing: {
      encoding: 'latin1',
      csvParser: {
        delimiter: ';'
      }
    },
    aggregation: {}
  };
}
